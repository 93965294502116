import config from "config";
import { authHeader, handleResponse } from "../_helpers";

function getIframeSettings() 
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(`${config.apiUrl}/Settings/iframeSettings`, requestOptions).then(response =>
        handleResponse(response, false, false)
    );
}

function updateIframeSettings(newSettings)
{
    debugger;
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(newSettings ? newSettings : {} )
    };
    return fetch(`${config.apiUrl}/Settings/iframeSettingsUpdate`, requestOptions).then(
        handleResponse
    );
}

function deleteIframeSettings(newSettings)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(newSettings ? newSettings : {} )
    };
    return fetch(`${config.apiUrl}/Settings/iframeSettingsDelete`, requestOptions).then(
        handleResponse
    );
    
}

function getSubFlows() 
{
    debugger;
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(`${config.apiUrl}/Settings/Flows`, requestOptions).then(response =>
        handleResponse(response, false, false)
    );
}

function getOptionSettings() 
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(`${config.apiUrl}/Settings/optionSettings`, requestOptions).then(response =>
        handleResponse(response, false, false)
    );
}

function addOption(newOption)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(newOption ? newOption : {} )
    };
    return fetch(`${config.apiUrl}/Settings/optionSettingsAdd`, requestOptions).then(
        handleResponse
    );
}

function deleteOption(newOption)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(newOption ? newOption : {} )
    };
    return fetch(`${config.apiUrl}/Settings/optionSettingsDelete`, requestOptions).then(
        handleResponse
    );
}

//profile
function getProfileSettings()
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({})
    };
    return fetch(`${config.apiUrl}/Settings/profile/get`, requestOptions).then(
        handleResponse
    );
}

function updateProfileSettings(settings)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(settings ? settings : {})
    };
    return fetch(`${config.apiUrl}/Settings/profile/update`, requestOptions).then(
        handleResponse
    );
}

async function GetScripts(settings)
{   
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(settings ? settings : {} )
    };
    return fetch(`${config.apiUrl}/Settings/bigCommerce/scripts/getall`, requestOptions).then(
        handleResponse
    );
}

async function AddScript(settings)
{   
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(settings ? settings : {} )
    };
    return fetch(`${config.apiUrl}/Settings/bigCommerce/scripts/add`, requestOptions).then(
        handleResponse
    );
}

async function UpdateScript(settingsAndUUID)
{   
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(settingsAndUUID ? settingsAndUUID : {} )
    };
    return fetch(`${config.apiUrl}/Settings/bigCommerce/scripts/update`, requestOptions).then(
        handleResponse
    );
}
async function DeleteScript(settingsAndUUID)
{   
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(settingsAndUUID ? settingsAndUUID : {} )
    };
    return fetch(`${config.apiUrl}/Settings/bigCommerce/scripts/delete`, requestOptions).then(
        handleResponse
    );
}

function updateBigCommerce(settings)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(settings ? settings : {} )
    };
    return fetch(`${config.apiUrl}/Settings/bigCommerce/update`, requestOptions).then(
        handleResponse
    );
}

function getBigCommerce(hash)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(hash)
    };
    return fetch(`${config.apiUrl}/Settings/bigCommerce/get`, requestOptions).then(
        handleResponse
    );
}

function getIframeSettingsByURL(url) 
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(url ? url : {} )
    };
    return fetch(`${config.apiUrl}/Settings/iframeSettingsByUrl`, requestOptions).then(
        handleResponse
    );
}

export const settingsService = 
{
    getIframeSettings,
    updateIframeSettings,
    deleteIframeSettings,
    getSubFlows,
    getOptionSettings,
    addOption,
    deleteOption,
    updateBigCommerce,
    getBigCommerce,
    getIframeSettingsByURL,
    GetScripts,
    AddScript,
    UpdateScript,
    DeleteScript,
    getProfileSettings,
    updateProfileSettings

};