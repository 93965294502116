<template>
  <div id="reportRow" class="row">
    <div class="col-12">
      <div class="card lockCard" id="reportCard">
        <div class="card-body lockCard">
          <div class="row mb-1">
            <div class="col-sm-4">
              <h4 class="header-title">Compliance Screening Results</h4>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-right">
                <!-- <button type="button" class="btn btn-success mb-2 mr-1"><i class="mdi mdi-settings"></i></button>
                  <button type="button" class="btn btn-light mb-2 mr-1">Import</button> -->

                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    @input="onPageSizeChange"
                    v-model="filterObject.pageSize"
                    size="sm"
                    class="mr-1 ml-1"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
                <button
                  type="button"
                  class="btn btn-secondary ml-2 mb-1"
                  @click="downloadOrderReport(filterObject)"
                >
                  Export
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0" style="overflow-x: unset">
            <b-table
              :items="
                Array.isArray(this.report.orderList.orders)
                  ? this.report.orderList.orders
                  : []
              "
              :fields="fields"
              :busy="this.report.orderList.status.reportLoading"
              :per-page="filterObject.pageSize"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              v-if="this.report.orderList"
              id="reportTable"
              responsive="sm"
              fixed
            >
              <template #table-colgroup>
                <col style="width:35px;" />
                <!-- orderId -->
                <col style="width:35px;" />
                <!-- date-->
                <col style="width:45px;" />
                <!-- status-->
                <col style="width:50px;" />
                <!-- customer -->
                <col style="width:140px;" />
                <!-- address-->
                <col style="width:80px;" />
                <!-- compliance_flow -->
                <col style="width:45px;" />
                <!-- expand -->
              </template>

              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(orderDate)="row">
                {{ formatFriendlyDateLocal(row.item.orderDate) }}
              </template>

              <template #cell(orderStatus)="row">
                <div class=" row">
                    <div class="col-auto" id="statusContainer">
                <i
                  v-if="row.item.orderStatus === 'Pass'"
                  class="mdi mdi-circle text-success font-12"/>
                <i
                  v-if="row.item.orderStatus === 'Fail'"
                  class="mdi mdi-circle text-danger font-12"/>
                <i
                  v-if="row.item.orderStatus === 'Conditional Fail'"
                  class="mdi mdi-circle text-warning font-12"/>
                    </div>
                    <div class="col-auto pl-0">
                      {{row.item.orderStatus}}
                    </div>
                  </div>
              </template>

              <!-- <template #cell(customer)="row">
                  {{row.item.billToAddresses[0].firstName}} {{row.item.billToAddresses[0].lastName}}
                </template> -->

              <template #cell(addresses)="row">
                <div class="row">
                  <div class="col-sm-2 pr-0">
                    <strong>Bill To: </strong>
                  </div>
                  <div class="col-sm-10 pl-0">
                    <ul class="smallList">
                      <li
                        class="addressList"
                        :key="index"
                        v-for="(billToAddress, index) in row.item
                          .billToAddresses"
                      >
                        {{ billToAddress.firstName }}
                        {{ billToAddress.lastName }},
                        {{ billToAddress.address1 }},
                        <span v-show="billToAddress.address2 !== 'null'"
                          >{{ billToAddress.address2 }},
                        </span>
                        {{ billToAddress.city }},
                        {{ billToAddress.addressState }},
                        {{ billToAddress.postalCode }}
                      </li>
                    </ul>
                  </div>
                  <!-- <br/> -->
                </div>
                <div class="row">
                  <div class="col-sm-2 pr-0">
                    <strong>Ship To: </strong>
                  </div>
                  <div class="col-sm-10 pl-0">
                    <ul class="smallList">
                      <li
                        class="addressList"
                        :key="index"
                        v-for="(shipToAddress, index) in row.item
                          .shipToAddresses"
                      >
                        {{ shipToAddress.firstName }}
                        {{ shipToAddress.lastName }},
                        {{ shipToAddress.address1 }},
                        <span v-show="shipToAddress.address2 !== 'null'"
                          >{{ shipToAddress.address2 }},
                        </span>
                        {{ shipToAddress.city }},
                        {{ shipToAddress.addressState }},
                        {{ shipToAddress.postalCode }}
                      </li>
                    </ul>
                  </div>
                </div>
              </template>

              <template #cell(expand)="row">
                <b-button
                 :ref="'expand' + row.item.id" size="sm"
                  @click="
                    handleExpand(row.detailsShowing, row);
                    row.toggleDetails();
                  "
                  class="mr-2"
                >
                  {{ row.detailsShowing ? "Hide" : "Show" }} Details
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-table
                    :items="rowDetailsItems(row)"
                    :fields="rowDetailsFields"
                    :busy="report.orderDetailsList.status.detailsLoading"
                    v-if="report.orderDetailsList"
                    sort-by="stepOrder"
                    responsive="sm"
                  >
                    <template #table-colgroup>
                      <col style="width:110px;" />
                      <!-- date-->
                      <col style="width:300px;" />
                      <!-- compliance_check_type-->
                      <col style="width:150px;" />
                      <!-- status -->
                    </template>

                    <template #cell(date)="row">
                      {{ formatFriendlyDateLocal(row.item.date) }}
                    </template>
                    <template #cell(description)="row">
                      <span v-html="row.item.description"></span>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading...</strong>
                      </div>
                    </template>

                    <template #cell(status)="row">
                      <div class="row">
                        <div class="col-auto" id="statusContainer">
                          <i v-if="row.item.status === 'Pass'" class="mdi mdi-circle text-success font-12"/>
                          <i v-if="row.item.status === 'Fail'" class="mdi mdi-circle text-danger font-12"/>
                          <i v-if="row.item.status === 'Conditional Fail'" class="mdi mdi-circle text-warning font-12"/>
                        </div>
                        <div class="col-auto pl-0">
                          {{row.item.status}}
                        </div>
                      </div>
                      <!-- {{row.item.status}} <i v-if="row.item.status === 'Pass'" class="uil uil-check text-success font-20"/> <i v-if="row.item.status === 'Fail'" class="uil uil-times text-danger font-20"/> -->
                    </template>

                    </b-table>

                  <b-button
                    size="sm"
                    @click="
                      handleCollapse(row);
                      row.toggleDetails();
                    "
                    >Hide Details</b-button
                  >
                </b-card>
                <!-- <b-card>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
                      <b-col>{{ row.item.age }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Salary:</b></b-col>
                      <b-col>{{ row.item.salary }}</b-col>
                    </b-row>

                    <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                  </b-card> -->
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <!-- <ul class="pagination pagination-rounded mb-0">
                    pagination
                    <b-pagination @input="onPageChange" v-model="filterObject.pageNumber" :total-rows="5" :per-page="filterObject.pageSize"></b-pagination>
                  </ul> -->
                <div class="row">
                  <div class="col-auto">
                    <span
                      v-show="
                        this.filterObject.pageNumber !== 0 &&
                          this.filterObject.pageNumber !== 1
                      "
                      id="previousPageContainer"
                    >
                      <button
                        tabindex="-1"
                        @click="onPageChange(-1)"
                        class="btn icon p-0 buttonHover"
                        id="previousButton"
                        title="Previous Page"
                      >
                        <i class="uil uil-angle-left font-22"></i>
                      </button>
                    </span>
                  </div>

                  <div class="col-auto alignCenter">
                    <div>
                      {{ pageCount === 0 ? 0 : filterObject.pageNumber }} of
                      {{ pageCount }}
                    </div>
                  </div>

                  <div class="col-auto">
                    <span
                      v-show="this.pageCount !== this.filterObject.pageNumber"
                      id="nextPageContainer"
                    >
                      <button
                        tabindex="-1"
                        @click="onPageChange(1)"
                        class="btn icon float-right p-0 buttonHover"
                        id="nextButton"
                        title="Next Page"
                      >
                        <i class="uil uil-angle-right font-22"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end table -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { tableData } from "@/views/report/compliance-table";
import { rowDetailsData } from "@/views/report/details-table";
import { formatFriendlyDate,formatFriendlyDateLocal } from "../../_helpers";
import { exportService } from "../../_services";

export default {
  data() {
    return {
      tableData: tableData,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderDate",
      sortDesc: true,
      // eslint-disable-next-line
      fields: [
        { key: 'systemDisplayOrderID', label:'Order #', sortable: true },
        { key: 'orderDate', label: 'Date', sortable: true },
        { key: 'orderStatus', label: 'Status', sortable: true},
        { key: 'customer', label: 'Customer', sortable: true,
        formatter: (value, key, item) => {
          return item.billToAddresses[0].firstName + ' ' + item.billToAddresses[0].lastName
        }, sortByFormatted: true },
        { key: 'addresses', label: 'Addresses', sortable: false },
        { key: 'flowName', label: 'Compliance Flow', sortable: true },
        { key: 'expand', label: 'Expand', sortable: false }
      ],
      rowDetailsData: rowDetailsData,
      // eslint-disable-next-line
      rowDetailsFields: [
        { key: "date", sortable: false },
        { key: "serviceName", label: "Compliance Check Type", sortable: false },
        { key: "status", sortable: false, tdClass: "stylePadding" },
        { key: "description", sortable: false }
      ],
      expandedOrders: []
    };
  },
  props: {
    filterObject: {
      required: true
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
      report: state => state.report
    }),
    pageCount() {
      if (this.report.orderList.status.reportloaded === true) {
        return Math.ceil(
          this.report.orderList.orderCount / this.filterObject.pageSize
        );
      } else {
        return 0;
      }
    },
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
  created() {
    this.getOrderReport(this.filterObject);
  },
  methods: {
    ...mapActions("report", ["getOrderReport", "getOrderDetails", "getFlows"]),
    formatFriendlyDate: formatFriendlyDate,
    formatFriendlyDateLocal: formatFriendlyDateLocal,
    downloadOrderReport: exportService.downloadOrderReport,
    // /**
    //  * Search the table data with search input
    //  */
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length
    //   this.this.filterObject.pagenumber = 1
    // },
    onPageSizeChange() {
      this.filterObject.pageNumber = 1;
      this.$emit("handleSubmit");
    },
    onPageChange(page) {
      debugger;
      this.filterObject.pageNumber = this.filterObject.pageNumber + page;

      if (this.filterObject.pageNumber === 0) {
        this.filterObject.pageNumber = 1;
        return;
      }
      this.$emit("handleSubmit");
    },
    handleExpand(detailsShowing, row) {
      debugger;
      if (detailsShowing) {
        this.removeOrderId(row.item.id);
        return;
      } else {
        this.expandedOrders.push(row.item.id);
        this.getOrderDetails(this.expandedOrders);
      }
    },
    //Not currently in use
    handleExpandAll() {
      this.expandedOrders = []
      this.report.orderList.forEach(order => {
        this.expandedOrders.push(order.id)
      });
      this.getOrderDetails(this.expandedOrders)
    },
    handleCollapse(row) {
      this.removeOrderId(row.item.id);
    },
    removeOrderId(rowId) {
      for (let i = 0; i < this.expandedOrders.length; i++) {
        if (this.expandedOrders[i] === rowId) {
          this.expandedOrders.splice(i, 1);
        }
      }
    },
    rowDetailsItems(row) {
      debugger;
      if (
        this.report.orderDetailsList &&
        Array.isArray(this.report.orderDetailsList.details)
      ) {
        const rowDetails = this.report.orderDetailsList.details.filter(
          details => details.orderID === row.item.id
        );

        
        
        return rowDetails;
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
#statusContainer {
  padding-right: 3px;
}#previousButton,
#previousButton i,
#nextButton,
#nextButton i {
  border-radius: 25px;
  height: 30px;
  width: 30px;
  line-height: 30px;
}

#previousPageContainer :hover,
#nextPageContainer :hover {
  background-color: #e9ecef;
}

.alignCenter {
  align-self: center;
}

.lockCard {
  min-width: 1200px;
}

.addressList {
  list-style-type: none;
}

.smallList {
  padding-inline-start: 0px;
  margin-bottom: 0px;
}

#reportCard .btn-secondary, 
#reportCard .btn-secondary:focus, 
#reportCard .btn-secondary:visited {
  color: rgb(58, 58, 58);
  background-color: #bfd9f1;
  border-color: #bfd9f1;
}

#reportCard .btn-secondary:hover,
#reportCard .btn-secondary:active {
  color: rgb(58, 58, 58);
  background-color: #a0c7eb;
  border-color: #a0c7eb;
}

.smallPara {
  margin-bottom: 0px;
}

// #reportTable td {
//   vertical-align: middle;
// }

#reportTable .stylePadding {
  padding-bottom: 1.25rem;
}

table#reportTable span.fflCheck {
    display: inline-block;
    margin-left: 60px;
}

table#reportTable span.fflCheckFail {
    display: inline-block;
    margin-left: 32px;
}

table#reportTable span.exemptionbullet {
    display: inline-block;
    margin-left: 25px;
}

table#reportTable span.businesstradetitle {
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  background-color: rgba(0,0,0,0.015);
  padding: 2px 0px 0px 3px;
}

table#reportTable span.addlrequirementmet {
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  margin-left: 32px;
  padding: 2px 0px 0px 3px;
}

table#reportTable span.addlFflLicenserequirementnotmet {
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 100%;
  margin-left: 32px;
  padding: 2px 0px 0px 3px;
}

</style>
