import { productDesignationService, productService } from "../_services";

const state = {
  
  productDesignationsList: {
    status: {},
    addingStatus: {
      designationAdded: false
    },
    deletingStatus: {},
    designations: [],
    categories: [],
    error: null
  }
};


const actions = {
    
  getProductDesignations({ dispatch, commit }) {debugger;
    commit("getProductDesignationsRequest");

    productDesignationService.getDesignations().then(
      response => {
        commit("getProductDesignationsSuccess", response);
      },
      error => {
        commit("getProductDesignationsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  addProductDesignation({ dispatch, commit, rootState }, productDesignationDto) {
    commit("addProductDesignationRequest");

    const productDesignationDtoObject = Object.assign({}, productDesignationDto);

    productDesignationService.addProductDesignation(productDesignationDtoObject).then(
      response => {
        commit("addProductDesignationSuccess", [productDesignationDto,rootState]);
      },
      error => {
        commit("addProductDesignationFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  deleteProductDesignation({ dispatch, commit, rootState }, productDesignationDto) {
    commit("deleteProductDesignationRequest");

    const productDesignationDtoObject = Object.assign({}, productDesignationDto);

    productDesignationService.deleteProductDesignation(productDesignationDtoObject).then(
      response => {
        commit("deleteProductDesignationSuccess", [productDesignationDto,rootState]);
      },
      error => {
        commit("deleteProductDesignationFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  }

};

const mutations = {
    getProductDesignationsRequest(state) {
        state.productDesignationsList.status = { gettingDesignations: true };
    },
    getProductDesignationsSuccess(state, designations) {debugger;
      state.productDesignationsList.categories = new Map();
      for (const d of designations) {
        d.selected = false
        d.unique = true
        d.filtered = false
        d.hovered = false
        d.dragHovered = false
        state.productDesignationsList.categories.set(d.productCategoryID, {productCategory: d.productCategory, productCategoryID: d.productCategoryID})
      }

      for (const category of state.productDesignationsList.categories) {
        const filtered = designations.filter(x => x.productCategoryID === category[0])
        category[1].children = filtered
        state.productDesignationsList.categories.set(category[0], category[1])
      }

      // sort by value
      state.productDesignationsList.categories = new Map([...state.productDesignationsList.categories.entries()].sort(function (a,b) { 
        if (a[1].productCategory > b[1].productCategory) return 1;
        if (a[1].productCategory < b[1].productCategory) return -1;
      }));

      for (const category of state.productDesignationsList.categories) { debugger;
        category[1].children.sort(function (a, b) {
          
          // return a.sortOrder - b.sortOrder
        
        
          // Sort by sortOrder
          // If the first item has a higher number, move it down
          // If the first item has a lower number, move it up
          if (a.sortOrder > b.sortOrder) return 1;
          if (a.sortOrder < b.sortOrder) return -1;
        
          // If the sortOrder number is the same between both items, sort alphabetically
          // If the first item comes first in the alphabet, move it up
          // Otherwise move it down
          if (a.designation > b.designation) return 1;
          if (a.designation < b.designation) return -1;})
      }

      // state.productDesignationsList.categories.sort(function (a, b) {
      //   // sort alphabetically
      //   // If the first item comes first in the alphabet, move it up
      //   // Otherwise move it down
      //   if (a.value.productCategory > b.value.productCategory) return 1;
      //   if (a.value.productCategory < b.value.productCategory) return -1;
      // })

      state.productDesignationsList.designations = designations;
      state.productDesignationsList.status = { designationsLoaded: true };
      
    },
    getProductDesignationsFailure(state, error) {
        state.productDesignationsList.error = error;
        state.productDesignationsList.status = { gettingDesignationsError: true };
    },

    addProductDesignationRequest(state) {
        state.productDesignationsList.addingStatus = { designationAdded: false };
    },
    addProductDesignationSuccess(state, dataArray) {debugger;
        const productIds = dataArray[0].productIds.split(",")
        for (const product of dataArray[1].product.productList.products) {
          if (productIds.includes(product.id.toString())) {
            product.tags = product.tags + "," + dataArray[0].designationId.toString()
            product.tagIds.push(dataArray[0].designationId)
          }
          if(product.variants) {
            for (const variant of product.variants) {
              if (productIds.includes(variant.id.toString())) {
                variant.tags = variant.tags + "," + dataArray[0].designationId.toString()
                variant.tagIds.push(dataArray[0].designationId)
              }
            }
          }
        }
        state.productDesignationsList.addingStatus = { designationAdded: true };
        
    },
    addProductDesignationFailure(state, error) {
        state.productDesignationsList.error = error;
        state.productDesignationsList.addingStatus = { addingProductError: true };
    },

    deleteProductDesignationRequest(state) {
        state.productDesignationsList.deletingStatus = { deletingDesignation: true };
    },
    deleteProductDesignationSuccess(state, dataArray) {debugger
        const productIds = dataArray[0].productIds.split(",")
        for (const product of dataArray[1].product.productList.products) {
          if (productIds.includes(product.id.toString())) {
            product.tagIds = product.tagIds.filter(item => item !== dataArray[0].designationId)
            product.tags = product.tagIds.join(",")
          }
          if(product.variants) {
            for (const variant of product.variants) {
              if (productIds.includes(variant.id.toString())) {
                variant.tagIds = variant.tagIds.filter(item => item !== dataArray[0].designationId)
                variant.tags = variant.tagIds.join(",")
              }
            }
          }
        }
        state.productDesignationsList.deletingStatus = { designationDeleted: true };
    },
    deleteProductDesignationFailure(state, error) {
        state.productDesignationsList.error = error;
        state.productDesignationsList.deletingStatus = { errorDeletingDesignation: true };
    }
};

export const productDesignation = {
  namespaced: true,
  state,
  actions,
  mutations
};
