<template>
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div id="container" class="align-items-center d-flex h-100">
        <div class="card-body">
          <!-- Logo -->
          <logo-content></logo-content>

          <!-- email send icon with text-->
          <div class="text-center m-auto">
            <img
              src="../../design/assets/images/mail_sent.svg"
              alt="mail sent image"
              height="64"
            />
            <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
              Please check your email
            </h4>
            <p class="text-muted mb-4">
              If the email you entered is associated with an eCheckpoint account then an
              email has been sent. Please check your email and click on the included link to
              reset your password.
            </p>
          </div>

          <!-- button -->
          <div class="form-group mb-0 text-center">
            <a class="btn btn-primary btn-block userButton" href="/login">
              Back to Login
            </a>
          </div>
          <!-- end button-->
        </div>
        <!-- end card-body -->
        <footer class="footer footer-alt">
          <p class="text-muted">
            CONFIDENTIAL {{ new Date().getFullYear() }} RICO Technologies LLC
          </p>
        </footer>
      </div>
      <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <auth-fluid-content></auth-fluid-content>
    <!-- end Auth fluid right content -->
  </div>
  <!-- end Auth fluid -->
</template>

<script>
// import { mapState, mapActions } from 'vuex'

import AuthFluidContent from "../../components/login/AuthFluidContent";
import LogoContent from "../../components/login/LogoContent";
import config from "config";

export default {
  data() {
    return {
      config: config
    };
  },
  components: { AuthFluidContent, LogoContent }
};
</script>

<style>
/* #button {
  box-shadow: 0px 2px 6px 0px #4a90e2;
  background-color: #4a90e2;
  border: #4a90e2;
}  */
</style>
