<script>
// import { layoutComputed, layoutMethods } from '@state/helpers'
import { mapState } from "vuex";

import Topbar from "./topbar";
import AppMenu from "./app-menu";
// import RightSidebar from './right-sidebar'
import Footer from "./footer";

export default {
  components: { Topbar, AppMenu, Footer },
  data() {
    return {
      user: this.account ? this.account.user : {} || {},
      showMenu: window.screen.width > 768,
      layoutWidth: "fluid"
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  watch: {
    layoutWidth: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.activateWidth(newVal);
      }
    }
  },
  created: function() {
    document.body.setAttribute("data-layout", "topnav");
    document.body.classList.remove("authentication-bg");
    document.body.classList.remove("authentication-bg-pattern");
    this.activateWidth(this.layoutWidth);
  },
  methods: {
    // ...layoutMethods,
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    activateWidth(width) {
      switch (width) {
        case "boxed":
          document.body.setAttribute("data-layout-mode", "boxed");
          break;
        default:
          document.body.removeAttribute("data-layout-mode");
          break;
      }
    }
  }
};
</script>

<template>
  <div class="wrapper">
    <div class="content-page">
      <div class="content">
        <div class="navbar-custom topnav-navbar topnav-navbar-dark">
          <div class="container-fluid">
            <a href class="topnav-logo">
              <span class="topnav-logo-lg">
                <img
                  src="@/design/assets/images/Original_Horizontal-white.png"
                  alt
                  height="40"
                />
              </span>
              <!-- <span class="topnav-logo-sm">
                <img
                  src="@/design/assets/images/rico.png"
                  alt
                  height="16"
                />
              </span> -->
            </a>
            <Topbar
              :width="layoutWidth"
              :user="this.account.user"
              layout="horizontal"
            />
          </div>
        </div>
        <!-- <Topbar /> -->
        <div class="topnav shadow-sm" v-if="$router.currentRoute.name !== 'bigCommerce'">
          <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
              <b-collapse id="topnav-menu-content" v-model="showMenu">
                <div class="topbar-nav">
                  <AppMenu mode="horizontal" />
                </div>
              </b-collapse>
            </nav>
          </div>
        </div>
        <div class="container-fluid overflowScroll">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
    <!-- <RightSidebar /> -->
  </div>
</template>

<style>
.overflowScroll {
  overflow-x: auto;
}

.topnav-navbar-dark {
  background-color: #58568c;
}

.container-fluid body {
  background-color: #fff8ed;
}

.topnav{
  background-color: #fff;
}
</style>
