<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- FILTERING -->
    <FilterComponent
      @handleSubmit="handleSubmit"
      :filter-object="filterObject"
    />

    <!-- TABLE -->
    <TableComponent
      @handleSubmit="handleSubmit"
      :filter-object="filterObject"
    />
  </Layout>
</template>

<script>
// import Vuetable from 'vuetable-2/src/components/Vuetable'
// import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
// import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
// import DatePicker from 'vue2-datepicker'
// import Multiselect from 'vue-multiselect'
import _ from "lodash";
import { mapActions } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/components/layout/main";
import PageHeader from "@/components/layout/page-header"; 
import FilterComponent from "@/components/report/FilterComponent";
import TableComponent from "@/components/report/TableComponent";
/**
 * products component
 */
export default {
  page: {
    title: "products",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    FilterComponent,
    TableComponent
  },
  data() {
    return {
      title: "Compliance Reporting",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "eCheckpoint",
          href: "/about"
        },
        {
          text: "Report",
          active: true
        }
      ],
      filterObject: {
        startDate: null,
        endDate: null,
        orderID: null,
        skuID: null,
        statusID: null,
        addressTypeID: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        postalCode: null,
        flowID: null,
        pageNumber: 1,
        pageSize: 25
      }
    };
  },
  created() {
    debugger;
    const url = window.location.ancestorOrigins[window.location.ancestorOrigins.length - 1];
    const regexTemplate = /https:\/\/store-[A-z1-9]*\.mybigcommerce.com/
    if(regexTemplate.test(url))
    {
        //router.pop();
        this.$router.push("/bigcommerce")
    }
  },
  methods: {
    ...mapActions("report", ["getOrderReport"]),
    handleSubmit() {
      debugger;

      for (const property in this.filterObject) {
        if (this.filterObject[property] === "") {
          this.filterObject[property] = null;
        }
      }

      /*if (this.filterObject.orderID !== null) {
        if (typeof this.filterObject.orderID === "string") {
          this.filterObject.orderID = this.filterObject.orderID; //Number(this.filterObject.orderID);
        }
      }

      if (this.filterObject.skuID !== null) {
        if (typeof this.filterObject.skuID === "string") {
          this.filterObject.skuID = this.filterObject.skuID; //Number(this.filterObject.skuID);
        }
      }*/

      this.getOrderReport(this.filterObject);
    }
  }
};
</script>

<style lang="scss"></style>
