import config from "config";
import { authHeader, handleResponse } from "../_helpers";

async function streamToString(stream) {
    // lets have a ReadableStream as a stream variable
    const chunks = [];

    for await (const chunk of stream) {
        chunks.push(Buffer.from(chunk));
    }

    return Buffer.concat(chunks).toString("utf-8");
}

function getResults(processCheckDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(processCheckDto ? processCheckDto : {})
  };

  return fetch(`${config.apiUrl}/compliancecheck/getorderresults`, requestOptions).then( async (res) => {
    if(res.status === 402)
        throw {text: await streamToString(res.body), error: true, status: 402};

    handleResponse(res)
  }
  );
}

function getServiceFlowState(processCheckDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(processCheckDto ? processCheckDto : {})
  };

  return fetch(`${config.apiUrl}/compliancecheck/getserviceflowstate`, requestOptions).then(
    handleResponse
  );
}

async function getAllProducts(searchObject) { 
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(searchObject ? searchObject : {}),
    
  };
  return fetch(`${config.apiUrl}/compliancecheck/getproductlist`, requestOptions).then(
    handleResponse
  );
}



export const manualCheckService = {
  getResults,
  getAllProducts,
  getServiceFlowState
};
