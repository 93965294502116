<template>
    <div id="reportRow" class="row">
        <div class="col-12">
            <div class="card lockCard" id="reportCard">
                <div class="card-body lockCard">
                    <div ref="OrderDetailsComponent" id="OrderDetailsComponent">
                        <form ref="form" @submit.stop.prevent="check" novalidate>
                            <div class="mb-0 row">
                                <div class="manualCellTitle mb-2 row">

                                </div>
                                <div class="manualCellFull">
                                    <h4 class="header-title">Manual Compliance Check</h4>
                                </div>
                                <div class="manualCellEND mb-2 row">

                                </div>
                            </div>
                            <br>
                            <div class="row mb-0">
                                <div class="manualCellEND mb-2 row">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="orderNumber" class="form-label">Checkpoint</label>
                                </div>
                                <div class="manualCellHalf">
                                    <multiselect :searchable="true" track-by="id" label="flowName" :show-labels="false"
                                        :options="Array.isArray(this.report.flowLookup.flows)
                                            ? this.report.flowLookup.flows.filter(x => x.flowName != 'All')
                                            : []
                                            " :value="flowValue()" :max-height="250" v-if="this.report.flowLookup"
                                        @input="onFlowChange($event)" class="filterSelect" placeholder="Select Checkpoint">
                                        <template slot="singleLabel" slot-scope="props">{{
                                            props.option.flowName
                                        }}</template>
                                    </multiselect>
                                </div>
                                <div class="manualCellSpacerSM">

                                </div>
                                <div class="manualCellLabel">

                                </div>
                                <div class="manualCellFill">

                                </div>
                                <div class="manualCellEND mb-2 row">

                                </div>
                            </div>
                            <div class="row mb-0">
                                <div class="manualCellEND mb-2 row">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="orderNumber" class="form-label" :state="orderNumberState">Order
                                        Number</label>
                                </div>
                                <div class="manualCellHalf">
                                    <b-form-input type="text" v-model="processCheckObject.orderID" @keyup.enter="submission"
                                        id="orderNumber" placeholder="Order Number" :state="orderNumberState" required>
                                    </b-form-input>
                                </div>
                                <div class="manualCellSpacerSM">

                                </div>
                                <div class="manualCellLabel">
                                </div>
                                <div class="manualCellFill">
                                </div>
                                <div class="manualCellEND mb-2 row">

                                </div>
                            </div>
                            <div class="mb-0 row">
                                <div class="manualCellEND mb-2 row">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="multiselect" class="form-label">Products</label>
                                </div>
                                <div class="manualCellFull">
                                    <!--<label class="typo__label" for="multiselect">Products</label>-->

                                    <multiselect class="select" v-model="value" placeholder="Search for Products"
                                        ref="multiselect" id="ajax" :hide-selected="true" label="name"
                                        track-by="id" :options="options"
                                        :loading="this.manualState.manualCheck.productList.status.reportLoadingProducts"
                                        :multiple="true" @search-change="asyncFindDebounced" :internalSearch="false">
                                        <template #clear="props">
                                            <div class="multiselect__clear" v-if="value.length"
                                                @mousedown.prevent.stop="clearAll(props.search)"></div>
                                        </template>
                                        <template #noResult>
                                            <span>Oops! No elements found. Consider changing the search query.</span>
                                        </template>
                                    </multiselect>

                                </div>
                                <div class="manualCellEND mb-2 row">

                                </div>
                            </div>
                            <div class="row mb-0">
                                <div class="manualCellEND mb-2 row">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="customerEmail" class="form-label" :state="emailState">Email</label>
                                </div>
                                <div class="manualCellHalf">
                                    <b-form-input type="text" v-model="processCheckObject.CustomerEmail"
                                        @keyup.enter="submission" id="customerEmail" placeholder="Customer Email"
                                        :state="emailState" required>
                                    </b-form-input>
                                </div>
                                <div class="manualCellSpacerSM">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="company" class="form-label" :state="companyState">Company</label>
                                </div>
                                <div class="manualCellFill">
                                    <b-form-input type="text" v-model="processCheckObject.ShippingAddress.Company"
                                        @keyup.enter="submission" id="company" placeholder="Company" :state="companyState"
                                        required>
                                    </b-form-input>
                                </div>
                                <div class="manualCellEND mb-2 row">

                                </div>
                            </div>
                            <div class="row mb-0">
                                <div class="manualCellEND mb-2 row">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="firstname" class="form-label manualLabel" :state="firstNameState">First
                                        Name</label>
                                </div>
                                <div class="manualCellHalf">
                                    <b-form-input id="firstname" class="form-control"
                                        v-model="processCheckObject.ShippingAddress.CustomerFirstName"
                                        @keyup.enter="submission" :state="firstNameState" placeholder="First Name" required>
                                    </b-form-input>
                                </div>
                                <div class="manualCellSpacerSM">

                                </div>
                                <div class="manualCellLabel">
                                    <label for="lastname" class="form-label" :state="lastNameState">Last Name</label>
                                </div>
                                <div class="manualCellFill">
                                    <b-form-input id="lastname" class="form-control"
                                        v-model="processCheckObject.ShippingAddress.CustomerLastName"
                                        @keyup.enter="submission" :state="lastNameState" placeholder="Last Name" required>
                                    </b-form-input>
                                </div>
                                <div class="manualCellEND mb-2 row">

                                </div>
                            </div>

                            <div class="row mb-0">
                                <div class="col-sm-12">
                                    <div class="mb-0 row">
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputShipAddress" class="form-label"
                                                :state="shippingAddressState">Address</label>
                                        </div>
                                        <div class="manualCellHalf">
                                            <b-form-input id="inputShipAddress" class="form-control"
                                                v-model="processCheckObject.ShippingAddress.Address1"
                                                @keyup.enter="submission" placeholder="1234 Main St"
                                                :state="shippingAddressState" required>
                                            </b-form-input>

                                        </div>
                                        <div class="manualCellSpacerSM">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputShipAddress2" class="form-label">Address 2</label>
                                        </div>
                                        <div class="manualCellFill">
                                            <b-form-input id="inputShipAddress2" class="form-control"
                                                v-model="processCheckObject.ShippingAddress.Address2"
                                                @keyup.enter="submission" placeholder="Apartment, studio, or floor">
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputShipCity" class="form-label" :state="shippingCityState">
                                                City</label>
                                        </div>
                                        <div class="manualCellCity">
                                            <b-form-input id="inputShipCity" class="form-control" style="min-width: 100%;"
                                                v-model="processCheckObject.ShippingAddress.City" @keyup.enter="submission"
                                                :state="shippingCityState" required>
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellSpacerSM">
                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="shippingAddressState" class="form-label">State</label>
                                        </div>
                                        <div class="manualCellState">
                                            <multiselect id="shippingAddressState" placeholder="Select" :searchable="true"
                                                track-by="abbr" label="abbr" :show-labels="false" :options="states"
                                                open-direction="above" :value="stateValue()" :max-height="250"
                                                @input="onStateChange($event)" class="filterSelect multiselect">
                                                <template slot="singleLabel" slot-scope="props">{{
                                                    props.option.abbr === "None" ? "" : props.option.abbr
                                                }}</template>
                                            </multiselect>
                                        </div>
                                        <div class="manualCellSpacerSM">
                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputShipZip" class="form-label"
                                                :state="shippingPostalCodeState">Postal
                                                Code</label>
                                        </div>
                                        <div class="manualCellZip">
                                            <b-form-input id="inputShipZip" class="form-control" style="min-width: 100%;"
                                                v-model="processCheckObject.ShippingAddress.PostalCode"
                                                @keyup.enter="submission" :state="shippingPostalCodeState" required>
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div class="manualCellTitle mb-2 row">

                                        </div>
                                        <div class="manualCellFull mb-2 row">


                                            <div class="form-check">

                                                <input type="checkbox" @change="onClick" class="form-check-input"
                                                    id="customCheck11" checked>
                                                <label class="form-check-label" for="customCheck11">Same as Billing
                                                    Address</label>
                                            </div>
                                        </div>
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="row mb-1">
                                <div class="col-sm-12 hide" id="billAddress" ref="billing">
                                    <div class="mb-2 row">
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputBillAddress" class="form-label"
                                                :state="billingAddressState">Billing
                                                Address</label>
                                        </div>
                                        <div class="manualCellHalf">
                                            <b-form-input id="inputBillAddress" class="form-control"
                                                v-model="processCheckObject.BillingAddress.Address1"
                                                @keyup.enter="submission" placeholder="1234 Main St"
                                                :state="billingAddressState" required>
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellSpacerSM">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputBillAddress2" class="form-label">Address 2</label>
                                        </div>
                                        <div class="manualCellFill">
                                            <b-form-input id="inputBillAddress2" class="form-control"
                                                v-model="processCheckObject.BillingAddress.Address2"
                                                @keyup.enter="submission" placeholder="Apartment, studio, or floor">
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputBillCity" class="form-label"
                                                :state="billingCityState">City</label>
                                        </div>
                                        <div class="manualCellCity">
                                            <b-form-input id="inputBillCity" class="form-control" style="min-width: 100%;"
                                                v-model="processCheckObject.BillingAddress.City" @keyup.enter="submission"
                                                :state="billingCityState" required>
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellSpacerSM">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputState" class="form-label">State</label>
                                        </div>
                                        <div class="manualCellState">
                                            <multiselect id="billingAddressState" placeholder="Select" :searchable="true"
                                                track-by="abbr" label="abbr" :show-labels="false" :options="statesBill"
                                                open-direction="above" :value="stateBillValue()" :max-height="250"
                                                @input="onStateBillChange($event)" class="filterSelect">
                                                <template slot="singleLabel" slot-scope="props">{{
                                                    props.option.abbr === "None" ? "" : props.option.abbr
                                                }}</template>
                                            </multiselect>
                                        </div>
                                        <div class="manualCellSpacerSM">

                                        </div>
                                        <div class="manualCellLabel">
                                            <label for="inputBillZip" class="form-label"
                                                :state="billingPostalCodeState">Postal Code</label>
                                        </div>
                                        <div class="manualCellZip">
                                            <b-form-input id="inputBillZip" class="form-control" style="min-width: 100%;"
                                                v-model="processCheckObject.BillingAddress.PostalCode"
                                                @keyup.enter="submission" :state="billingPostalCodeState" required>
                                            </b-form-input>
                                        </div>
                                        <div class="manualCellEND mb-2 row">

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col pr-0"><b-button id="resetButton" @click="reset"
                                        class="btn btn-secondary float-right resetbutton btn-secondary"
                                        style="">Reset</b-button>
                                </div>
                                <div class="col-auto"><button id="filterButton" @click="fillData" type="submit"
                                        class="btn float-right userButton btn-primary" href="#table">Check</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Shipping Warning Alert Modal -->
        <b-modal ref="serviceflowwarningalertmodal" tabindex="-1" role="dialog" header-bg-variant="danger2" hide-footer
            centered aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="text-center">
                            <i class="uil-exclamation-triangle h1 text-danger2"></i>
                            <p class="mt-3">Please select a Checkpoint.</p>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </b-modal><!-- /.modal -->

        <!-- Shipping Warning Alert Modal -->
        <b-modal ref="shippingwarningalertmodal" tabindex="-1" role="dialog" header-bg-variant="danger2" hide-footer
            centered aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="text-center">
                            <i class="uil-exclamation-triangle h1 text-danger2"></i>
                            <p class="mt-3">Please select a shipping address state.</p>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </b-modal><!-- /.modal -->

        <!-- Billing Warning Alert Modal -->
        <b-modal ref="billingwarningalertmodal" tabindex="-1" role="dialog" header-bg-variant="danger2" hide-footer centered
            aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="text-center">
                            <i class="uil-exclamation-triangle h1 text-danger2"></i>
                            <p class="mt-3">Please select a billing address state.</p>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </b-modal><!-- /.modal -->

        <!-- Shiping and Billing Warning Alert Modal -->
        <b-modal ref="shippingbillingwarningalertmodal" tabindex="-1" role="dialog" header-bg-variant="danger2" hide-footer
            centered aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="text-center">
                            <i class="uil-exclamation-triangle h1 text-danger2"></i>
                            <p class="mt-3">Please select a shipping and billing address state.</p>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </b-modal><!-- /.modal -->

        <!-- Shiping and Billing Warning Alert Modal -->
        <b-modal ref="productwarningalertmodal" tabindex="-1" role="dialog" header-bg-variant="danger2" hide-footer centered
            aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="text-center">
                            <i class="uil-exclamation-triangle h1 text-danger2"></i>
                            <p class="mt-3">Please select some products.</p>
                        </div>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </b-modal><!-- /.modal -->
    </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import { states, statesBill } from "@/views/report/state_capitals";

export default {

    components: { Multiselect },
    data() {
        return {
            value: [],
            options: [],
            isLoading: false,
            timeout: null,
            shipValue: null,
            billValue: null,
            states: states,
            statesBill: states,
            orderNumberState: null,
            firstNameState: null,
            lastNameState: null,
            idkYet: null,

            shippingAddressState: null,
            shippingCityState: null,
            shippingStateState: null,
            shippingPostalCodeState: null,

            billingAddressState: null,
            billingCityState: null,
            billingStateState: null,
            billingPostalCodeState: null,

            serviceFlowState: null,
            emailState: null,
            companyState: null,
            asyncFindDebounced: null,
        }
    },
    computed: {
        ...mapState({
            manualState: state => state
        }),
        ...mapState({
            manualCheck: state => state.manualCheck
        }),
        ...mapState({
            report: state => state.report
        })
    },
    created() {
        this.getFlows();
        // Initialize the debounced version of asyncFind when the component is created
        this.asyncFindDebounced = this.debounce(this.asyncFind, 300);
    },
    methods: {
        ...mapActions("report", ["getFlows"]),
        ...mapActions("manualCheck", ["getAllProducts", "getServiceFlowState"]),
        validateEmail(email) {
            const emailRegex =
                new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[^0-9_!#$%&'*+/=?`{|}~^.-]{2}[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]*/, "gm");
            const isValidEmail = emailRegex.test(email);
            return isValidEmail;
        },
        //changes sevice flow and updates processCheckObject
        onFlowChange(event) {
            if (event === null) {
                this.filterObject.flowID = null;
                this.processCheckObject.ServiceFlowID = null;
                return;
            } else {
                this.filterObject.flowID = event.id;
                this.processCheckObject.ServiceFlowID = event.id;
                this.getServiceFlowState(this.processCheckObject);
            }
        },
        //gets Service flow values
        flowValue() {
            if (this.filterObject.flowID !== null) {
                return this.report.flowLookup.flows.filter(
                    flow => flow.id === this.filterObject.flowID
                );
            }
        },
        //gets products from database, uses timeout to wait for products to load
        async asyncFind(query) {
            const searchObj =
            {
                searchText: query.trim()
            }

            this.getAllProducts(searchObj).then(response => {
                this.$emit("checkProductsStatus");
                this.isLoading = false;
            })
        },
        //update processCheckObject products
        optionSelected(option, id) {
            this.processCheckObject.Products = this.value;
        },
        getValue() {
            return this.value;
        },
        resetProducts() {
            this.values = []
        },
        //when billing address is hidden, fill object data to make sure billing address passes validation
        fillData() {
            const checkbox = document.getElementById('customCheck11');
            this.processCheckObject.BillingAddress.CustomerFirstName = document.getElementById("firstname").value;
            this.processCheckObject.BillingAddress.CustomerLastName = document.getElementById("lastname").value;
            this.processCheckObject.BillingAddress.Company = document.getElementById("company").value;
            if (checkbox.checked) {
                this.processCheckObject.BillingAddress.Address1 = document.getElementById("inputShipAddress").value;
                this.processCheckObject.BillingAddress.Address2 = document.getElementById("inputShipAddress2").value;
                this.processCheckObject.BillingAddress.City = document.getElementById("inputShipCity").value;
                this.processCheckObject.BillingAddress.State = this.processCheckObject.ShippingAddress.State;
                this.processCheckObject.BillingAddress.PostalCode = document.getElementById("inputShipZip").value;

            }
        },
        //make sure form is filled out entirely, resulting in modals for the unfilled selects
        checkValidation() {
            this.$refs.form.checkValidity()

            this.processCheckObject.Products = this.value;

            this.processCheckObject.orderID = this.processCheckObject.orderID.trim();
            this.processCheckObject.ShippingAddress.CustomerFirstName = this.processCheckObject.ShippingAddress.CustomerFirstName.trim();
            this.processCheckObject.ShippingAddress.CustomerLastName = this.processCheckObject.ShippingAddress.CustomerLastName.trim();
            this.processCheckObject.ShippingAddress.Address1 = this.processCheckObject.ShippingAddress.Address1.trim();
            this.processCheckObject.ShippingAddress.Address2 = this.processCheckObject.ShippingAddress.Address2.trim();
            this.processCheckObject.ShippingAddress.City = this.processCheckObject.ShippingAddress.City.trim();
            if (this.processCheckObject.ShippingAddress.State != null) {
                this.processCheckObject.ShippingAddress.State = this.processCheckObject.ShippingAddress.State.trim();
            }
            this.processCheckObject.ShippingAddress.PostalCode = this.processCheckObject.ShippingAddress.PostalCode.trim();
            if (this.processCheckObject.ShippingAddress.Company != null && this.processCheckObject.ShippingAddress.Company != "") {
                this.processCheckObject.ShippingAddress.Company = this.processCheckObject.ShippingAddress.Company.trim();
            }
            if (this.processCheckObject.ShippingAddress.Company === "") {
                this.processCheckObject.ShippingAddress.Company = null;
            }
            this.processCheckObject.BillingAddress.CustomerFirstName = this.processCheckObject.BillingAddress.CustomerFirstName.trim();
            this.processCheckObject.BillingAddress.CustomerLastName = this.processCheckObject.BillingAddress.CustomerLastName.trim();
            this.processCheckObject.BillingAddress.Address1 = this.processCheckObject.BillingAddress.Address1.trim();
            this.processCheckObject.BillingAddress.Address2 = this.processCheckObject.BillingAddress.Address2.trim();
            this.processCheckObject.BillingAddress.City = this.processCheckObject.BillingAddress.City.trim();
            if (this.processCheckObject.BillingAddress.State != null) {
                this.processCheckObject.BillingAddress.State = this.processCheckObject.BillingAddress.State.trim();
            }
            this.processCheckObject.BillingAddress.PostalCode = this.processCheckObject.BillingAddress.PostalCode.trim();
            if (this.processCheckObject.BillingAddress.Company != null && this.processCheckObject.ShippingAddress.Company != "") {
                this.processCheckObject.BillingAddress.Company = this.processCheckObject.BillingAddress.Company.trim();
            }
            if (this.processCheckObject.BillingAddress.Company === "") {
                this.processCheckObject.BillingAddress.Company = null;
            }

            if (this.manualCheck.productList.serviceFlowHas != null) {
                if (this.manualCheck.productList.serviceFlowHas.result.length > 0)// check if the service flow has credential collection enabled (if results has content, it needs email)
                {
                    //require email address
                    this.emailState = this.validateEmail(this.processCheckObject.CustomerEmail);
                    if (this.processCheckObject.CustomerEmail != null) {
                            this.processCheckObject.CustomerEmail = this.processCheckObject.CustomerEmail.trim();
                        }
                } else { //doesnt require
                    if (this.processCheckObject.CustomerEmail == "" || this.processCheckObject.CustomerEmail == null) {
                        this.emailState = null;
                        this.processCheckObject.CustomerEmail = null;
                    }
                    else {
                        this.emailState = this.validateEmail(this.processCheckObject.CustomerEmail);
                        if (this.processCheckObject.CustomerEmail != null) {
                            this.processCheckObject.CustomerEmail = this.processCheckObject.CustomerEmail.trim();
                        }
                    }
                }

            }
            //order number
            this.serviceFlowState = (this.processCheckObject.ServiceFlowID != null && this.processCheckObject.ServiceFlowID != "");
            //order number
            this.orderNumberState = (this.processCheckObject.orderID != null && this.processCheckObject.orderID != "");

            //first and last name
            this.firstNameState = (this.processCheckObject.BillingAddress.CustomerFirstName != null && this.processCheckObject.BillingAddress.CustomerFirstName != "")
                && (this.processCheckObject.ShippingAddress.CustomerFirstName != null && this.processCheckObject.ShippingAddress.CustomerFirstName != "");
            this.lastNameState = (this.processCheckObject.BillingAddress.CustomerLastName != null && this.processCheckObject.BillingAddress.CustomerLastName != "")
                && (this.processCheckObject.ShippingAddress.CustomerLastName != null && this.processCheckObject.ShippingAddress.CustomerLastName != "");
            //shipping address
            this.shippingAddressState = (this.processCheckObject.ShippingAddress.Address1 != null && this.processCheckObject.ShippingAddress.Address1 != "");
            this.shippingCityState = (this.processCheckObject.ShippingAddress.City != null && this.processCheckObject.ShippingAddress.City != "");
            this.shippingStateState = (this.processCheckObject.ShippingAddress.State != null && this.processCheckObject.ShippingAddress.State != "");
            this.shippingPostalCodeState = (this.processCheckObject.ShippingAddress.PostalCode != null && this.processCheckObject.ShippingAddress.PostalCode != "");
            //billing address
            this.billingAddressState = (this.processCheckObject.BillingAddress.Address1 != null && this.processCheckObject.BillingAddress.Address1 != "");
            this.billingCityState = (this.processCheckObject.BillingAddress.City != null && this.processCheckObject.BillingAddress.City != "");
            this.billingStateState = (this.processCheckObject.BillingAddress.State != null && this.processCheckObject.BillingAddress.State != "");
            this.billingPostalCodeState = (this.processCheckObject.BillingAddress.PostalCode != null && this.processCheckObject.BillingAddress.PostalCode != "");

            this.shippingStateState = !(this.processCheckObject.ShippingAddress.State == "None" ||
                this.processCheckObject.ShippingAddress.State == "" ||
                this.processCheckObject.ShippingAddress.State == null);

            this.billingStateState = !(this.processCheckObject.BillingAddress.State == "None" ||
                this.processCheckObject.BillingAddress.State == "" ||
                this.processCheckObject.BillingAddress.State == null);
            //customer email

            if(this.processCheckObject.Products.length == 0)
            {
                return false;
            }

            const retVal = this.idkYet;

            const value = this.serviceFlowState && this.orderNumberState && this.firstNameState && this.lastNameState && this.shippingAddressState && this.shippingCityState && this.shippingStateState
                && this.shippingPostalCodeState && this.billingAddressState && this.billingCityState && this.billingStateState && this.billingPostalCodeState;

            if (this.emailState != null) {

                return value && this.emailState;
            }
            if (!this.shippingStateState) {
                return false;
            }
            if (!this.billingStateState) {
                return false;
            }
            return value;
        },
        // removes red/green borders from form fields
        resetValidation() {
            //order number
            this.orderNumberState = null;
            //first and last name
            this.firstNameState = null;
            this.lastNameState = null;
            //shipping address
            this.shippingAddressState = null;
            this.shippingCityState = null;
            this.shippingStateState = null;
            this.shippingPostalCodeState = null;
            //billing address
            this.billingAddressState = null;
            this.billingCityState = null;
            this.billingStateState = null;
            this.billingPostalCodeState = null;

            this.serviceFlowState = null;
            this.emailState = null;
        },
        // check select/dropdown validation
        check(event) {
            //this.resetValidation()
            if (!this.checkValidation()) {

                const checkbox = document.getElementById('customCheck11');

                this.processCheckObject.Products = this.$refs.multiselect.getValue();
                if (!this.serviceFlowState) {
                    this.$refs.serviceflowwarningalertmodal.show();
                }
                else if (this.processCheckObject.Products.length == 0) {
                    this.$refs.productwarningalertmodal.show();
                    return
                } else {
                    if (checkbox.checked) {
                        if (!this.shippingStateState) {
                            this.$refs.shippingwarningalertmodal.show();
                        }
                    } else {
                        if (!this.shippingStateState && !this.billingStateState) {
                            this.$refs.shippingbillingwarningalertmodal.show();
                        }
                        else if (!this.shippingStateState) {
                            this.$refs.shippingwarningalertmodal.show();
                        }
                        else if (!this.billingStateState) {
                            this.$refs.billingwarningalertmodal.show();
                        }
                    }
                }
                return
            }

            this.resetValidation();
            this.$emit("handleSubmit");
        },
        // set checkbox to correct value and hide/show billing address feilds accordingly
        onClick() {
            this.resetValidation();
            const checkbox = document.getElementById('customCheck11');
            if (checkbox.checked) {
                this.$refs.billing.className = "col-sm-12 hide"
            }
            else {
                this.$refs.billing.className = "col-sm-12 show"
                this.processCheckObject.BillingAddress.Address1 = "";
                this.processCheckObject.BillingAddress.Address2 = "";
                this.processCheckObject.BillingAddress.City = "";
                this.processCheckObject.BillingAddress.State = "";
                this.processCheckObject.BillingAddress.PostalCode = "";

            }

        },
        //reset all fields
        reset(event) {
            this.resetValidation();
            this.$emit("handleReset");
        },
        //handle shipping state dropdown
        onStateChange(event) {
            if (event === null || event.abbr === "None") {
                this.processCheckObject.ShippingAddress.State = null;
                //this.submission()
                return;
            } else {
                this.processCheckObject.ShippingAddress.State = event.abbr;
                //this.submission()
            }
        },
        //handle shipping state dropdown
        stateValue() {
            if (this.processCheckObject.ShippingAddress.State !== null) {
                return this.states.filter(
                    state => state.abbr === this.processCheckObject.ShippingAddress.State
                );
            } else {
                return { abbr: "None" };
            }
        },
        //handle billing state dropdown
        stateBillValue() {
            if (this.processCheckObject.BillingAddress.State !== null) {
                return this.statesBill.filter(
                    state => state.abbr === this.processCheckObject.BillingAddress.State
                );
            } else {
                return { abbr: "None" };
            }
        },
        //handle billing state dropdown
        onStateBillChange(event) {
            if (event === null || event.abbr === "None") {
                this.processCheckObject.BillingAddress.State = null;
                //this.submission()
                return;
            } else {
                this.processCheckObject.BillingAddress.State = event.abbr;
                //this.submission()
            }
        },
        // Debounce function to limit the rate at which a function can fire
        // Parameters:
        // - func: the function we want to debounce
        // - wait: the delay period (in milliseconds) to wait before calling the function after the last trigger
        debounce(func, wait) {
            let timeout; // Variable to hold the timeout ID

            // Return a new debounced function
            return function (...args) {
                // Clear any existing timeout to reset the waiting period if the function is called again
                clearTimeout(timeout);

                // Set a new timeout to call the function after the specified delay
                timeout = setTimeout(() => {
                    func.apply(this, args); // `this` will correctly refer to the original context here
                }, wait);
            };
        }
    },
    props: {
        filterObject: {
            required: true
        },
        processCheckObject: {
            required: true
        }
    }
}

</script>

<style>
.btn btn-primary left {
    margin-left: 50px;
}

.btn btn-primary right {
    margin-right: 50px;
}

.right {
    float: right;
}

#billAddress.hide {
    display: none;
}

#billAddress.show {
    display: "block";
}


.manualTable {
    display: table;
    width: 100%;
}

.manualCell {
    display: table-cell;
    padding: 5px;
    width: 18.3333333%;
}

.manualCellHalf {
    display: table-cell;
    padding: 5px;
    width: 27.5%;
}

.manualCellFill {
    display: table-cell;
    padding: 5px;
    width: 31.5%;
}

.manualCellCity {
    display: table-cell;
    padding: 5px;
    width: 27.5%;
}

.manualCellState {
    display: table-cell;
    padding: 5px;
    width: 10%;
}

.manualCellZip {
    display: table-cell;
    padding: 5px;
    width: 10%;
}

.manualCell23 {
    display: table-cell;
    padding: 5px;
    width: 38%;
}

.manualCell13 {
    display: table-cell;
    padding: 5px;
    width: 17%;
}

.manualCellSpacer {
    display: table-cell;
    padding: 5px;
    width: 6.5%;
}

.manualCellSpacerSM {
    display: table-cell;
    padding: 5px;
    width: 1%;
}

.manualCellLabel {
    display: table-cell;
    text-align: justify;
    margin-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.45rem + 1px);

    margin-bottom: calc(0.5rem + 1px);
    font-size: inherit;
    line-height: 1.5;
    width: 10%;
}

#OrderDetailsComponent .multiselect .multiselect__content-wrapper {
    height: max-content;
    min-width: 100%;
    border-top: 1px solid #e8e8e8;

}

#OrderDetailsComponent .multiselect__input {
    background: none;
}

#OrderDetailsComponent .multiselect--active .multiselect__tags {
    border-bottom: none;
}

.manualLabel {
    margin: 0 auto;
}

.manualCellFull {
    display: table-cell;
    padding: 5px;
    width: 70%;
}



.manualCellEND {
    display: table-cell;
    padding: 5px;
    width: 25%;
}

.manualCellTitle {
    display: table-cell;
    padding: 5px;
    width: 20%;
}

.multiselect__tag {
    background-color: #e0e2e3 !important;
    color: rgb(58, 58, 58);
    font-size: 13px;
}

.multiselect__tag-icon:hover {
    background: red;
}

.multiselect__tag-icon:after {
    color: rgb(58, 58, 58) !important;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
    background: #bfd9f1 !important;
    color: rgb(58, 58, 58);
}

.multiselect__option--selected.multiselect__option--highlight {
    background-color: #f3f3f3 !important;
    color: rgb(58, 58, 58);
}

.multiselect__option--highlight {
    background-color: #f3f3f3 !important;
    color: rgb(58, 58, 58);
}

.text-danger2 {
    color: #EE2A2A
}

.bg-danger2 {
    background-color: #EE2A2A
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: inherit !important;
}

.lockCard .row {
    flex-wrap: nowrap !important;
}
</style>