import config from "config";
import { authHeader, handleResponse } from "../_helpers";

function getOrderReport(report) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(report ? report : {})
  };
  return fetch(`${config.apiUrl}/orderreport`, requestOptions).then(response =>
    handleResponse(response, false, true)
  );
}

function getOrderReportDetails(ids) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ ids })
  };

  return fetch(`${config.apiUrl}/orderreport/details`, requestOptions).then(
    handleResponse
  );
}

function getOrderReportFlows() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(`${config.apiUrl}/lookup/flows`, requestOptions).then(
    handleResponse
  );
}

export const reportService = {
  getOrderReport,
  getOrderReportDetails,
  getOrderReportFlows
};
