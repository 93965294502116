<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- ORDER DETAILS-->
    <OrderDetailsComponent @handleSubmit="handleSubmit" @handleReset="handleReset"
      @checkProductsStatus="checkProductsStatus" ref="productSelect" :filter-object="filterObject"
      :process-check-object="processCheckObject" />

    <!-- FILTERING -->
    <FilterComponent @handleSubmit="handleSubmit" :filter-object="filterObject" id="filter" class="hide" />

    <!-- TABLE -->
    <TableComponent @handleSubmit="handleSubmit" :filter-object="filterObject" id="table" class="hide" ref="results" />

    <!-- Pre Loader Start -->
    <b-table :busy="this.manualCheckState.manualCheck.productList.status.reportLoading">
      <template #table-busy>
        <div id="preloader">
          <div id="status">
            <div class="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </template>
    </b-table>
    <!-- Pre Loader END -->
    <b-modal id="ErrModal" title-class="modalTitle" :title="ErrorModalTitle" @hidden="ErrorModalMessage = ''; ErrorModalTitle = ''" centered hide-footer no-fade :visible="ErrorModalMessage !== ''">
        <p v-html="ErrorModalMessage"></p>
        <div class="row mb-2 float-right">
            <div class="col-auto">
                <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="ErrorModalMessage = ''; ErrorModalTitle = ''">OK</b-button>
            </div>
        </div>
    </b-modal>
  </Layout>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/components/layout/main";
import PageHeader from "@/components/layout/page-header";
import FilterComponent from "@/components/report/FilterComponent";
import TableComponent from "@/components/report/TableComponent";
import OrderDetailsComponent from "@/components/manual_check/OrderDetailsComponent";

/**
 * products component
 */


export default {
  page: {
    title: "products",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    OrderDetailsComponent,
    FilterComponent,
    TableComponent
  },
  data() {
    return {
      title: "Compliance Reporting",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "eCheckpoint",
          href: "/about"
        },
        {
          text: "Report",
          active: true
        }
      ],
      filterObject: {
        startDate: null,
        endDate: null,
        orderID: null,
        skuID: null,
        statusID: null,
        addressTypeID: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        postalCode: null,
        flowID: null,
        pageNumber: 1,
        pageSize: 25
      },
      processCheckObject: {
        orderID: "",
        BillingAddress: {
          CustomerFirstName: "",
          CustomerLastName: "",
          Address1: "",
          Address2: "",
          City: "",
          County: "",
          State: "",
          PostalCode: "",
          Company: ""
        },
        ShippingAddress: {
          CustomerFirstName: "",
          CustomerLastName: "",
          Address1: "",
          Address2: "",
          City: "",
          County: "",
          State: "",
          PostalCode: "",
          Company: ""
        },
        Products: [{
          ID: "",
          Name: ""
        }],
        ServiceFlowID: "",
        CustomerEmail: ""
      },
      ErrorModalMessage: "",
      ErrorModalTitle: "",
    };
  },
  computed: {
    ...mapState({
      manualCheckState: state => state
    })
  },
  created: function () {
    const searchObj =
    {
      searchText: ""
    }
    this.getAllProducts(searchObj)
    this.checkProductsStatus(1000, 0);
  },
  methods: {
    ...mapActions("report", ["getOrderReport"]),
    ...mapActions("manualCheck", ["getResults"]),
    ...mapActions("manualCheck", ["getAllProducts"]),
    //submission from orderDetailsComponent
    async handleSubmit() {
      //send order
      this.$refs.results.$el.className = "row hide";
      this.getResults(this.processCheckObject).then(() => {
        this.checkStatus(10, 0);
      });

    },
    // Get order results on success, alert failed on error.
    checkStatus(i, stop) {
      const timeout = 60000;
      if (stop > timeout) {
        alert("Timed Out");
        return
      } else if (!this.manualCheckState.manualCheck.productList.status.reportLoaded && stop <= timeout) {
        const temp = stop + i;
        setTimeout(() => { this.checkStatus(i, temp); }, i);
      }
      else {
        if (!this.manualCheckState.manualCheck.productList.status.error) {
          this.getOrderResults();
        }
        else {
            if(this.manualCheckState.manualCheck.productList.status.code === 402)
                //alert(this.manualCheckState.manualCheck.productList.status.errorMessage)
            {
                this.ErrorModalMessage = "You've reached the limit of your current plan. But don't worry, we've got the perfect package waiting just for you!<br><br>Please contact us at <a href=\"mailto:accounts@echeckpoint.com\">accounts@echeckpoint.com</a> to discuss the best package to keep your business compliant and running smoothly.<br><br>Thank you for choosing eCheckpoint.";
                this.ErrorModalTitle = 'Account Limit Reached';
            }
            else
                //alert("Order Failed to Process");
            {
                this.ErrorModalMessage = "Order Failed to Process";
                this.ErrorModalTitle = 'Attention';
            }
        }
      }
    },
    // fill product selection after load
    checkProductsStatus(i, stop) {
      if (i == null) {
        this.checkProductsStatus(1000, 0)
      }
      const timeout = 240000;
      if (stop > timeout) {
        alert("Timed Out");
      } else if (this.manualCheckState.manualCheck.productList.status.reportLoadingProducts) {
        const temp = stop + i;
        setTimeout(() => { this.checkProductsStatus(i, temp); }, i);
      }
      else {
        this.$refs.productSelect.options = this.manualCheckState.manualCheck.productList.products;
      }
    },
    // reset manual compliance check
    handleReset() {
      //hides results panel
      this.$refs.results.$el.className = "row hide"
      //resets mutliselect dropdown
      this.$refs.productSelect.value = [];

      //resets Order Number, Customer Name, and Shipping Address (the entire proccess check object)
      this.processCheckObject = {
        orderID: "",
        BillingAddress: {
          CustomerFirstName: "",
          CustomerLastName: "",
          Address1: "",
          Address2: "",
          City: "",
          County: "",
          State: "",
          PostalCode: "",
          Company: ""
        },
        ShippingAddress: {
          CustomerFirstName: "",
          CustomerLastName: "",
          Address1: "",
          Address2: "",
          City: "",
          County: "",
          State: "",
          PostalCode: "",
          Company: ""
        },
        Products: [{
          ID: "",
          Name: ""
        }],
        ServiceFlowID: null,
        CustomerEmail: null,
        Company: null
      };
      //resets filterObject
      this.filterObject = {
        startDate: null,
        endDate: null,
        orderID: null,
        skuID: null,
        statusID: null,
        addressTypeID: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        postalCode: null,
        flowID: null,
        pageNumber: 1,
        pageSize: 25
      };


    },
    //after an order is processed, get the results table showing and filter to the placed compliance check
    async getOrderResults() {
      this.$refs.results.$el.className = "row show";
      this.filterObject.orderID = this.processCheckObject.orderID;
      this.filterObject.firstName = this.processCheckObject.ShippingAddress.CustomerFirstName;
      this.filterObject.lastName = this.processCheckObject.ShippingAddress.CustomerLastName;
      this.filterObject.address = this.processCheckObject.ShippingAddress.Address1;
      this.filterObject.city = this.processCheckObject.ShippingAddress.City;
      this.filterObject.state = this.processCheckObject.ShippingAddress.State;
      this.filterObject.postalCode = this.processCheckObject.ShippingAddress.PostalCode;
      this.getOrderReport(this.filterObject);
      document.getElementById('table').scrollIntoView()
    }
  }
};
</script>
  
<style scoped>
/*.productsCard {
    padding-top: 17px;
  }*/
.productsCard .card {
  /* TEST STYLES*/
  box-shadow: 0 0 15px 0 rgb(154 161 171 / 15%);
  margin-bottom: 0px !important;
  height: 78vh;
}

.productsCard .card .page-aside-right {
  height: 100%;
  padding: 2.5rem 0 0 25px;
}

.productsCard .card .product-table {
  max-height: 88vh !important;
}

.productsCard .card .tagList {
  height: 98vh !important;
}

@media screen and (max-height: 1200px) {
  .productsCard .card .product-table {
    max-height: 60vh !important;
  }

  .productsCard .card .tagList {
    height: 72.8vh !important;
  }
}

@media screen and (max-height: 900px) {
  .productsCard .card {
    height: 73vh;
  }

  .productsCard .card .product-table {
    max-height: 52.5vh !important;
  }

  .productsCard .card .tagList {
    height: 68.6vh !important;
  }
}

@media screen and (max-height: 700px) {
  .productsCard .product-table {
    max-height: 74vh !important;
  }

  .productsCard .tagList {
    height: 94vh !important;
  }
}

.visually-hidden {
  visibility: hidden;
}

.marginSwitch {
  margin-bottom: 4px;
}

.filterLogo {
  width: unset;
  position: unset;
}

.left-panel {
  width: 240px;
  float: left;
}

.importDate {
  font-size: .8rem;
}

.importDateCol {
  padding: 0px;
}

.importDateContainer {
  padding-top: 8px
}

.table-responsive {
  margin-bottom: 0px;
}

div#bulkAction>button {
  color: #6c757d;
}

#hideme {
  display: none;
}

#partialhide {
  display: none;
}

#table.show {
  display: block;
}

#table.hide,
#filter.hide {
  display: none;
}

.lockCard .row {
  flex-wrap: nowrap !important;
}
</style>