import config from "config";
import { authHeader, handleResponse, downloadFile } from "../_helpers";

function downloadOrderReport(report) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(report ? report : {})
  };

  return fetch(`${config.apiUrl}/export/csv`, requestOptions)
    .then(response => handleResponse(response, true))
    .then(text => downloadFile(text, "export.csv", "text/csv"));
}

export const exportService = {
  downloadOrderReport
};
