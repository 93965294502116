<template>
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div id="container" class="align-items-center d-flex h-100">
        <div class="card-body">
          <!-- Logo -->
          <logo-content></logo-content>

          <!-- password inputs -->
          <div
            v-show="
              !passwordChangeSuccess && !linkInvalid && !passwordChangeFail
            "
          >
            <!-- title-->
            <h4 class="mt-0">Reset Password</h4>
            <p class="text-muted mb-4">Enter your new password below.</p>

            <!-- <b-alert v-model="isResetError" variant="danger" dismissible>{{error}}</b-alert>

            <b-alert v-model="isSuccess" variant="success" dismissible>{{successMessage}}</b-alert> -->

            <b-form @submit.prevent="tryToReset">
              <b-form-group
                id="input-group1"
                class="mb-3"
                label="Password"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  required
                  placeholder="Enter your password"
                  autocomplete="off"
                  :state="passwordState"
                  @input="checkPassword"
                ></b-form-input>
                <b-form-invalid-feedback :state="passwordState"
                  >Invalid Password</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                id="input-group2"
                class="mb-3"
                label="Confirm Password"
                label-for="retypePassword"
              >
                <b-form-input
                  id="retypePassword"
                  v-model="retypePassword"
                  type="password"
                  required
                  placeholder="Re-enter your password"
                  autocomplete="off"
                  :state="passwordState"
                  @input="checkPassword"
                ></b-form-input>
                <b-form-invalid-feedback :state="passwordState"
                  >Invalid Password</b-form-invalid-feedback
                >
              </b-form-group>

              <div>
                <ul class="passwordUl">
                  <li
                    class="passwordLi"
                    v-bind:class="{ is_valid: containsEightCharacters }"
                  >
                    8 Characters
                  </li>
                  <li
                    class="passwordLi"
                    v-bind:class="{ is_valid: containsNumber }"
                  >
                    Contains Number
                  </li>
                  <li
                    class="passwordLi"
                    v-bind:class="{ is_valid: containsUppercase }"
                  >
                    Contains Uppercase
                  </li>
                  <li
                    class="passwordLi"
                    v-bind:class="{ is_valid: containsSpecialCharacter }"
                  >
                    Contains Special Character
                  </li>
                  <li
                    class="passwordLi"
                    v-bind:class="{ is_valid: matchingPasswords }"
                  >
                    Passwords Match
                  </li>
                </ul>
              </div>

              <div class="form-group mb-0 text-center">
                <b-button
                  class="btn btn-block userButton"
                  type="submit"
                  variant="primary"
                  ><i class="mdi mdi-lock-reset"></i> Reset Password</b-button
                >
              </div>
            </b-form>
            <!-- end form-->
          </div>
          <!-- end password inputs -->

          <!-- key invalid -->
          <div v-show="!passwordChangeSuccess && linkInvalid">
            <!-- icon with text -->
            <div class="text-center m-auto">
              <i class="uil uil-times-circle fontLarge text-danger" />
              <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                Link Expired or Invalid
              </h4>
              <p class="text-muted mb-4">
                Please try again or contact support.
              </p>
            </div>

            <!-- button -->
            <div class="form-group mb-0 text-center">
              <a class="btn btn-primary btn-block userButton" href="/login">
                Back to Login
              </a>
            </div>
          </div>
          <!-- end key invalid -->

          <!-- password change success -->
          <div
            v-show="
              passwordChangeSuccess && !linkInvalid && !passwordChangeFail
            "
          >
            <!-- icon with text -->
            <div class="text-center m-auto">
              <i class="uil uil-check-circle fontLarge text-success" />
              <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                Password Successfully Reset
              </h4>
              <p class="text-muted mb-4">
                You can now login to your account with your new password.
              </p>
            </div>

            <!-- button -->
            <div class="form-group mb-0 text-center">
              <a class="btn btn-primary btn-block userButton" href="/login">
                Login
              </a>
            </div>
          </div>
          <!-- end password change success -->

          <!-- password change fail -->
          <div
            v-show="
              !passwordChangeSuccess && !linkInvalid && passwordChangeFail
            "
          >
            <!-- icon with text -->
            <div class="text-center m-auto">
              <i class="uil uil-times-circle fontLarge text-danger" />
              <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                Password Change was Unsuccessful
              </h4>
              <p class="text-muted mb-4">
                Please try again or contact support.
              </p>
            </div>

            <!-- button -->
            <div class="form-group mb-0 text-center">
              <a class="btn btn-primary btn-block userButton" href="/login">
                Back to Login
              </a>
            </div>
          </div>
          <!-- end password change fail -->
        </div>
        <!-- end card-body -->
        <div
          v-show="!passwordChangeSuccess && !linkInvalid && !passwordChangeFail"
        >
          <footer class="footer footer-alt">
            <p class="text-muted">
              Back to<a href="/login" class="text-muted ml-1"><b>Log In</b></a>
            </p>
          </footer>
        </div>
        <!-- If password change was successful or key is not valid -->
        <div
          v-show="passwordChangeSuccess || linkInvalid || passwordChangeFail"
        >
          <footer class="footer footer-alt">
            <p class="text-muted">
              CONFIDENTIAL {{ new Date().getFullYear() }} RICO Technologies LLC
            </p>
          </footer>
        </div>
      </div>
      <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <auth-fluid-content></auth-fluid-content>
    <!-- end Auth fluid right content -->
  </div>
  <!-- end Auth fluid -->
</template>

<script>
// import { account } from '@/src/_store/account.module';
import { mapState, mapActions } from "vuex";
import AuthFluidContent from "../../components/login/AuthFluidContent";
import LogoContent from "../../components/login/LogoContent";
// import { userService } from '../../_services';

export default {
  data() {
    return {
      password: null,
      passwordState: null,
      retypePassword: null,
      passwordLength: 0,
      containsEightCharacters: false,
      containsNumber: false,
      containsUppercase: false,
      containsSpecialCharacter: false,
      matchingPasswords: false,
      validPassword: false,
      key: null
    };
  },

  components: { AuthFluidContent, LogoContent },
  created() {
    this.key = this.$route.query.key;
    this.resetPassword(this.key);
  },
  // mounted() {
  //   this.$nextTick(function () {
  //     // if(this.account.userId === null) {
  //     //   this.$router.push({ path: 'login' })
  //     // }
  //     console.log(this.account.user)
  //   })
  // },
  computed: {
    ...mapState({
      account: state => state.account
    }),
    passwordChangeSuccess: function() {
      return this.account.status.passwordChange === true;
    },
    passwordChangeFail: function() {
      return this.account.status.passwordChangeFail === true;
    },
    linkInvalid: function() {
      return this.account.status.keyInvalid === true;
    }
  },

  methods: {
    ...mapActions("account", {
      resetPassword: "resetPassword",
      submitPassword: "submitPassword"
    }),
    checkPassword() {
      this.passwordLength = this.password.length;
      // eslint-disable-next-line
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.passwordLength >= 8) {
        this.containsEightCharacters = true;
      } else {
        this.containsEightCharacters = false;
      }

      this.containsNumber = /\d/.test(this.password);
      this.containsUppercase = /[A-Z]/.test(this.password);
      this.containsSpecialCharacter = format.test(this.password);
      this.matchingPasswords =
        this.password === this.retypePassword && this.password !== "";

      if (
        this.containsEightCharacters === true &&
        this.containsSpecialCharacter === true &&
        this.containsUppercase === true &&
        this.containsNumber === true &&
        this.matchingPasswords === true
      ) {
        this.validPassword = true;
        this.passwordState = true;
      } else {
        this.validPassword = false;
      }
    },
    tryToReset() {
      if (this.validPassword === false) {
        this.passwordState = false;
        return;
      }

      this.submitPassword({ key: this.key, password: this.password });
    }
  }
};
</script>

<style>
.passwordUl {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* list-style-type:none; */
}

.passwordLi {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
  font-size: 13px;
}

.passwordLi:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  background: #2ecc71;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.fontLarge {
  font-size: 50px;
}

/* #button {
  box-shadow: 0px 2px 6px 0px #4a90e2;
  background-color: #4a90e2;
  border: #4a90e2;
}  */
</style>
