import { manualCheckService } from "../_services";
import moment from "moment";

const state = {
  productList: {
    status: {},
    serviceflowStatus: {},
    importStatus: {},
    updateStatus: {},
    products: null,
    serviceFlowHas: null,
    productCount: null,
    lastImportDate: null,
    error: null
  }
};


const actions = {
  getResults({ dispatch, commit }, proccessCheckObject) {
    commit("getResultsRequest");

    manualCheckService.getResults(proccessCheckObject).then(
      response => {
        commit("getResultsSuccess", response);
      },
      error => {
        commit("getResultsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  getServiceFlowState({ dispatch, commit }, proccessCheckObject) {
    commit("getServiceFlowStateRequest");

    manualCheckService.getServiceFlowState(proccessCheckObject).then(
      response => {
        commit("getServiceFlowStateSuccess", response);
      },
      error => {
        commit("getServiceFlowStateFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  getAllProducts({ dispatch, commit }, searchObject) {
    commit("getAllProductsRequest");

    manualCheckService.getAllProducts(searchObject).then(
      response => {
        commit("getAllProductsSuccess", response);
      },
      error => {
        commit("getAllProductsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  }
};

const mutations = {
  getResultsRequest(state) {
    state.productList.status = { 
      reportLoading: true,
      reportLoaded: false
    };
  },
  getResultsSuccess(state, response) {
   state.productList.status = { 
    reportLoading: false,
    reportLoaded: true,
    error: false
  }
  },
  getResultsFailure(state, error) {
    //console.log(error)
    state.productList.status = { 
      reportLoading: false,
      reportLoaded: true,
      error: true,
      errorMessage: error.text,
      code: error.status
    }
    state.productList.orders = error;
  },

  getServiceFlowStateRequest(state) {
    state.productList.serviceFlowStatus = { reportLoadingFlows: true };
  },
  getServiceFlowStateSuccess(state, response) {
     state.productList.serviceFlowHas = response;
     state.productList.serviceFlowStatus = { reportLoadingFlows: false };
  },
  getServiceFlowStateFailure(state, error) {
    state.productList.serviceFlowStatus = { reportLoadingFlows: false };
    state.productList.orders = error;
  },

  getAllProductsRequest(state) {
    state.productList.status = { reportLoadingProducts: true };
  },
  getAllProductsSuccess(state, response) {
     state.productList.products = response.products;
     state.productList.status = { reportLoadingProducts: false };
  },
  getAllProductsFailure(state, error) {
    state.productList.status = { reportLoadingProducts: false };
    state.productList.orders = error;
  }
};

export const manualCheck = {
  namespaced: true,
  state,
  actions,
  mutations
};
