<template>
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div id="container" class="align-items-center d-flex h-100">
        <div class="card-body">
          <!-- Logo -->
          <logo-content></logo-content>

          <!-- title-->
          <h4 class="mt-0">Forgot Password</h4>
          <p class="text-muted mb-4">
            Enter your email address and we'll send you an email with
            instructions to reset your password.
          </p>

          <!-- <b-alert v-model="isResetError" variant="danger" dismissible>{{error}}</b-alert>

          <b-alert v-model="isSuccess" variant="success" dismissible>{{successMessage}}</b-alert> -->

          <b-form @submit.prevent="sendEmail(email)">
            <b-form-group
              id="email-group"
              class="mb-3"
              label="Email address"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                required
                placeholder="Enter your email"
              ></b-form-input>
            </b-form-group>

            <div class="form-group mb-0 text-center">
              <b-button
                class="btn btn-block userButton"
                type="submit"
                variant="primary"
                ><i class="mdi mdi-lock-reset"></i> Reset Password</b-button
              >
            </div>
          </b-form>
          <!-- end form-->
        </div>
        <!-- end card-body -->
        <footer class="footer footer-alt">
          <p class="text-muted">
            Back to<a href="/login" class="text-muted ml-1"><b>Log In</b></a>
          </p>
        </footer>
      </div>
      <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <auth-fluid-content></auth-fluid-content>
    <!-- end Auth fluid right content -->
  </div>
  <!-- end Auth fluid -->
</template>

<script>
// import { mapState, mapActions } from 'vuex'

import AuthFluidContent from "../../components/login/AuthFluidContent";
import LogoContent from "../../components/login/LogoContent";
import { userService } from "../../_services";

export default {
  data() {
    return {
      email: ""
    };
  },
  components: { AuthFluidContent, LogoContent },
  methods: {
    sendEmail(email) {
      userService.sendMail(email);
      this.$router.push({ path: "recover-confirm" });
    }
  }
};
</script>

<style>
/* #button {
  box-shadow: 0px 2px 6px 0px #4a90e2;
  background-color: #4a90e2;
  border: #4a90e2;
}  */
</style>
