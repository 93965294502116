<template>
  <Layout>
    <PageHeader title="Product Manager" /> 
    <!-- <product-edit-modal
      :passedProduct="editProduct"
      :selected-products="selectedProducts"
      :mode="productEditingMode"
      @singleProductEdit="singleProductEdit"
    /> -->
    <AddProduct
    :add-product-object="addProductObject"
    ref="addProductRef"
    @hideModal="hideModal"
    @searchProducts="searchProducts"
    />
    <div class="row">
      <div class="col-12 productsCard">
        
        <div class="card mb-0">
          
          <div class="card-body">
            <div class="left-panel">
              <!-- <a
                href="/"
                class="logo text-center filterLogo"
              >
                <span class="logo-lg">
                  <img
                    id="side-main-logo"
                    src="../../design/assets/images/OriginalNoText2.png"
                    alt
                    height="40"
                  />
                </span>
                
              </a> -->
              <!-- <div class="row mb-1">
                <h4 class="header-title ml-2">Tags</h4>
              </div> -->
              <TagFilter
                @applyTagFilter="filterProductsByTag"
                @searchEdit="searchEdit"
                :tags-show-none-only-default="tagsShowNoneOnly"
                :tag-match-any-default="tagMatchAny"
                :passed-tags="productDesignation.productDesignationsList.designations"
                :current-filtered-tag="currentFilteredTags"
              />
            </div>
            
            <div class="page-aside-right" >
              <!-- <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-left"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="search"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      />
                    </label>
                  </div>
                </div>
                Search
                <div class="col-sm-12 col-md-6 text-md-right">
                  <div class="btn btn-group">
                    <button
                      v-if="currentlySelectedMultiple"
                      class="btn echeckpointOrange"
                      v-b-modal.product-edit-modal
                    >
                      Edit Multiple
                    </button>
                    <button
                      class="btn echeckpointBlue"
                      @click="this.productEditingMode = 'new'"
                      v-b-modal.product-edit-modal
                    >
                      <i class="mdi mdi-plus" />
                    </button>
                  </div>
                </div>
                End search
              </div> -->
            <!-- <div class="row mb-1">
              <h4 class="header-title ml-2">Products</h4>
            </div> -->
            <div class="row mb-2">
              <div class="col-sm-auto">
                <!-- Search -->
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="getProductsObject.searchText"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm"
                      @keypress.enter="onSearchClick"
                      @input="onSearchInput"
                      ref="searchProducts"
                    ></b-form-input>
                    <b-button
                      class="ml-1 echeckpointBlue btn"
                      id="searchButton"
                      ref="search"
                      type="submit"
                      size="sm"
                      @click="onSearchClick"
                      >Search</b-button
                    >
                    <b-button id="NewProdBtn" class="btn ml-1 echeckpointOrange" size="sm" style="white-space: nowrap;" @click="showModal">Add Product</b-button>
                  </label>
                  
                  
                </div>
               
              <!-- End search -->
              </div>
              <div v-if="this.account.user.isUserIntegrated" class="col importDateCol">
                <div v-if="product.productList.status.productsLoaded && !product.productList.importStatus.productsImporting && product.productList.productCount > 0" class="float-right mb-0 text-muted importDateContainer">
                  Last imported: <span class="importDate"> {{formatDateLocal(product.productList.lastImportDate)}} </span>
                </div>
                <div v-if="!product.productList.status.productsLoaded || product.productList.importStatus.productsImporting" class="spinner-border float-right" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div v-if="this.account.user.isUserIntegrated" class="col-sm-auto">
                <a @click="onImportClick" class="btn echeckpointOrange mb-2">
                  <i class="mdi mdi-plus-circle mr-2"></i>Import
                </a>
              </div>
              
            </div>
            <div class="row mb-1">
              <div class="col-auto">
                <label class="col-form-label col-form-label-md">Status</label>
              </div>
              <div class="col-3 ml-auto">
                <b-form-group class="col-form-label col-form-label-md mb-0">
                  <b-form-radio-group
                    id="status-radio-group"
                    v-model="getProductsObject.active"
                    :options="statusOptions"
                    name="status-radio"
                    @change="onStatusChange"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <!--<div class="col-auto">
                <label class="col-form-label col-form-label-md">Bulk Activate</label>
              </div>-->
              <div class="col-2 ml-auto">
                <!--<b-form-group class="col-form-label col-form-label-md mb-0">
                  <div class="isActiveCellDiv">
                    <input type="checkbox" id="bulkActivate" @change="onActivateChange($event)" :checked="false" :value="false" data-switch="success"/>
                    <label for="bulkActivate" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>-->
                    <div>
                      <b-dropdown id="bulkAction" text="Bulk Actions" variant="light" size="sm" class="mb-0 d-block test">
                        <b-dropdown-item @click="onActivateChange($event, true)">Activate</b-dropdown-item>
                        <b-dropdown-item @click="onActivateChange($event, false)">Deactivate</b-dropdown-item>
                        <!--<b-dropdown-item>Third Action</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item active>Active action</b-dropdown-item>
                        <b-dropdown-item disabled>Disabled action</b-dropdown-item>-->
                      </b-dropdown>
                    </div>
                  <!--</div>
                </b-form-group>-->
              </div>
              
              <div class="col">
                <div class="text-sm-right">
                  <!-- <button type="button" class="btn btn-success mb-2 mr-1"><i class="mdi mdi-settings"></i></button>
                    <button type="button" class="btn btn-light mb-2 mr-1">Import</button> -->
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-select
                      @input="onPageSizeChange"
                      v-model="getProductsObject.pageSize"
                      size="sm"
                      class="mr-1 ml-1"
                      :options="pageOptions"
                    ></b-select>
                    entries
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <hyperNav
                  :pageNumber="getProductsObject.pageNumber"
                  :pageCount="pageCount"
                  :gettingProductsStatus="product.productList.status.gettingProducts"
                  @updateCurrentPage="onPageChange"
                />
              </div>
            </div>
              <div class="table-responsive">
                <ProductTable
                  :quick-filter="search"
                  :current-filtered-tags="currentFilteredTags"
                  :tag-match-any="tagMatchAny"
                  :show-tagless="tagsShowNoneOnly"
                  :add-variant-object="addVariantObject"
                  :add-product-object="addProductObject"
                  :edit-object="editObject"
                  :get-products-object="getProductsObject"
                  @searchVariants="searchVariants"
                  @searchProducts="searchProducts"
                  @searchDelete="searchDelete"
                  @searchEdit="searchEdit"
                  @singleProductEdit="singleProductEdit"
                  @removeTagFromProduct="removeTagFromProduct"
                  @multipleSelected="multipleSelected"
                  @customTagDrag="handleTagCustomDrag"
                  @getProductsAfterTag="getProductsAfterTag"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>




<script>
import { mapState, mapActions } from "vuex";
import { formatDate, formatDateLocal } from "../../_helpers";
import appConfig from "@/app.config";
import Layout from "@/components/layout/main";
import PageHeader from "@/components/layout/page-header";
import ProductTable from "@/components/products/ProductTable";
import TagFilter from "@/components/products/TagFilter";
// import ProductEditModal from "@/components/products/productEditModal";
import HyperNav from "@/components/products/HyperNav";
import AddProduct from "@/components/products/AddProduct";
// import Switches from 'vue-switches'

/**
 * products component
 */
export default {
  name: "ProductPage",
  page: {
    title: "products",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    // ProductEditModal,
    TagFilter,
    Layout,
    PageHeader,
    ProductTable,
    HyperNav,
    AddProduct,
    // Switches
  },
  created() {
    this.getProducts(this.getProductsObject)
    this.getProductDesignations()
  },
  computed: {
    ...mapState({
      account: state => state.account,
      product: state => state.product,
      productDesignation: state => state.productDesignation
    }),
    pageCount() {debugger;
      if (this.product.productList.status.productsLoaded === true) {
        return Math.ceil(
          this.product.productList.productCount / this.getProductsObject.pageSize
        );
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      title: "Product Classification",
      pageOptions: [10, 25, 50, 100],
      filterTag: null,
      widths: [10, 11],
      search: "",
      editProduct: {},
      selectedProducts: [],
      currentFilteredTags: [],
      tagMatchAny: true,
      tagsShowNoneOnly: false,
      currentlySelectedMultiple: false,
      productEditingMode: "single",
      getProductsObject: {
        searchText: null,
        pageNumber: 1,
        pageSize: 50,
        active: null,
        tagId: null,
        checkedItems: [],
        onlyParentFilter: false
      },
      updateProductObject: {
        productIds: [],
        active: null
      },
      productImportObject: {
        userId:null,
        lastImportedDate:null
      },
      statusOptions: [
        { text: "All", value: null },
        { text: "Active", value: true },
        { text: "Inactive", value: false }
      ],
      addProductObject: {
        active: false,
        description: "",
        name: null,
        SubscriberParentProductID: null,
        SubscriberProductID: null,
        parentName: null,
        parentDescription: ""
      },
      addVariantObject: {
        active: false,
        description: "",
        name: null,
        SubscriberParentProductID: null,
        SubscriberProductID: null,
        parentName: null,
        parentDescription: ""
      },
      editObject: {
        active: false,
        description: "",
        name: null,
        SubscriberParentProductID: null,
        SubscriberProductID: null,
        parentName: null,
        parentDescription: ""
      }
    };
  },
  methods: {
    ...mapActions("product", ["getProducts", "importProducts", "updateProduct"]),
    ...mapActions("productDesignation", ["getProductDesignations", "addProductDesignation", "deleteProductDesignation"]),
    formatDate: formatDate,
    formatDateLocal: formatDateLocal,
    showModal(){
      this.$refs.addProductRef.reset()
      this.$bvModal.show("modal-addProduct");
    },
    hideModal(){
      this.$nextTick(() => {
      this.$bvModal.hide("modal-addProduct");
      });

    },
    searchProducts(){
      this.getProductsObject.searchText = this.addProductObject.SubscriberProductID;
      this.onSearchClick();
    },
    searchDelete(){
      this.onSearchClickEdit();
    },
    searchVariants(){
      this.getProductsObject.searchText = this.addVariantObject.SubscriberProductID;
      this.onSearchClick();
    },
    searchEdit(){
      //this.getProductsObject.searchText = this.editObject.name;
      //console.log("madeit")
      this.onSearchClickEdit();
      return this.getProductsObject;
    },
    updateParentTags(value) {
      this.currentFilteredTags = value.map(item => item.id);
      
    },
    handleTagCustomDrag(draggedData) {
      //console.log("Custom Drag!");
      //console.log(draggedData);
    },
    handleCustomDrop() {
      //console.log("customDrop");
      
    },
    singleProductEdit(data) {
      this.productEditingMode = "single";
      this.editProduct = data;
      this.selectedProducts = [data.subscriberProductID];
    },
    tagMatchAnyToggle(data) {
      this.tagMatchAny = data;
    },
    tagsShowNoneOnlyToggle(data) {
      this.tags.forEach(tag => (tag.filtered = false));
      this.tagsShowNoneOnly = data;
    },
    multipleSelected(data) {debugger;
      this.currentlySelectedMultiple = data.length > 1;
      if (this.currentlySelectedMultiple) {
        this.productEditingMode = "multiple";
      }
      this.selectedProducts = data;
    },
    getProductsAfterTag(checkedItems){ debugger;
      //this.getProductsObject.checkedItems = checkedItems;
      //this.getProducts(this.getProductsObject);
    },
    filterProductsByTag(tagId) {
      if (this.filterTag && this.filterTag === tagId) {
        this.getProductsObject.tagId = null
        this.filterTag = null
      }
      else {
        this.getProductsObject.tagId = tagId
        this.filterTag = tagId
      }
      this.getProductsObject.pageNumber = 1
      this.getProducts(this.getProductsObject)
    },
    removeTagFromProduct(data) {
      const productToUpdate = data[0];
      const tagId = data[1];
      //Find the object in the parent propagation to remove it properly - Todo: move to Vuex
      const parentProduct = Object(
        this.products.find(product => productToUpdate.id === product.id)
      );
      const tagLocation = parentProduct.tagIds.indexOf(tagId);
      if (tagLocation !== -1) {
        this.products.splice(tagLocation, 1);
      }
      console.log(tagLocation);
    },
    onPageSizeChange() {
      this.getProductsObject.pageNumber = 1;
      this.getProducts(this.getProductsObject);
    },
    onPageChange(page) {
      this.getProductsObject.pageNumber = this.getProductsObject.pageNumber + page;

      if (this.getProductsObject.pageNumber === 0) {
        this.getProductsObject.pageNumber = 1;
        return;
      }
      this.getProducts(this.getProductsObject);
    },
    onStatusChange() {
      this.getProductsObject.pageNumber = 1;
      this.getProducts(this.getProductsObject);
    },
    onSearchClickEdit() {
      this.getProducts(this.getProductsObject);
    },
    onSearchClick() {
      this.getProductsObject.pageNumber = 1;
      this.getProducts(this.getProductsObject);
    },
    onSearchInput(event) {
      if (event === "") {
        this.getProductsObject.pageNumber = 1;
        this.getProducts(this.getProductsObject);
      }
    },
    onImportClick() {
      if (!this.product.productList.importStatus.productsImporting && this.product.productList.status.productsLoaded) {
        this.productImportObject.userId = this.account.user.id
        this.productImportObject.lastImportedDate = this.product.productList.lastImportDate.toISOString().split("T")[0]
        this.importProducts(this.productImportObject)
      }
    },
    onActivateChange(event, isChecked) {
      //check if there are any selected products
      if(this.selectedProducts != null && this.selectedProducts.length > 0)
      {
        this.updateProductObject.productIds = [];
        //look through selected products to stored ids 
        for (const product of this.selectedProducts) {
          product.active = isChecked;
          this.updateProductObject.active = isChecked;
          this.updateProductObject.productIds.push(product.id);

          //if product has parentID, find parent
          //select it and activate as well
          if(product.parentID != null)
          {
            this.product.productList.products.find(item => item.id == product.parentID).active = isChecked;
            this.product.productList.products.find(item => item.id == product.parentID).checked = true;
            this.updateProductObject.productIds.push(product.parentID);
          }
        }

        this.updateProductObject.productIds = this.updateProductObject.productIds.join(",");
        this.updateProduct(this.updateProductObject);
      }
      
    }
  }
};
</script>

<style>

/*.productsCard {
  padding-top: 17px;
}*/
  .productsCard .card{
    /* TEST STYLES*/
    box-shadow: 0 0 15px 0 rgb(154 161 171 / 15%);
    margin-bottom: 0px !important;
    height: 78vh;  
  }
    .productsCard .card .page-aside-right {
      height: 100%;
      padding: 2.5rem 0 0 25px;
    }
    .productsCard .card .product-table {
      max-height: 88vh !important;
    }
    .productsCard .card .tagList {
      height: 98vh !important;
  }

  @media screen and ( max-height: 1200px )
  {
    .productsCard .card .product-table {
      max-height: 60vh !important;
    }
    .productsCard .card .tagList {
      height: 72.8vh !important;
    }
  }
  @media screen and ( max-height: 900px )
    {
    .productsCard .card{
    height: 73vh;  
  }
  .productsCard .card .product-table {
      max-height: 52.5vh !important;
    }
    .productsCard .card .tagList {
      height: 68.6vh !important;
    }
  }
  @media screen and ( max-height: 700px )
  {
    .productsCard .product-table {
      max-height: 74vh !important;
    }
    .productsCard .tagList {
      height: 94vh !important;
    }
  }
.visually-hidden {
  visibility: hidden;
}

.marginSwitch {
  margin-bottom: 4px;
}

.filterLogo {
  width: unset;
  position: unset;
}

.left-panel {
    width: 240px;
    float: left;
}

.importDate {
  font-size: .8rem;
}

.importDateCol {
  padding: 0px;
}

.importDateContainer {
  padding-top: 8px
}

.table-responsive {
  margin-bottom: 0px;
}

div#bulkAction > button {
    color: #6c757d;
}

</style>