import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alert.module";
import { account } from "./account.module";
import { report } from "./report.module";
import { users } from "./users.module";
import { product } from "./product.module";
import { productDesignation } from "./productDesignation.module";
import { manualCheck } from "./manual_check.module";
import { settings } from "./settings.module";
import { credentialReview } from "./credential_review.module";
import { idme } from "./IDme.module";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    alert,
    account,
    users,
    report,
    product,
    productDesignation,
    manualCheck,
    settings,
    credentialReview,
    idme
  }
});

export default store;
