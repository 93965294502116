<template>
  <div class="row">
    <div class="col">
      <div
        class="dataTables_paginate paging_simple_numbers float-right"
      >
        <!-- <ul class="pagination pagination-rounded mb-0">
            pagination
            <b-pagination @input="onPageChange" v-model="filterObject.pageNumber" :total-rows="5" :per-page="filterObject.pageSize"></b-pagination>
          </ul> -->
        <div class="row">
          <div class="col-auto">
            <span
              v-if="
                this.pageNumber !== 0 &&
                  this.pageNumber !== 1
              "
              id="previousPageContainer"
            >
              <button
                tabindex="-1"
                @click="onPageChange(-1)"
                class="btn icon p-0 buttonHover"
                id="previousButton"
                title="Previous Page"
              >
                <i class="uil uil-angle-left font-22"></i>
              </button>
            </span>
          </div>

          <div class="col-auto alignCenter">
            <!-- :class="{'paddingTop' : pageNumber === 1 && pageCount === 1}" -->
            <div class="extraPadding paddingTop" >
              {{ pageCount === 0 ? 1 : pageNumber }} of
              {{ pageCount === 0 ? 1 : pageCount }}
            </div>
          </div>

          <div class="col-auto">
            <span
              v-if="this.pageCount !== 0 && this.pageCount !== this.pageNumber && !gettingProductsStatus === true"
              id="nextPageContainer"
            >
              <button
                tabindex="-1"
                @click="onPageChange(1)"
                class="btn icon float-right p-0 buttonHover"
                id="nextButton"
                title="Next Page"
              >
                <i class="uil uil-angle-right font-22"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "hyperNav",
  props: ["pageCount", "pageNumber", "gettingProductsStatus"],
  methods: {
    onPageChange(pageNum) {
      this.$emit("updateCurrentPage", pageNum);
    }
  }
};
</script>


<style>

.extraPadding {
  padding-top: 3px;
}

.paddingTop {
  padding-top: 6px !important;
}
</style>