import config from "config";
import { authHeader, handleResponse } from "../_helpers";

function getProducts(productDto) {;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productDto ? productDto : {} )
  };
  return fetch(`${config.apiUrl}/productreport`, requestOptions).then(response =>
    handleResponse(response, false, true)
  );
}

function getFilteredProducts(searchText) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      searchText: searchText
    }),
  };
  return fetch(`${config.apiUrl}/productreport/getfilteredresults`, requestOptions).then(response =>
    handleResponse(response, false, true)
  );
}

function addProduct(productDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productDto ? productDto : {} )
  };

  return fetch(`${config.apiUrl}/productreport/create`, requestOptions).then(
    handleResponse
  );
}

function updateProduct(productDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productDto ? productDto : {} )
  };

  return fetch(`${config.apiUrl}/productreport/edit`, requestOptions).then(
    handleResponse
  );
}

function deleteProduct(productDto) {
   const requestOptions = {
     method: "DELETE",
     headers: { ...authHeader(), "Content-Type": "application/json" },
     body: JSON.stringify(productDto ? productDto : {})
   };

   return fetch(`${config.apiUrl}/productreport/delete`, requestOptions).then(
     handleResponse
   );
}

function importProducts(productImportDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productImportDto ? productImportDto : {} )
  };

  return fetch(`${config.engineUrl}/import`, requestOptions).then(
    handleResponse
  );
}

function searchProducts(productDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productDto ? productDto : {} )
  };
  return fetch(`${config.apiUrl}/productreport/search`, requestOptions).then(response =>
    handleResponse(response, false, true)
  );
}

export const productService = {
    getProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    importProducts,
    searchProducts,
    getFilteredProducts
};
