import { render, staticRenderFns } from "./CredentialSettings.vue?vue&type=template&id=3be8471a&scoped=true&"
import script from "./CredentialSettings.vue?vue&type=script&lang=js&"
export * from "./CredentialSettings.vue?vue&type=script&lang=js&"
import style0 from "./CredentialSettings.vue?vue&type=style&index=0&id=3be8471a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be8471a",
  null
  
)

export default component.exports