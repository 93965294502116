<template>
    <div class="saveButton">
        <button type="button" class="btn userButton btn-secondary saveButton-Button" @click="save()">{{label}}</button>
        <div :class="'saveButton-Status ' + msgDirection" >
            <span>{{state}}</span>
        </div>
    </div>
</template>

<script>


export default {
    components: {},
    props: {
        saveFunc: {
            type: Function,
            required: false,
            default: function(){return null;}
        },
        handle: {
            type: Function,
            required: false,
            default: function(){return null;}
        },
        state: {
            type: String,
            required: false,
            default: " "
        },
        msgDirection: {
            type: String,
            required: false,
            default: "left"//can be left, right, up-growleft, up-growright, down-growleft, or down-growright
        },
        label: {
            type: String,
            required: false,
            default: "Save"
        }
    },
    methods: {
        async save()
        {
            if(this.saveFunc())
            {
                this.state = "Saving";
                try
                {
                    await this.saveFunc();
                    this.state = "Saved"
                    setTimeout(()=>{this.saveState = ""}, 5000);
                }
                catch
                {
                    this.state = "Save Failed";
                    setTimeout(()=>{this.saveState = ""}, 5000);
                }
            }
            else if(this.handle())
                this.handle();
        }
    }
}
</script>


<style scoped>
.saveButton
{
    height: fit-content;
}

.saveButton-Button
{
    margin: 1em;
}

.saveButton-Status
{
    height: fit-content;
    width: max-content;
}

.left
{
    position:absolute;
    top: 35%;
    right: 100%;
}

.right
{
    position:absolute;
    top: 35%;
    left: 100%;
}

.up-growleft
{
    position:absolute;
    bottom: 100%;
    right: 1em;
}

.up-growright
{
    position:absolute;
    bottom: 100%;
    left: 1em;
}

.down-growleft
{
    position:absolute;
    top: 100%;
    right: 1em;
}

.down-growright
{
    position:absolute;
    top: 100%;
    left: 1em;
}
</style>