import config from "config";
import { authHeader, handleResponse } from "../_helpers";


function getDesignations() {debugger;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(`${config.apiUrl}/productdesignation`, requestOptions).then(
    handleResponse
  );
}

function addProductDesignation(productDesignationDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productDesignationDto ? productDesignationDto : {} )
  };

  return fetch(`${config.apiUrl}/productdesignation/create`, requestOptions).then(
    handleResponse
  );
}

function deleteProductDesignation(productDesignationDto) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(productDesignationDto ? productDesignationDto : {} )
  };

  return fetch(`${config.apiUrl}/productdesignation/delete`, requestOptions).then(
    handleResponse
  );
}

export const productDesignationService = {
    getDesignations,
    addProductDesignation,
    deleteProductDesignation
};
