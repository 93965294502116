export function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("user"));
  //const params = [{'Authorization': 'Bearer ' + user.token}, {'twoAuthToken': user.twoAuthToken}]
  if (user && user.token) {
    //return {params};
    return {
      Authorization: "Bearer " + user.token,
      twoAuthToken: user.twoAuthToken
    };
  } else {
    return {};
  }
}

export function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

export function handleResponse(
  response,
  download = false,
  hasListCount = false
) {
  return response.text().then(text => {
    const data = !download ? text && JSON.parse(text) : text;
    if (!response.ok) {
      if (response.status === 401 ||
        response.status === 403 ||
        response.status === 417) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("user");
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (hasListCount) {
      const listCount = Number.parseInt(response.headers.get("row-count"));
      return { data: data, listCount: listCount };
    } else {
      return data;
    }
  });
}

export function downloadFile(data, filename) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], { type: "text/csv" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

export function getUserId() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return user.id;
  } else {
    return {};
  }
}
