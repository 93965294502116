import { userService } from "../_services";
import router from "../router";

const user = JSON.parse(localStorage.getItem("user"));
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

const actions = {
  login({ dispatch, commit }, { email, password }) {
    commit("loginRequest", { email });

    userService.login(email, password).then(
      user => {
        commit("loginSuccess", user);
        dispatch("twoFaReq");
        router.push("/twofa");
      },
      error => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  resetPassword({ dispatch, commit }, key) {
    commit("resetPasswordRequest");

    userService.resetPassword(key).then(
      user => {
        commit("resetPasswordSuccess", user);
      },
      error => {
        commit("resetPasswordFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  submitPassword({ dispatch, commit }, { key, password }) {
    commit("submitPasswordRequest");

    userService.submitPassword(key, password).then(
      status => {
        commit("submitPasswordSuccess");
      },
      error => {
        commit("submitPasswordFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  twoFaReq({ dispatch, commit }) {
    commit("twoFaReqRequest");

    userService.twoFaRequest().then(
      status => {
        commit("twoFaReqSuccess", status);
      },
      error => {
        commit("twoFaReqFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  twoFaSubmit({ dispatch, commit }, code) {
    commit("twoFaSubmitRequest");

    userService.twoFaSubmit(code).then(
      user => {
        commit("twoFaSubmitSuccess", user);
        router.push("/report");
      },
      error => {
        commit("twoFaSubmitFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  logout({ commit }) {
    userService.logout();
    commit("logout");
  },
  register({ dispatch, commit }, user) {
    commit("registerRequest", user);

    userService.register(user).then(
      user => {
        commit("registerSuccess", user);
        router.push("/login");
        setTimeout(() => {
          // display success message after route change completes
          dispatch("alert/success", "Registration successful", { root: true });
        });
      },
      error => {
        commit("registerFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  }
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state, error) {
    state.status = { loginFailed: true };
    state.message = error;
    state.user = null;
  },
  resetPasswordRequest(state) {
    state.status = { requestingUser: true };
  },
  resetPasswordSuccess(state, user) {
    state.status = { userReceived: true };
    state.user = user;
  },
  resetPasswordFailure(state, error) {
    state.status = { keyInvalid: true };
    state.user = null;
  },
  submitPasswordRequest(state) {
    state.status = { changingPassword: true };
  },
  submitPasswordSuccess(state) {
    state.status = { passwordChange: true };
  },
  submitPasswordFailure(state, error) {
    state.status = { passwordChangeFail: true, error: error };
  },
  twoFaReqRequest(state) {
    state.status = { twoFaRequesting: true };
  },
  twoFaReqSuccess(state) {
    state.status = { twoFaRequested: true };
  },
  twoFaReqFailure(state, error) {
    state.status = { twoFaRequestFailure: true, error: error };
  },
  twoFaSubmitRequest(state) {
    state.status = { twoFaSubmitting: true };
  },
  twoFaSubmitSuccess(state, user) {
    state.user = user;
    state.status = { twoFaSubmitted: true };
  },
  twoFaSubmitFailure(state, error) {
    state.status = { twoFaSubmitFailure: true, error: error };
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state, user) {
    state.status = { registering: true };
  },
  registerSuccess(state, user) {
    state.status = {};
  },
  registerFailure(state, error) {
    state.status = {};
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
