/* eslint-disable */
const rowDetailsData = [
    {
        date: '03/01/2021',
        compliance_check_type: 'Catalog Integrity Check',
        status: 'Pass',
        detail: 'Detail on the status can be provided here for clarity and audititng purposes.'
    },
    {
        date: '03/01/2021',
        compliance_check_type: 'U.S. Address Validation',
        status: 'Pass',
        detail: 'Shipping Address was verified and corrected.'
    },
    {
        date: '03/01/2021',
        compliance_check_type: 'U.S. Denied Party Screening',
        status: 'Pass',
        detail: 'Detail on the status can be provided here for clarity and audititng purposes.'
    },
    {
        date: '03/01/2021',
        compliance_check_type: 'Restricted Region Screening',
        status: 'Pass',
        detail: 'Detail on the status can be provided here for clarity and audititng purposes.'
    },
    {
        date: '03/01/2021',
        compliance_check_type: 'Restricted Region Screening',
        status: 'Fail',
        detail: 'Detail on the status can be provided here for clarity and audititng purposes.'
    },
]

export { rowDetailsData };
