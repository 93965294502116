<template>
    <div id="idmeMain">
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data() {
    return {
        
    };
  },
  created() {
    this.Redirect();
  },
  computed:{
    ...mapState("idme", ["result"]),
  },
  methods: {
    ...mapActions("idme", ["sendAuthCode"]),
    Redirect()
    {
        this.sendAuthCode({Code: this.$route.query.code + "", ID: this.$route.query.state + ""});
    }
  },
}
</script>

<style scoped>
#idmeMain
{
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
}
</style>