<template>
  <Layout style="height: 95vh;">
    <PageHeader title="Credential Review" />
    <!-- Content container for Credential Review Page -->
    <div class="content">
      <!--Container div to hold credential review content-->
      <div class="wrapperDiv">
        <div class="left-side">
          <div class="card max-size screenHeight" id="reportCard" >
            <div style="width:fit-content;">
              <button class="ibtn btn btn-light" id="openFilter"  @click="openfilter = !openfilter"><i class="mdi mdi-filter"></i></button>
              <button @click="ChangePage(1)" class="ibtn btn btn-light" title="First Page" :disabled="pageNumber <= 1 "><i class="mdi mdi-chevron-double-left font-18"></i></button>
              <button @click="ChangePage(pageNumber - 1)" class="ibtn btn btn-light" title="Previous Page" :disabled="pageNumber <= 1 "><i class="mdi mdi-chevron-left font-18"></i></button>
              <span id="pageSpan">Page {{pageCount !== 0 ? pageNumber : 0 }} of {{ pageCount }}</span>
              <button  @click="ChangePage(pageNumber + 1)" class="ibtn btn btn-light" title="Next Page" :disabled="pageNumber >= pageCount "><i class="mdi mdi-chevron-right font-18"></i></button>
              <button @click="ChangePage(pageCount)" class="ibtn btn btn-light" title="Last Page" :disabled="pageNumber >= pageCount "><i class="mdi mdi-chevron-double-right font-18"></i></button>
              <div id="summaryFilter" v-show="openfilter" >
                <Label for="startDate" name="startDate" class="label">Start Date:</Label>
                <input id="startDate" name="startDate" v-model="tempFilter.After" type="date">

                <Label for="endDate" name="endDate" class="label" id="endDateLabel">End Date:</Label>
                <input id="endDate" name="endDate" v-model="tempFilter.Before" type="date">

                <br/>
                <label for="Name" class="label">Name:</label>
                <input type="text" id="Name" v-model="tempFilter.Name">

                <label for="OrdNums" class="label" id="orderLabel">Order(s):</label>
                <input type="text" id="OrdNums" v-model="tempFilter.OrderNums">

                <div id="statusDiv">
                  <label for="status" class="label" id="statusLabel">Status:</label>
                  <input type="checkbox" id="ConditionalFail" value="Conditional Fail" v-model="tempFilter.checked" name="status">
                  <label for="ConditionalFail" class="miniLabel label">Cdn'l Fail</label>
                  <input type="checkbox" id="Fail" value="Fail" v-model="tempFilter.checked" name="status">
                  <label for="Fail" class="miniLabel label">Fail</label>
                  <input type="checkbox" id="Pass" value="Pass" v-model="tempFilter.checked" name="status">
                  <label for="Pass" class="miniLabel label">Pass</label>
                </div>

                <button @click="SubmitFilter()" id="filterBtn" class="btn btn-light btn-sm">Filter</button>
                <button @click="ClearFilter()" id="clearBtn" class="btn btn-light btn-sm">Clear</button>
              </div>
            </div>
            <div class="TableDiv">
              <Table class="table table-centered mb-1 statusTable">
                <thead>
                  <Tr class="TableHead">
                    <th style="width: 100px;">Order #</th>
                    <th style="width: 108px;">Date</th>
                    <th >Customer Name</th>
                    <th style="width: 75px;">Status</th>
                  </Tr>
                </thead>
                <tbody>
                  <tr v-if="pageCount === 0">
                    <td colspan="4">No Results</td>
                  </tr>
                  <template v-for="order of sortedOrders">
                    <tr :key="order.orderID" @click="Select(order.id, order)" class="clickable">
                      <td>{{ order.systemDisplayOrderID }}</td>
                      <td class="dateCol">{{ formatFriendlyDateLocal(order.dateUTC) }}</td>
                      <td>{{ order.name }}</td>
                      <td style="text-align: center;">
                        <i v-if="order.outcomeType === 'Pass'" title="Pass" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                        <i v-if="order.outcomeType === 'Fail'" title="Fail" class="mdi mdi-close-circle text-danger font-18"></i>
                        <i v-if="order.outcomeType === 'Conditional Fail'" title="Conditional Fail" class="mdi mdi-clock text-warning font-18"></i>
                      </td>
                    </tr>
                    <tr :key="order.orderID + 'Details'" v-if="order.showDetails === true">
                      <!--Requirements Table-->
                      <td colspan="4">
                        <i v-if="reviewOrders.getOrderDetailsLoading !== false" class="mdi mdi-loading mdi-spin"></i>
                        <span v-if="reviewOrders.getOrderDetailsLoading === false && (orderDetails[order.orderID] === undefined  || orderDetails[order.orderID] === {})">This order has no requirements.</span>
                        <table class="Subtable" v-if="orderDetails[order.orderID] !== undefined && reviewOrders.getOrderDetailsLoading === false">
                          <!--Thead>
                            <th colspan="3">Requirements</th>
                          </Thead-->
                          <tbody>
                            <template v-for="trade in Trades"><!--Trade Type-->
                              <tr :key="trade.ID" @click="Show(order.orderID, trade.ID)" class="clickable" v-if="TradeIsNotEmpty(orderDetails[order.orderID], trade.ID)">
                                <td ><strong>{{ trade.Name }}</strong></td>
                                <td class="actionCol"></td>
                                <td class="statusCol"></td>
                              </tr>
                              <template v-if="orderDetails[order.orderID].OpenTrade == trade.ID" >
                                <tr v-for="exem in orderDetails[order.orderID].BasicAndList[trade.ID]" :key="exem.id"><!--All Rows-->
                                <td>{{ exem.name }}</td>
                                <td class="btnCell"><button class="btn btn-light btn-sm" @click="View(exem, order)">view</button></td>
                                <td class="statusCol">
                                  <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                                  <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-18"></i>
                                  <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-18"></i>
                                  <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-18"></i>
                                </td>
                                </tr>
                                <template v-for="anyRow in orderDetails[order.orderID].BasicOrList[trade.ID]"><!--Any Rows-->
                                  <tr :key="anyRow.id" v-if="anyRow.chosen === ''">
                                    <td>Choose One</td>
                                    <td colspan="2" class="btnCell">
                                      <div style="width: fit-content;" @mouseenter.once="FixBorkedDropDown($event)">
                                        <b-dropdown no-caret size="sm" right text="Choose" variant="light">
                                          <b-dropdown-item @click="ChooseAny(anyRow, exem.id, $event)" v-for="exem in anyRow.exemptions" :key="exem.id">{{ exem.name}}</b-dropdown-item>
                                        </b-dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                  <template v-for="exem in anyRow.exemptions">
                                    <tr :key="exem.id" v-if="anyRow.chosen === exem.id">
                                      <td>{{exem.name}}</td>
                                      <td class="btnCell">
                                        <button class="btn btn-light btn-sm" @click="View(exem, order)">view</button>
                                        <div style="width: fit-content;" @mouseenter.once="FixBorkedDropDown($event)">
                                          <b-dropdown no-caret size="sm" right text="Choose" variant="light">
                                            <b-dropdown-item @click="ChooseAny(anyRow, exem.id, $event)" v-for="exem in anyRow.exemptions" :key="exem.id">{{ exem.name}}</b-dropdown-item>
                                          </b-dropdown>
                                        </div>
                                      </td>
                                      <td class="statusCol">
                                        <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                                        <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-18"></i>
                                        <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-18"></i>
                                        <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-18"></i>
                                      </td>
                                    </tr>
                                  </template>
                                  <template v-for="exem in anyRow.exemptions">
                                    <tr v-if="anyRow.chosen === exem.id" :key="'e' + exem.id"></tr><!--Any Options-->
                                  </template>
                                </template>
                                <template v-for="orRow in orderDetails[order.orderID].ComplexOrList[trade.ID]"><!--Or Rows-->
                                  <tr :key="orRow.id">
                                    <td>Choose one </td>
                                    <td colspan="2" class="btnCell">
                                      <div style="width: fit-content;" @mouseenter.once="FixBorkedDropDown($event)">
                                        <b-dropdown no-caret size="sm" right text="Choose" variant="light" class="btn-sm">
                                          <b-dropdown-item @click="ChooseOr(orRow, exem.id, $event)" v-for="exem in orRow.exemptionSets" :key="exem.id">{{ exem.Name}}</b-dropdown-item>
                                        </b-dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                  <template v-for="set in orRow.exemptionSets">
                                    <template v-for="exem in set.andExemptions"><!--inner all-->
                                      <tr v-if="orRow.orChosen === set.id" :key="'e' + exem.id">
                                        <td>{{ exem.name }}</td>
                                        <td class="btnCell"><button class="btn btn-light btn-sm" @click="View(exem, order)">view</button></td>
                                        <td class="statusCol">
                                          <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                                          <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-18"></i>
                                          <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-18"></i>
                                          <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-18"></i>
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="orRow.orChosen === set.id && Object.keys(set.orExemptions).length !== 0"><!--inner any-->
                                      <tr :key="'set' + set.id" v-if="set.chosen === ''">
                                        <td>Choose one</td>
                                        <td colspan="2" class="btnCell">
                                          <div style="width: fit-content;" @mouseenter.once="FixBorkedDropDown($event)">
                                            <b-dropdown no-caret size="sm" right text="Choose" variant="light">
                                              <b-dropdown-item  @click="ChooseAny(set, exem.id, $event)" v-for="exem in set.orExemptions" :key="exem.id">{{ exem.name}}</b-dropdown-item>
                                            </b-dropdown>
                                          </div>
                                        </td>
                                      </tr>
                                      <template v-for="exem in set.orExemptions">
                                        <tr :key="exem.id" v-if="set.chosen === exem.id">
                                          <td>{{ exem.name }}</td>
                                          <td class="btnCell">
                                            <button class="btn btn-light btn-sm" @click="View(exem, order)">view</button>
                                            <div style="width: fit-content;" @mouseenter.once="FixBorkedDropDown($event)">
                                              <b-dropdown no-caret size="sm" right text="Choose" variant="light" style="overflow-x: visible;">
                                                <b-dropdown-item  @click="ChooseAny(set, exem.id, $event)" v-for="exem in set.orExemptions" :key="exem.id">{{ exem.name}}</b-dropdown-item>
                                              </b-dropdown>
                                            </div>
                                          </td>
                                          <td class="statusCol">
                                            <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                                            <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-18"></i>
                                            <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-18"></i>
                                            <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-18"></i>
                                          </td>
                                        </tr>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <!--Container to hold right side of content -->
        <div id="right-side">
          <!-- Container to hold header content -->
          <div class="card max-size" id="reportCard">
            <div class="card-body" id="innerRight">
              <div id="headers">
                <!-- <h4>DOCUMENT REQUIRING VERIFICATION</h4> -->
                <h5>ITEM REQUIRING VERIFICATION</h5>
                <h5 v-if="Object.keys(selectedExem).length > 0">ITEM STATUS:
                  <i v-if="selectedExem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                  <i v-if="selectedExem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-18"></i>
                  <i v-if="selectedExem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-18"></i>
                  <i v-if="selectedExem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-18"></i>
                  {{selectedExem.status}}
                </h5>
                <div class="orderButton" id="sendLink">
                  <button class="button" type="button"  @click="ActivateButton($event); RenewLink()" :disabled="!((Pass.using === false && FocusedOrder > 0) || (Pass.using === true && Object.keys(FastPassOrder).length > 0 && FastPassOrder.id === selectedExem.orderID))">Send Link</button>
                  <!--<div id="feedback">
                    <span id="SendLinkConfirm" class="buttonFeedbackHide">Link Sent</span>
                  </div>-->
                </div>
                <div class="orderButton">
                    <button class="button" type="button" style="width: 150px;" @click="ActivateButton($event); ExpireLink($event)" :disabled="!((Pass.using === false && FocusedOrder > 0) || (Pass.using === true && Object.keys(FastPassOrder).length > 0 && FastPassOrder.id === selectedExem.orderID))">Stop Reminders</button>
                    <!--<div class="feedback" style="left: 5%">
                        <span class="buttonFeedbackHide">Reminders Stopped</span>
                    </div>-->
                </div>
              </div>
              <!-- information on requirement-->
              <div id="reqInfo">
                <h5>Requirement: {{selectedExem.name}}<span v-if="selectedExem.description">{{ selectedExem.description }}</span></h5>
              </div>
              <div id="ColumnLeft">
                <div id="form-frame" v-if="manual">
                  <div id="ManualForm" v-if="selectedExem.exemptionDataType === 'Form'" style="margin-top: 1em;">
                  <form v-html="formHTML" @submit="AdminFormSubmit($event)"></form>
                  <span class="status" v-if="submitStatus !== 'None'">
                    <i v-if="submitStatus === 'Loading'" class="mdi mdi-spin mdi-loading font-16">Uploading</i>
                    <i v-if="submitStatus === 'Success'" class="mdi mdi-check font-16 valid-feedback" style="display: inline;">Success</i>
                    <i v-if="submitStatus !== 'Loading' && submitStatus !== 'Success' && submitStatus !== 'None' " class="mdi mdi-close font-16 invalid-feedback" style="display: inline;">{{ submitStatus }}</i>
                  </span>
                  
                  </div>
                  <div id="ManualFile" v-if="selectedExem.exemptionDataType === 'File'">
                    <form @submit="AdminFileSubmit($event)">
                      <input type="file" style="border: none; margin: 1em;" required><br>
                      <input type="submit" style="margin-left: 1em;">
                    </form>
                    <span class="status" v-if="submitStatus !== 'None'">
                      <i v-if="submitStatus === 'Loading'" class="mdi mdi-spin mdi-loading font-16">Uploading</i>
                      <i v-if="submitStatus === 'Success'" class="mdi mdi-check font-16 valid-feedback" style="display: inline;">Success</i>
                      <i v-if="submitStatus !== 'Loading' && submitStatus !== 'Success' && submitStatus !== 'None' " class="mdi mdi-close font-16 invalid-feedback" style="display: inline;">{{ submitStatus }}</i>
                    </span>
                  </div>
                </div>
                <div id="id-frame" v-if="!manual">
                  <div v-if="Object.keys(selectedExem).length < 1">
                    Please select an exemption.
                  </div>
                  <!-- <div v-if="retrievalError !== ''">
                    {{retrievalError}}
                  </div> -->
                  <!-- form/file info -->
                  <div v-if="OrderFulfillmentIntegrations[selectedExem.id] !== undefined && selectedExem.status === 'Accepted'"><!--integration logic-->
                    Fulfilled through {{OrderFulfillmentIntegrations[selectedExem.id]}}
                  </div>
                  <div id="FormFrame" v-if="selectedExem.exemptionDataType === 'Form' && (OrderFulfillmentIntegrations[selectedExem.id] === undefined || selectedExem.status !== 'Accepted')">
                    <template v-if="selectedExem.status !== 'Unsubmitted' && Object.keys(form).length > 0">
                      <!-- <div v-for="(formRow) in form" :key="formRow[0]"><span class="formRowLabel">{{ formRow[0] + ": "}}</span> {{formRow[1]}}</div> -->
                        <template v-for="row in adminFormTemplate.fields">
                            <div :key="row.ElementType + row.Label" v-if="row.Label !== undefined">
                                <span class="formRowLabel">{{ row.Label + ": "}}</span>
                                {{ form.find((e)=>{return e[0] === row.Label})[1] }}
                            </div>
                            <div :key="row.ElementType + row.Message" v-if="row.ElementType === 'Message'">
                                {{ row.Message }}
                            </div>
                        </template>
                    </template>
                    <template v-if="selectedExem.status !== 'Unsubmitted' && Object.keys(form).length == 0">
                      <!-- Overriden-->
                      <div class="overriden">
                        <p>Requirement has been fulfilled outside of eCheckpoint (override).</p>
                      </div>
                    </template>
                    <template v-if="selectedExem.status === 'Unsubmitted'">
                      <template v-for="row in adminFormTemplate.fields">
                        <div :key="row.ElementType + row.Label" v-if="row.Label !== undefined">
                          <span class="formRowLabel">{{ row.Label + ": "}}</span>
                        </div>
                        <div :key="row.ElementType + row.Message" v-if="row.ElementType === 'Message'">
                            {{ row.Message }}
                        </div>
                      </template>
                    </template>
                  </div>
                  <div id="FileFrame" v-if="selectedExem.exemptionDataType === 'File' && (OrderFulfillmentIntegrations[selectedExem.id] === undefined || selectedExem.status !== 'Accepted')">
                      <div id="noFile" v-if="selectedExem.status === 'Unsubmitted'">No File Submitted</div>
                      <template v-if="selectedExem.status !== 'Unsubmitted'">
                        <img v-if="imgFileID != 0 && imgString.startsWith('data:image')" @click="Enlarge()"  :src="imgString" id="fileViewer" alt="Credential Image"/><!--@mouseenter="ZoomStart()" @mouseleave="ZoomEnd()"-->
                        <div @click="Enlarge()" @keyup.esc="Enlarge()" tabindex="0" v-if="enlarged" id="enlargedImg">
                          <img :src="imgString" alt="Enlarged Document">
                        </div>
                        <embed  v-if="imgFileID != 0 && imgString.startsWith('data:application/pdf')" :src="imgString" id="pdfViewer" >
                        <!-- Overriden-->
                        <div class="overriden" v-if="imgFileID == 0">
                          <p>Requirement has been fulfilled outside of eCheckpoint (override).</p>
                        </div>
                        <!--FFL Scope-->
                        <div id="FFLScope" v-if="imgFileID == 4" @click="GoToLink()">
                          <p>File provided by FFLs.com</p>
                          <button class="button">View</button>
                        </div>
                      </template>
                  </div>
                </div>
                <div id="watermark">
                  <div v-if="selectedExem.status === 'Denied' && !manual">DENIED</div>
                  <div v-if="selectedExem.status === 'Accepted' && !manual" style="color: #0acf97; border-color: #0acf97;">ACCEPTED</div>
                </div>
                <button :title="manual ? 'Finish' : 'Edit'" class="button" type="button" id="ManualOverride" @click="manual = !manual; $store.commit('credentialReview/ResetSubmitStatus'); if(selectedExem.exemptionDataType === 'Form' && selectedExem.status !== 'Unsubmitted'){GenerateForm()}else if(selectedExem.exemptionDataType === 'Form'){$store.commit('credentialReview/ResetFormData');GenerateForm()}" :disabled="Object.keys(selectedExem).length === 0">
                  <i v-if="!manual" class="mdi mdi-pencil font-16"></i>
                  <i v-if="manual" class="mdi mdi-close font-16"></i>
                </button>
              </div>
              <div id="addresses-and-products-ordered">
                <div v-if="!Pass.using">
                  <h5>ORDER DETAILS</h5>
                  <p>Date:<span v-if="FocusedOrder && orders[FocusedOrder]"> {{ formatFriendlyDateLocal(orders[FocusedOrder].dateUTC) }}</span></p>
                  <p>Order #:<span v-if="FocusedOrder && orders[FocusedOrder]"> {{ orders[FocusedOrder].systemDisplayOrderID }} </span></p>
                  <p>Name:<span v-if="FocusedOrder && orders[FocusedOrder]"> {{ orders[FocusedOrder].name }}</span></p>
                </div>
                <div v-if="Pass.using">
                  <h5>ORDER DETAILS</h5>
                  <p>Date:<span v-if="Object.keys(FastPassOrder).length > 0"> {{ formatFriendlyDateLocal(FastPassOrder.dateUTC) }}</span></p>
                  <p>Order #:<span v-if="Object.keys(FastPassOrder).length > 0"> {{ FastPassOrder.systemDisplayOrderID }} </span></p>
                  <p>Name:<span v-if="Object.keys(FastPassOrder).length > 0"> {{ FastPassOrder.name }}</span></p>
                </div>
                <div id="addresses">
                  <H5>ADDRESSES:</H5>
                  <p>Bill To: {{ credentialReview.orderAddress.BillingAddress }}</p>
                  <p>Ship To: {{ credentialReview.orderAddress.ShippingAddress }}</p>
                </div>
                <div id="products">
                  <h5>PRODUCTS:</h5>
                  <span v-for="prd in orderProducts" :key="prd.id">• {{ prd.name}} <br/></span>
                  <!--ul id="prdList">
                    <li v-for="prd in orderProducts" :key="prd.id">{{ prd.name}}</li>
                  </ul-->
                </div>
              </div>

              <!-- Container for form which grants documents permissions -->
              <form>
                <div id="order-info-header-to-calendar">
                  <div id="calendar-div"><span>Accept for future orders until:</span><input type="date" id="date"></div>
                </div>
                <div id="Notes">
                  <div>
                    <div>
                      <h5>Note to Customer</h5>
                      <div class="box">
                        <textarea class="noteArea" id="ExternalComment"></textarea>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h5 style="display: inline-block">INTERNAL Note</h5>
                      <button class="button" type="button" id="addInternalBtn" @click="ActivateButton($event); MakeDecision(false, 'Comment')" :disabled="Object.keys(selectedExem).length === 0">Add internal only</button>
                      <div class="box">
                        <textarea class="noteArea" id="InternalComment"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Container for buttons -->
                <div id="button-container">
                  <button type="button" @click="PassMove(-1)" class="FPBtn btn btn-light" title="First Page" :disabled="FastPass.length === 0 || (Pass.using && (reviewOrders.getCredentialReviewOrderFastPassLoading || Pass.index <= 0))"><i class="mdi mdi-chevron-left font-18"></i>Back</button>
                  <!-- <button class="button" type="button" id="sendLink" @click="ActivateButton($event); RenewLink()" :disabled="!((Pass.using === false && FocusedOrder > 0) || (Pass.using === true && Object.keys(FastPassOrder).length > 0 && FastPassOrder.id === selectedExem.orderID))">Send Link</button> -->
                  <button class="button " type="button" id="deny" @click="ActivateButton($event); MakeDecision(false, 'Deny'); selectedExem.status = 'Denied'" :disabled="selectedExem.status === 'Unsubmitted'">DENY</button>
                  <button class="button" type="button" id="accept" @click="ActivateButton($event); MakeDecision(true, 'Accept'); selectedExem.status = 'Accepted'" :disabled="selectedExem.status === 'Unsubmitted'">ACCEPT</button>
                  <button class="button" type="button" id="override" @click="ActivateButton($event); MakeDecision(true, 'Override'); selectedExem.status = 'Accepted'" :disabled="selectedExem.status !== 'Unsubmitted'">OVERRIDE</button>
                  <button type="button" @click="PassMove(1)" class="FPBtn btn btn-light" title="First Page" :disabled="FastPass.length === 0 || (Pass.using && (reviewOrders.getCredentialReviewOrderFastPassLoading || Pass.index >= FastPass.length - 1))" :style="(selectedExem.status === 'Denied' || selectedExem.status === 'Accepted') && Pass.index < FastPass.length-1 ? 'border: 2px solid #333; color: black; font-weight:bold' : ''">Next<i class="mdi mdi-chevron-right font-18"></i></button>
                </div>
              </form>
            </div>
          </div>
          <div class="card" id="reportCard"  v-if="orderDetailComments.length > 0">
            <div class="card-body">
              <!-- Container for history table -->
              <div>
                <h4>HISTORY</h4>
                <table id="CommentTable" class="table table-bordered  mb-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>User</th>
                      <th>Action</th>
                      <th>Note Type</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="comment in orderDetailComments" :key="comment.id">
                      <td>{{formatFriendlyDateLocal(comment.date)}}</td>
                      <td>{{comment.user}}</td>
                      <td>{{comment.action}}</td>
                      <td>{{comment.type}}</td>
                      <td>{{comment.commentText}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>




<script>
import appConfig from "@/app.config";
import Layout from "@/components/layout/main";
import PageHeader from "@/components/layout/page-header";
import { mapState, mapActions } from "vuex";
import { formatFriendlyDate, formatFriendlyDateLocal } from "../../_helpers";
import { nextTick } from 'vue';

export default {
  name: "CredentialReview",
  data: function () {
    return {
      Trades: [
          {ID: "1", Type: "Consumer", Name: "Consumer Requirements"},
          {ID: "2", Type: "Business", Name: "Business  Requirements"},
          {ID: "3", Type: "Government", Name: "Government  Requirements"},
      ],
      Pass:
      {
        using: false,
        index: 0
      },
      pageNumber: 1,
      tempFilter: {
        Page: 1,
        Name: null,
        Statuses: null,
        Before: null,
        After: null,
        OrderNums: null,
        checked: ["Conditional Fail"]
      },
      openfilter: false,
      selectedExem: {},
      manual: false,
      FocusedOrder: null,
      Zooming: false,
      enlarged: false,
      formHTML: "",
    }
  },
  page: {
    title: "Credential Review",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted: function () {
    this.filter.Statuses = "Conditional Fail"
    this.getCredentialReviewOrders(this.filter);
    this.getCredentialReviewOrderPageCount(this.filter);
    this.getCredentialReviewOrderFastPass(this.filter);
    this.AwaitPass(0);
  },
  computed: {
    ...mapState({
      credentialReview: state => state.credentialReview
    }),
    ...mapState("credentialReview", ["orders", "orderDetails", "reviewOrders", "pageCount", "filter", 
                "orderProducts", "imgString", "form", "retrievalError", "orderDetailComments", "FastPass",
                "FastPassOrder", "adminFormTemplate", "adminSubmitStatus", "submitStatus", "OrderFulfillmentIntegrations", "imgFileID"]),
    sortedOrders: function() {
        const temp = []
        for(const order in this.orders)
        {
            temp.push(this.orders[order])
        }
        return temp.slice().reverse()
    }
  },
  methods: {
    ...mapActions("credentialReview", ["getCredentialReviewOrders", "getCredentialReviewOrderDetails", "getCredentialReviewOrderAddress",
                  "getCredentialReviewOrderProducts", "getCredentialReviewOrderPageCount", "getCredentialReviewExemFile",
                  "getCredentialReviewExemForm", "Decide", "getComments", "getCredentialReviewOrderFastPass",
                  "getCredentialReviewOrderFastPassOrder", "getAdminFormTemplate", "submitAdminForm", "uploadAdminData", "updateLinkExpiration",
                  "expireLinkExpiration", "getOrderFulfillmentIntegrations", "finalizeSubmissionNoEmail"]),
    formatFriendlyDate: formatFriendlyDate,
    formatFriendlyDateLocal: formatFriendlyDateLocal,
    handleProductExpand(row) {
      this.getCredentialReviewOrderDetails({ OrderID: row.item.orderID, ProductID: row.item.id });
      //document.getElementById('' + row.item.orderID).class = row.item.showDetails === true ? 'uil-angle-down' : 'uil-angle-right';
    },
    rowProductItems(row) {
      debugger;
      //this.getCredentialReviewOrderAddress({ OrderID: row.item.id });
      const rowDetails = this.credentialReview.orderProducts[row.item.id]
      return rowDetails;
    },
    rowDetailItems(row) {
      debugger;
      //this.getCredentialReviewOrderAddress({ OrderID: row.item.id });
      let rowDetails = this.credentialReview.orderDetails[row.item.orderID + "_" + row.item.id]
      if(rowDetails == null)
      {
        rowDetails = [{
          businessTradeTypeID: 0,
          exemption: 'There Are No Requirements For This Product',
          name: 'Approved'
        }]
      }
      return rowDetails;
    },
    //THIS IS WHAT I ADDED
    FilterToggle(event)
    {
      debugger;
      const filterDiv = event.target.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling;
      filterDiv.style.display = filterDiv.style.display === 'none' ? '' : 'none';
    },
    PassMove(change)
    {
      this.manual = false;
      let needOrder = false;
      if(this.Pass.using)
      {
        this.Pass.index += change;
        if(this.FastPass[this.Pass.index] !== undefined)
        {   
          if(this.FastPass[this.Pass.index].orderID !== this.FastPass[this.Pass.index - change].orderID)
          {
            needOrder = true;
          } 
        }
        else
        {
          this.Pass.index -= change;
        }
      }
      else
      {
        this.Pass.using = true;
        this.manual = false;
        needOrder = true;
      }

      if(needOrder)
      {
        //get addresses
        this.getCredentialReviewOrderAddress(this.FastPass[this.Pass.index].orderID);
        //get products
        this.getCredentialReviewOrderProducts(this.FastPass[this.Pass.index].orderID);

        this.getCredentialReviewOrderFastPassOrder(this.FastPass[this.Pass.index].orderID);
        this.FastPassOrderIn(0);
        
      }
      else
      {
        this.Retrieve(this.FastPass[this.Pass.index].exemptionDataType, this.FastPassOrder.customerID, this.FastPass[this.Pass.index].id, this.FastPassOrder.id );
      }

      this.selectedExem = this.FastPass[this.Pass.index];
      
    },
    FastPassOrderIn(i)
    {
      if(this.FastPassOrder.id !== this.FastPass[this.Pass.index].orderID && i < 100)
        setTimeout(() => {this.FastPassOrderIn(++i)}, 250);
      else
        this.Retrieve(this.FastPass[this.Pass.index].exemptionDataType, this.FastPassOrder.customerID, this.FastPass[this.Pass.index].id, this.FastPassOrder.id )
    },
    Select(i, order)
    {
      this.orders[i].showDetails = !this.orders[i].showDetails;
      this.se
      this.$forceUpdate();
      //console.log(this.orders[i].showDetails);
      if (this.orders[i].showDetails) {
        this.getCredentialReviewOrderDetails(this.orders[i].id);
        this.getOrderFulfillmentIntegrations(this.orders[i].id);
        this.FocusOrder(order.orderID)
        this.Pass.using = false;
        this.manual = false;
        //this.getCredentialReviewOrderDetails({ OrderID: row.item.id });
      }
      //document.getElementById('' + row.item.orderID).class = row.item.showDetails === true ? 'uil-angle-down' : 'uil-angle-right';
    },
    Show(ordID, i)
    {
      debugger;
      if(this.orderDetails[ordID].OpenTrade === i)
      {
        this.orderDetails[ordID].OpenTrade = "0";
      }
      else
        this.orderDetails[ordID].OpenTrade = i;
      this.$forceUpdate();
      //event.target.parentElement.nextElementSibling.classList.toggle("HideContents");
      //event.target.parentElement.nextElementSibling.style.display = event.target.parentElement.nextElementSibling.style.display === 'none' ? "" : 'none';
    },
    ChangePage(newPage)
    {
      if(!this.Pass.using)
        this.$store.commit('credentialReview/ResetOrderInfo');

      if(newPage < 1)
        newPage = 1;
      if(newPage > this.pageCount)
      newPage = this.pageCount;

      this.filter.Page = newPage;
      this.getCredentialReviewOrders(this.filter).then(this.pageNumber = newPage)
    },
    ToggleOptions(event)
    {
      event.target.nextElementSibling.firstElementChild.classList.toggle("show");
    },
    ToggleDrop(event)
    {
      debugger;
      const styles = event.target.nextElementSibling;
      styles.classList.toggle('show');
      //styles.style.display = styles.style.display === "none" ? "" : "none";
      //event.target.nextElementSibling.style.display = event.target.nextElementSibling.style.display === "none" ? "" : "none";
    },
    ChooseOr(list, id, event)
    {
      list.orChosen = id;
      this.$forceUpdate();
      event.target.parentElement.classList.remove("show");
    },
    ChooseAny(list, id, event)
    {
      list.chosen = id;
      this.$forceUpdate();
      event.target.parentElement.classList.remove("show");
    },
    FixBorkedDropDown(event)
    {

      const toFix = event.target.firstElementChild.firstElementChild.nextElementSibling;
      if(toFix.firstElementChild !== null)
      {
        let listElem = toFix.firstElementChild;
        while(listElem !== null)
        {
          listElem.firstElementChild.style.width = "100%";
          listElem.firstElementChild.style.maxWidth = "400px";
          listElem.firstElementChild.style.minWidth = "400px";
          listElem.firstElementChild.style.whiteSpace = "unset";
          listElem.firstElementChild.style.overflowWrap = "break-word";

          listElem = listElem.nextElementSibling;
        }
      }
      
    },
    SubmitFilter()
    {

      this.filter.Page = 1;
      this.filter.OrderNums = this.tempFilter.OrderNums === "" || this.tempFilter.OrderNums === null ? null : this.tempFilter.OrderNums.trim();
      this.filter.Name = this.tempFilter.Name === "" || this.tempFilter.Name === null ? null : this.tempFilter.Name.trim().replaceAll(", ", ",");
      this.filter.Statuses = this.tempFilter.checked === null || this.tempFilter.checked.length === 0 ? null : this.tempFilter.checked.join(',');
      this.filter.Before = this.tempFilter.Before === "" ? null : this.tempFilter.Before;
      this.filter.After = this.tempFilter.After === "" ? null : this.tempFilter.After;

      this.pageNumber = 1;
      this.Pass.index = 0;
      this.Pass.using = false;
      this.manual = false;
      this.getCredentialReviewOrders(this.filter);
      this.getCredentialReviewOrderPageCount(this.filter)
      this.getCredentialReviewOrderFastPass(this.filter)
      this.AwaitPass(0);
    },
    ClearFilter()
    {
      this.tempFilter.Name = null;
      this.tempFilter.Statuses = null;
      this.tempFilter.Before = null;
      this.tempFilter.After = null;
      this.tempFilter.OrderNums = null;
      this.tempFilter.checked = ["Conditional Fail"];
    },
    TradeIsNotEmpty(Rules, tID)
    {
      //debugger;
      let isNotEmpty = false;
      if(Rules.BasicAndList[tID].length !== 0 || Object.keys(Rules.BasicOrList[tID]).length !== 0 ||  Object.keys(Rules.ComplexOrList[tID]).length !== 0)
      {
        isNotEmpty = true;
      }
      return isNotEmpty;
    },
    //Right Side Order Viewing
    FocusOrder(orderID)
    {

      //check if orders contains the order
      this.selectedExem = {};
      this.FocusedOrder = orderID;
      //document.getElementById("check-box").checked = false;
      document.getElementById("date").value = null;
      //get addresses
      this.getCredentialReviewOrderAddress(orderID);
      //get products
      this.getCredentialReviewOrderProducts(orderID);
    },
    Retrieve(exemType, customerID, id, orderID)
    {
      debugger;
      document.getElementById("right-side").scrollTop = 0;
      switch(exemType)
      {
        case "File":
          this.getCredentialReviewExemFile({OrderID: orderID, CustomerID: customerID, ExemptionID: Number(id)});
          break;
        case "Form":
          this.getCredentialReviewExemForm({OrderID: orderID, CustomerID: customerID, ExemptionID: Number(id)})
          this.getAdminFormTemplate(Number(id));
          break;
      }
      this.getComments({OrderID: orderID, ExemID: Number(id)});
    },
    MakeDecision(accepted, action)
    {
      debugger;
      // if(this.selectedExem.id)//set action based on incoming bools
      // {
      //   let action = "";
      //   if(accepted)
      //     action = "Accept";
      //   else
      //   {
      //     if(cType)
      //       action = "Comment";
      //     else
      //       action = "Deny";
      //   }
        const date = new Date(document.getElementById("date").value);
        //const date2 = );
        //const forFuture = false
        this.Decide({
          Accepted: accepted,
          OrderID: this.Pass.using ? this.FastPassOrder.id : this.FocusedOrder,
          ExemptionID: Number(this.selectedExem.id),
          ForFutureUse: date !== null || date !== "" || date !== undefined ? true : false,
          Expiration: date > new Date() ? document.getElementById("date").value : null,
          CommentOnly: action === "Comment" || action === "Upload",
          ExternalComment: document.getElementById("ExternalComment").value.trim(),
          InternalComment: document.getElementById("InternalComment").value.trim(),
          CustomerName: this.Pass.using ? this.FastPassOrder.name : this.orders[this.FocusedOrder].name,
          ExemptionName: this.selectedExem.name,
          Action: action
        })

        //clear note fields
        document.getElementById("ExternalComment").value = "";
        document.getElementById("InternalComment").value = "";
    },
  
    magnify(imgID, zoom) {
      const img = document.getElementById(imgID);
      //create magnifier glass:
      const glass = document.createElement("DIV");
      //glass.classList.add("img-magnifier-glass")//setAttribute("class", "img-magnifier-glass");
      //insert magnifier glass:
      img.parentElement.insertBefore(glass, img);
      //set background properties for the magnifier glass:
      glass.style.backgroundImage = "Url(" + img.src + ")";
      glass.style.backgroundRepeat = "no-repeat";
      glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
      glass.style.position = "absolute";
      glass.style.border = "2px solid rbga(#000000, 0.2)";
      glass.style.borderRadius = "1%";
      glass.style.width = "200px";
      glass.style.height = "200px";
      glass.style.pointerEvents = "none";
      glass.style.zIndex = "10";
      glass.id = "ZoomGlass"
      const bw = 3;
      const w = glass.offsetWidth / 2;
      const h = glass.offsetHeight / 2;


      function getCursorPos(e)
      {
        let x = 0, y = 0;
        e = e || window.event;
        //get the x and y positions of the image:
        const a = img.getBoundingClientRect();
        //calculate the cursor's x and y coordinates, relative to the image:
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        //consider any page scrolling:
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x : x, y : y};
      }
      function moveMagnifier(e)
      {
        //prevent any other actions that may occur when moving over the image
        e.preventDefault();
        //get the cursor's x and y positions:
        const pos = getCursorPos(e);
        let x = pos.x;
        let y = pos.y;
        //prevent the magnifier glass from being positioned outside the image:
        if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
        if (x < w / zoom) {x = w / zoom;}
        if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
        if (y < h / zoom) {y = h / zoom;}
        //set the position of the magnifier glass:
        const a = img.getBoundingClientRect();
        const b = document.getElementById("FileFrame").getBoundingClientRect();
        glass.style.left = (x - w + (a.left - b.left)) + "px";
        glass.style.top = (y - h) + "px";
        //display what the magnifier glass "sees":
        glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
      }



      //execute a function when someone moves the magnifier glass over the image:
      glass.addEventListener("mousemove", moveMagnifier);
      img.addEventListener("mousemove", moveMagnifier);
      //and also for touch screens:
      glass.addEventListener("touchmove", moveMagnifier);
      img.addEventListener("touchmove", moveMagnifier);

    },
    ZoomStart()
    {
      if(!document.getElementById("ZoomGlass"))
      {
        this.magnify("fileViewer", 1.75);
      }

    },
    ZoomEnd()
    {
      if(document.getElementById("ZoomGlass"))
      {
        document.getElementById("ZoomGlass").remove();
      }
    },
    Enlarge()
    {
      this.enlarged = !this.enlarged;
      if(this.enlarged)
      {
        this.$nextTick(() => {
          document.getElementById("enlargedImg").focus();
        });
      }
    },
    GenerateForm()
    {
      this.formHTML = "";
      const DataDict = {};
      for(const row of this.form)
      {
        DataDict[row[0].replace(/\s/g, '')] = row[1];
      } 
      debugger;

      for(const field of this.adminFormTemplate.fields)
      {
        if(field.ElementType.toLowerCase() === "message")
        {
          this.formHTML += `<span style="display: block; padding-left: 20px; margin-right: 20px;">${field.Message}</span>`
        }
        else
        {
        const label = field.Label.replace(/\s/g, '');
        const req = field.Required ? "required" : "";
        this.formHTML += `<label for="${label}" style="width: 150px;text-align: right;" class="formLabel">${field.Label}:</label>
                  <input id="${label}" name="${field.Label}" type="${field.ElementType}" value="${DataDict[label] === undefined ? "" : DataDict[label]}" ${req}><br/>`;
        }
      }
      this.formHTML += `<input type="submit" style="margin-left:154px;" value="Submit"></input>`;
    },
    AdminFormSubmit(event)
    {
      event.preventDefault();
      event.target.checkValidity();

      const formData = new FormData(event.target);
      let dataArr = [...formData];

      //checks for checkboxes
      for(const field of this.adminFormTemplate.fields)
      {
        if(field.ElementType === "checkbox")
        {
          let inSet = false;
          for(const pair of dataArr)
          {
            if(field.Label === pair[0])
            {
                inSet = true;
            }
          }
          if(!inSet)
          {
            dataArr = [...dataArr, [field.Label , ""]];
          }
        }
      }
      let displayOrder = "";
      let orderID = null;
      if(this.Pass.using)
      {
        orderID = this.FastPassOrder.id;
        displayOrder = this.FastPassOrder.systemDisplayOrderID;
      }
      else
      {
        orderID = this.FocusedOrder
        displayOrder = this.orders[this.FocusedOrder].systemDisplayOrderID;
      }
      const toSend = {formData: dataArr, OrderID: orderID, exemption: this.selectedExem.id + '', formTemplateID: this.adminFormTemplate.id, statusID: 2, exemptionTypeID: this.selectedExem.exemptionTypeID, displayOrderID: displayOrder, exemptionDataType: this.selectedExem.exemptionDataType, exemptionDataTypeDetailID: this.selectedExem.exemptionDataTypeDetailID, subscriberID: 0 };
      //console.log(toSend);
      this.submitAdminForm(toSend);
      //this.MakeDecision(false, "Upload")
      this.AwaitSubmit(0);
    },
    AdminFileSubmit(event)
    {
      event.preventDefault();
      event.target.checkValidity();


      let customer = null;
      let orderID = null;
      if(this.Pass.using)
      {
        orderID = this.FastPassOrder.id;
        customer = this.FastPassOrder.customerID;
      }
      else
      {
        orderID = this.FocusedOrder
        customer = this.orders[this.FocusedOrder].customerID;
      }
      debugger;
      const file = event.target.firstElementChild.files[0];
      const formData = new FormData();
      formData.append("formFile", file);
      formData.append("orderID", orderID);
      formData.append("exemption", this.selectedExem.id);
      formData.append("statusID", 2);
      formData.append("CustomerID", customer);
      //const check = this.uploadData({formData, trade, listType, exemId, HashKey});
      //this.uploadData({formData, trade, listType, exemId, HashKey})
      this.uploadAdminData(formData);
      this.AwaitSubmit(0);
    },
    /*AwaitAdminUpload(i)
    {
      if(this.reviewOrders.submissionLoading !== false && i < 150)
      {
        setTimeout(() => {
          this.AwaitAdminUpload(++i);
        }, 250);
      }
      else if(this.reviewOrders.submissionLoading === false)
      {
        if(this.submitStatus === "Success")
        {

        }
      }
    },*/
    AwaitPass(i)
    {
      if(this.reviewOrders.getCredentialReviewOrderFastPassLoading !== false && i < 150)
      {
        setTimeout(() => {
          this.AwaitPass(++i);
        }, 100);
      }
      else if(this.reviewOrders.getCredentialReviewOrderFastPassLoading === false)
      {
        if(this.FastPass.length === 0)
        {
          
          this.$store.commit('credentialReview/ResetOrderInfo');
          this.$store.commit('credentialReview/ResetFormData');
          this.Pass.using = false;
          this.selectedExem = {};
          return;
        }
        this.PassMove(1);
      }
    },
    AwaitSubmit(i)
    {
      if(this.reviewOrders.submissionLoading !== false && i < 150)
      {
        setTimeout(() => {
          this.AwaitSubmit(++i);
        }, 100);
      }
      else if(this.reviewOrders.submissionLoading === false)
      {
        if(this.adminSubmitStatus !== "Denied")
          this.selectedExem.status = this.adminSubmitStatus;

        let customerID, orderID;
        if(this.Pass.using)
        {
          customerID = this.FastPassOrder.customerID;
          orderID = this.FastPassOrder.id;
        }
        else
        {
          customerID = this.orders[this.FocusedOrder].customerID;
          orderID = this.orders[this.FocusedOrder].id;
        }
        
        //Check for full path
        const path = this.CheckPath();
        if(path !== null)
        {
            this.finalizeSubmissionNoEmail(path);
        }

        //only send upload to history if it succeeds
        if(this.submitStatus === "Success")
        {
          // Get the "accept" button element
          const acceptButton = document.getElementById("accept");
          // Enable the "accept" button
          acceptButton.disabled = false;
          // Get the "deny" button element
          const denyButton = document.getElementById("deny");
          // Enable the "deny" button
          denyButton.disabled = false;
          // Get the "override" button element
          const overrideButton = document.getElementById("override");
          // Disable the "override" button
          overrideButton.disabled = true;

          this.MakeDecision(false, "Upload");
        }

        if(this.selectedExem.status !== "Unsubmitted")
          this.Retrieve(this.selectedExem.exemptionDataType, customerID, this.selectedExem.id, orderID );
      }
    },
    View(exem, order)
    {
      this.Pass.using = false;
      this.manual = false;
      this.$store.commit('credentialReview/ResetOrderInfo');
      this.FocusOrder(order.id);
      this.selectedExem = exem; 
      
      if(exem.status !== 'Unsubmitted')
      {
        this.Retrieve(exem.exemptionDataType, order.customerID, exem.id, order.id);
      }
      else if(exem.exemptionDataType === "Form")
      {
        this.getAdminFormTemplate(Number(exem.id));
        this.getComments({OrderID: order.id, ExemID: Number(exem.id)});
      }
      else
      {
        this.getComments({OrderID: order.id, ExemID: Number(exem.id)});
      }
    },
    RenewLink(event)
    {
      
      if(this.Pass.using)
        this.updateLinkExpiration(this.selectedExem.orderID);
      else
        this.updateLinkExpiration(this.FocusedOrder);

      this.SendToastMessage('Link Sent!');

      // REMOVED 05/13/24 AS THIS IS NOT BEHAVING AS EXPECTED
      // REPLACED BY NATIVE VUE JS HYPER TOAST NOTIFICATION
      /*document.getElementById("SendLinkConfirm").classList.toggle("buttonFeedbackHide");
      document.getElementById("SendLinkConfirm").classList.toggle("buttonFeedback");
      document.getElementById("sendLink").disabled = true;
      setTimeout(() => {
        document.getElementById("SendLinkConfirm").classList.toggle("buttonFeedbackHide");
        document.getElementById("SendLinkConfirm").classList.toggle("buttonFeedback");
        document.getElementById("sendLink").disabled = false;
      }, 750);*/
    },
    AnimateButton(event)
    {
        const message = event.target.nextElementSibling.firstElementChild;
        const parent = event.target.parentElement;
        message.classList.toggle("buttonFeedbackHide");
        message.classList.toggle("buttonFeedback");
        parent.disabled = true;
        setTimeout(() => {
            message.classList.toggle("buttonFeedbackHide");
            message.classList.toggle("buttonFeedback");
            parent.disabled = false;
        }, 750);
    },
    ActivateButton(event)
    {
      const button =  event.target;
      button.classList.add("buttonActivated");
      setTimeout(() => {
        button.classList.remove("buttonActivated");
      }, 250);
    },
    ExpireLink(event)
    {
        if(this.Pass.using)
            this.expireLinkExpiration(this.selectedExem.orderID);
        else
            this.expireLinkExpiration(this.FocusedOrder);
        //this.AnimateButton(event);
        this.SendToastMessage('Reminders Stopped');
    },
    GoToLink()
    {
      window.open(this.imgString,"","width=820,height=1100");
    },

    CheckPath()
    {
        //get ruleset
        const ruleset = this.orderDetails[this.FocusedOrder]
        const ttype = ruleset.OpenTrade
        
        //checking all trade types
        
        let path = "";
        //check all AND ALL's
        for(const exem of ruleset.BasicAndList[ttype])
        {
            if(!(exem.status === "Received" || exem.status === "Accepted"))
                return null;
            else
                path += exem.id + ","
            debugger;
        }
        //check all AND ANY's
        for(const setID of Object.keys(ruleset.BasicOrList[ttype]))
        {
            const set = ruleset.BasicOrList[ttype][setID];
            let fulfilled = false;
            for(const exem of set.exemptions)
            {
                //const exem = set.exems[exemID];
                if(exem.status === "Received" || exem.status === "Accepted")
                {
                    path += exem.id + ","
                    fulfilled = true;
                    break;
                }
            }
            if(!fulfilled)
                return null;
        }
        //check all OR's
        let fulfilled = false;
        for(const ruleID of Object.keys(ruleset.ComplexOrList[ttype]))
        {
            const rule = ruleset.ComplexOrList[ttype][ruleID];
            for(const setID of Object.keys(rule.exemptionSets))
            {
                const set = rule.exemptionSets[setID];
                let goodRule = true;
                let tempPath = ""
                //ALL
                for(const exemID of Object.keys(set.andExemptions))//ADD TO TEMP TO ONLY ADD FOR GOOD RULE
                {
                    const exem = set.andExemptions[exemID];
                    if(!(exem.status === "Received" || exem.status === "Accepted"))
                    {
                        goodRule = false;
                        break;
                    }
                    else
                        tempPath += exem.id + ",";
                }
                //ANY
                if(goodRule)
                {
                    let fulfilledAny = false;
                    for(const anysetID of Object.keys(set.orExemptions))//SAME AS FOR "ALL" NEED TEMP PATH
                    {
                        const anyset = set.orExemptions[anysetID];
                        for(const exemID in Object.keys(anyset.exems))//PROBABLY WRONG
                        {
                            const exem = anyset.exems[exemID];
                            if(exem.status === "Received" || exem.status === "Accepted")
                            {
                                tempPath += exem.id + ","
                                fulfilledAny = true;
                                break;
                            }
                        }
                    }

                    if(fulfilledAny || Object.keys(set.orExemptions).length === 0)
                    {
                        path += tempPath;
                        fulfilled = true;
                        break;
                    }
                }

            }
        }

        if(!fulfilled && Object.keys(ruleset.ComplexOrList[ttype]).length !== 0)
        {
            return null;
        }
        //return if trade type is fulfilled
        return {OrderID: this.FocusedOrder, TradeType: Number(ttype), Path: path.substring(0, path.length - 1)}//the substring is to remove the trailing commma
    },
    SendToastMessage(toastText, vartiantType = 'info')
    {
      this.$bvToast.toast(toastText, {
        //title: null,
        autoHideDelay: 1000,
        appendToast: false,
        variant: vartiantType,
        solid: true,
        noCloseButton: true
      });
    }

  },
};
</script>

<style lang="scss" scoped>


.dateCol {
  width: 125px;
}

.statusCol {
  width: 50px;
}

.actionCol {
  width: 75px;
}


.statusTable {
  display: table;
  table-layout: fixed;
  width: 502px;
}

.TableDiv
{
  overflow-y: scroll;
}

.screenHeight
{
  max-height: 78vh;
  margin-bottom: 0;
}

.max-size {
  //max-height: 900px;
}

/*Styling for container div*/
.wrapperDiv {
  /*background-color: white;*/
  //display: grid;
  //grid-template-areas:
  //@at-root  '.sales-orders' '.right-side';
  /*box-shadow: 0 0 15px 0 rgb(154 161 171 / 15%);*/
  //position: relative;
  //z-index: 20;
  //box-shadow: inset 0 -20px 10px black;
  //clip-path: inset(0px 0px 0px 0px);
  height: 78vh;
}

/*Styling for right side container holds documenent frame, notes, and history table*/
#right-side {
  flex-basis: auto;
  overflow-y: scroll;
  padding-left: 10px;
  margin-left: 550px;
  //max-height: fit-content;
  //height: fit-content;
  height: 100%;
  //scrollbar-width: none;
  //-ms-overflow-style:none ;
  

  > :first-child
  {
    margin-bottom:0;
    padding-top: 4px;
    padding-bottom: 3px;
    //height: 78vh;
  }

  > :nth-child(2)
  {
    margin-bottom: 0;
    margin-top: 16px;
  }
}

#right-side::-webkit-scrollbar
{
  display: none ;
}

/*Styling for order info headers*/
#order-info {
  justify-content: space-between;
  margin-top: 20px;
}

#headers {
  >:first-child
  {
    width: 65%;
  }
  >:nth-child(2)
  {
    position:absolute;
    right:40px;
  }
  h5
  {
    display: inline-block;
    margin-top: 0;
  }
}

#reqInfo
{
  :first-child
  {
    width: 100%;
  }
}

/*Styling for documentation frames*/
#form-frame
{
  width: 400px;
  height: 400px;
  border: 1px solid #00000033;
  overflow: auto;
}

.status
{
  margin-left: 15px;
}

#id-frame {
  border: 1px solid #00000033;
  //border-radius: 5px;
  //min-height: 150px;
  height: 400px;
  width: 400px;//100%;

  display: flex;
  justify-content: center;
}

#fileViewer
{
  max-height: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;

  //cursor: none;
}

#fileViewerZoom
{
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  //transform: translateY(-100px) translateX(500px);
  //transform-origin: center;
  //pointer-events: none;
  z-index: 5;
}

#enlargedImg
{
  position: fixed;
  background-color: #00000022;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  img
  {
    margin:auto;
    max-width: 35%;//because of the scale this is actually 70%
    max-height: 40%;//because of the scale this is actually 80%
    scale: 2;
    border: 5px solid #f5f5f5;
    border-radius: 10px;
  }

}

#ZoomedFile
{
  transform: scale( 1.5 , 1.5);
  position: relative;
}

#FileFrame
{
  width: 100%;
  //width: 400px;
  height: 100%;
  //height: 400px;
  //border-radius: 5px;
  background-color: #f5f5f5;
  position: relative;
}

#noFile
{
  width: fit-content;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

#pdfViewer
{
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;//464px;
  border:none;
}

#FormFrame
{
  padding: 1em;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: auto;
}

.formRowLabel
{
  display: inline-block;
  width: 150px;
  text-align: end;
  margin-right: .25em;
}

/*Styling for deny and accept buttons*/
#button-container {
  text-align: center;
  padding-bottom:1em;
  > :first-child
  {
    margin-right: 10px;
    margin-left: 0;
  }
}

.button {
  margin-top: 10px;
  margin-right: 10px;
  color: #222;
  padding: 10px;
  width: 150px;
  height: 42px;
  border-radius: 10px;
  border: none;
  background-color: #BFD9F1;
}

.button:disabled
{
  color: #aaa;
}

#accept {
  background-color: #0acf97;
  color: white;
  margin-right: 0;
}

#deny {
  background-color: #fa5c7c;
  color: white;
}

#override {
  background-color: #ffbc00;
  color: white;
  margin-left: 10px;
}

#ManualOverride
{
  position: absolute;
  margin: 0;
  top: 5px;
  right: 5px;
  border: 1px solid #222;
  height: fit-content;
  width: fit-content;
  padding-top:.25em;
  padding-bottom:.25em;
  vertical-align: middle;
}

#ManualFile input
{
  filter: opacity(1);
}

.FPBtn
{
  margin-left: 10px;
  //margin-right: 10px;
  border-radius: 10px;
  height: 42px;
  //padding-bottom: 15px;
  line-height: normal;
  
}



/*Styling for Notes container */
#Notes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  textarea
  {
    resize: none;
  }
}

/*Styling for Note containers */
.box {
  height: 50px;
}

.noteArea
{
  border:1px solid #00000040;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}



/*Styling Between Calendar and Expiration Date */
#order-info-header-to-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left:0;
}

//Styling for Fulfillment Requirements
.formLabel
{
  width: 50%;
}

//date input styling
#calendar-div span {
  margin-right: 10px;
}

#floatLeft {
  float: left;
}

#floatRight {
  float: right;
}

//THIS IS WHAT I HAVE ADDED
.card
{
  padding: 1em 1em 1em 1em;
  //border: 1px solid rgba($color: #000000, $alpha: 0.0);
  height: fit-content;
}

.left-side
{
  width:550px;
  float: left;
  max-height: 78vh;
  overflow-y:scroll;
  > :first-child
  {
    height: 100%;
  }
}

.left-side::-webkit-scrollbar
{
  display: none;
}

.Subtable
{
  table-layout:fixed;
  margin: 0 auto;
  width: 465px;
}

.TableHead
{
  position:sticky;
  top: 0;
  background-color: white;
  box-shadow:0 1px rgba($color: #000000, $alpha: 0.05) ;
  z-index: 2;
  th
  {
    border: 0;
  }
}

.clickable:hover
{
  cursor: pointer;
  background-color: #eeeeee;
}

.ibtn
{
  margin-right: 4px;
  margin-bottom: 3px;
  height: 2.5em;
  width: 2.5em;
  text-align: center;
  padding: 0;
}

.label
{
  margin-right: 5px;
  width: 75px;
}

.miniLabel
{
  width:fit-content;
  margin-left: 2px;
}

#summaryFilter
{
  margin-top:5px;
  padding: 5px;
  padding-bottom:10px ;
  background-color: #fafafa;
  border-radius: 3px;
  width:519px;
}

#filterBtn
{
  background-color: #F89C43;
  border-color: #F89C43;
  color: #ffffff;
  float: right;
  margin-left: 4px;
}

#clearBtn
{
  background-color: #727cf5;
  border-color: #727cf5;
  color: #ffffff;
  float: right;
}

#pageSpan
{
  margin-right: 4px;
  font-size: 12.5pt;
}

.btnCell
{
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px;
}

.changeBtn
{
  margin-top: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

#Name
{
  width: 170px;
}

#OrdNums
{
  width: 170px;
}

#orderLabel
{
  margin-left: 6px;
}

#endDateLabel
{
  margin-left: 46px;
}

#statusDiv
{
  margin-top: 5px;
  display: inline-block;
}

#statusLabel, .miniLabel
{
  margin-bottom: 0;
}

input
{

  border:1px solid rgba($color: #000000, $alpha: .4);
  border-radius: 2px;

  //color:rgba($color: #000000, $alpha: 0.5);
  filter: opacity(.6);
}

input[type='checkbox']:checked{
  accent-color: #727cf5 ;
  filter: opacity(1);
}

//ADRESSE AND PRODUCT INFO
#addresses
{

  vertical-align: top;
}

#products
{

  vertical-align: top;
}

#prdList
{
  list-style-type: "►";
}

.dropdown-menu
{
  position: relative !important;
  width: 100% !important;
  max-width: 400px !important;
  min-width: 1rem !important;
}

.dropdown-menu  li > a
{
  min-width: 0;
  white-space: normal;
  width: 100%;
  max-width: 400px;
}


#ColumnLeft
{
  width: 400px;
  //display: inline-block;
  //float:left;
  position: absolute;
  //float: left;
}

#addresses-and-products-ordered
{
  //width: 55%;
  min-height: 400px;
  margin-left:410px;
  display: inline-block;
  vertical-align: top;

}


#CommentTable
{
  thead tr{
    :first-child
    {
      width: 1%;
    }
    :nth-child(2)
    {
      width: 20%;
    }
    :nth-child(3)
    {
      width:10%;
      //width: fit-content;
    }
    :nth-child(4)
    {
      text-wrap: nowrap;
      width: 10%;
    }
    tbody td
    {
      vertical-align: top;
    }
  }
}

.feedback
{
  //background-color: #000000;
  height: 20px;
  position: relative;
  left: 17%;
  top: -30px;
  font-weight: bold;
  pointer-events: none;
}

@keyframes feedbackAnim 
{
  0%{top: 0px; opacity: 80%;}
  80%{top: -28px; opacity: 0%;}
  100%{top: -30px; opacity: 0%; display:none;}
}

/*.buttonFeedback
{
  animation-name: feedbackAnim;
  animation-duration: .75s;
  animation-fill-mode: forwards;
  position: relative;
}

.buttonFeedbackHide
{
  display: none;
}*/

@keyframes BtnActive
{
  50% {filter: brightness(85%);}
}

.buttonActivated
{
 //background-color:white;
  animation-name: BtnActive;
  animation-duration: 250ms;
}

.orderButton > button
{
  width: 100px;
  height: 25px;
  margin: 0;
  padding: 2px 0 0 0;
}

.orderButton {
	float: right;
    margin-left: 1em;
}

#addInternalBtn
{
  float: right;
  margin: 0;
  margin-top: 6.5px;
  padding: 2px 0 0 0;
  height: 25px;
}

#watermark
{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10%;

  width: fit-content;
  height: fit-content;

  color: #fa5c7c;
  filter:opacity(.75);
  font-size: 50px;
  pointer-events: none;

  margin-left: auto;
  margin-right: auto;
  div{
    text-align: center;
    padding-left: .25em;
    padding-right: .25em;
    border: 5px solid #fa5c7c;
    border-radius: 5px;
  }
}

//
#headers>:first-child {
  width: auto !important;
}
#headers>:nth-child(2) {
  float: right;
  margin-left: 15px;
  position: relative !important;
  right: 0 !important;
}

#reqInfo h5 {
  color: #222;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px 10px 0px;
  text-transform: uppercase;
  background-color: #efefef;
  border-radius: 4px;
  text-align: center;
}
#reqInfo span {
  font-weight: 400;
  padding: 10px 0 5px;
  line-height: 20px;
  text-transform: none;
  display: block;
}
#addresses-and-products-ordered {
	margin-left: 415px !important;
}
#addresses-and-products-ordered h5 {
	font-size: 18px;
	margin: 10px 0 5px;
}
#addresses-and-products-ordered p {
  color: #555;
  font-weight: bold;
  margin: 0 0 2px;
}
#addresses-and-products-ordered span {
  font-weight: 400;
}
#addresses, #products {
	margin: 25px 0;
}
#addInternalBtn {
	margin: 0;
}
#clearBtn {
  background-color: #bfd9f1;
  border-color: #bfd9f1;
  color: #555;
  float: right;
}
#id-frame {
	border-radius: 4px;
}
.formRowLabel {
	font-weight: 700;
}
input {
	padding: 2px 7px;
}
#ManualOverride {
	border: 0 !important;
}
#FileFrame {
	border-radius: 4px;
}
button {
  border-radius: 4px !important;
}

#FFLScope
{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  font-size: large;
  > button
  {
    display: inline-flex;
    align-items: center;
    width: 4.35em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: calc(50% - 2.175em);
    margin-right: 0;
  }

  > p
  {
    margin-bottom: 0;
  }
}

div #innerRight
{
  padding-bottom: 0!important;
}

.btnCell button, .btnCell > div > div > :first-child{
    padding: 0px 7px;
    font-size: 12px;
    margin: 0 0 3px 0;
    border: 1px solid #58568c;
    text-transform: capitalize;
    border-radius: 4px;	
}
button#__BVID__21__BV_toggle_ {
    margin: 0;
}
button#accept.button[disabled] {
    background-color: darkgray;
}
button#deny.button[disabled] {
    background-color: darkgray;
}
button#override.button[disabled] {
    background-color: darkgray;
}
.overriden
{
  width: fit-content;
  margin-left: 10px;
  margin-right: auto;
  margin-top: 1em;
  font-size: large;
  > button
  {
    display: inline-flex;
    align-items: center;
    width: 4.35em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: calc(50% - 2.175em);
    margin-right: 0;
  }

  > p
  {
    margin-bottom: 0;
  }
}
</style>