import config from "config";
import { authHeader, handleResponse } from "../_helpers";

function getCredentialReviewOrders(page){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(page)
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrders`, requestOptions).then(
        handleResponse
    );
}
function getCredentialReviewOrder(id){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(id)
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrder`, requestOptions).then(
        handleResponse
    );
}
function getCredentialReviewOrderPageCount(Filters){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(Filters)
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderPageCount`, requestOptions).then(
        handleResponse
    );
}
function getCredentialReviewOrderFastPass(Filters){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(Filters)
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderFastPass`, requestOptions).then(
        handleResponse
    );
}
function getCredentialReviewOrderDetails(credentialOrderObject){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(credentialOrderObject)
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderDetails`, requestOptions).then(
        handleResponse
      );
}
function getCredentialReviewOrderIntegrations(id){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(id)
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderIntegrations`, requestOptions).then(
        handleResponse
    );
}
function getCredentialReviewOrderAddress(credentialOrderObject){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(credentialOrderObject ? credentialOrderObject : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderAddress`, requestOptions).then(
        handleResponse
      );
}
function getCredentialReviewOrderProducts(credentialOrderObject){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(credentialOrderObject ? credentialOrderObject : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderProducts`, requestOptions).then(
        handleResponse
      );
}
//for credential retrieval
function getCredentialReviewExemFile(fileRetrievalObject){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(fileRetrievalObject ? fileRetrievalObject : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewExemFile`, requestOptions).then(
        handleResponse
      );
}
function getCredentialReviewExemForm(formRetrievalObject){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(formRetrievalObject ? formRetrievalObject : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewExemForm`, requestOptions).then(
        handleResponse
      );
}

function Decide(DecisionObject){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(DecisionObject ? DecisionObject : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/decide`, requestOptions).then(
        handleResponse
      );
}

function getComments({OrderID, ExemID}){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(OrderID && ExemID ? {OrderID, ExemptionID: ExemID} : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/comments`, requestOptions).then(
        handleResponse
      );
}

function getSingleFormtemplate(id)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(id ? id : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getSingleFormTemplate`, requestOptions).then(
        handleResponse
    );
}

function adminSubmitForm(formData)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(formData ? formData : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/AdminSubmitForm`, requestOptions).then(
        handleResponse
    );
}

function adminUploadData(fileData)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader()},
        body: fileData ? fileData : {}
    }
    return fetch(`${config.apiUrl}/credentialreview/AdminUpload`, requestOptions).then(
        handleResponse
    );
}

//for iframe
function getOrderRuleSet(key)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(key ? key : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getOrderRuleSet`, requestOptions).then(
        handleResponse
      );
}

function getCredentialReviewOrderByKey(key)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(key ? key : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderByKey`, requestOptions).then(
        handleResponse
    );
}

function getCredentialReviewOrderProductsByKey(key)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(key ? key : {})
    }

    return fetch(`${config.apiUrl}/credentialreview/getCredentialReviewOrderProductsByKey`, requestOptions).then(
        handleResponse
    );
}

function uploadData(formData)
{
    debugger;
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader()},
        body: formData ? formData : {}
    }

    return fetch(`${config.apiUrl}/credentialreview/upload`, requestOptions).then(
        handleResponse
    );
}

function getFormtemplate(id)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(id ? id : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getFormTemplate`, requestOptions).then(
        handleResponse
    );
}

function getIntegrations(id)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(id ? id : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/getIntegrations`, requestOptions).then(
        handleResponse
    );
}

function submitForm(formData)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(formData ? formData : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/submitForm`, requestOptions).then(
        handleResponse
    );
}

function finalizeSubmission(path)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(path ? path : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/submissionFinalize`, requestOptions).then(
        handleResponse
    );
}

function finalizeSubmissionNoEmail(path)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(path ? path : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/submissionFinalizeNoEmail`, requestOptions).then(
        handleResponse
    );
}

function updateExpiration(OrderDate)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(OrderDate ? OrderDate : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/submissionLinkDateUpdate`, requestOptions).then(
        handleResponse
    );
}

function ExpireLink(OrderDate)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(OrderDate ? OrderDate : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/submissionLinkDateExpire`, requestOptions).then(
        handleResponse
    );
}

function IDmeVerify(ID)
{
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(ID ? ID : {})
    }
    return fetch(`${config.apiUrl}/credentialreview/idmeVerify`, requestOptions).then(
        handleResponse
    );
}

export const credentialReviewService = {
    getCredentialReviewOrders,
    getCredentialReviewOrder,
    getCredentialReviewOrderPageCount,
    getCredentialReviewOrderFastPass,
    getCredentialReviewOrderDetails,
    getCredentialReviewOrderIntegrations,
    getCredentialReviewOrderAddress,
    getCredentialReviewOrderProducts,
    getCredentialReviewExemFile,
    getCredentialReviewExemForm,
    Decide,
    getComments,
    adminSubmitForm,
    adminUploadData,
    getSingleFormtemplate,
    getOrderRuleSet,
    getCredentialReviewOrderByKey,
    getCredentialReviewOrderProductsByKey,
    uploadData,
    getFormtemplate,
    getIntegrations,
    submitForm,
    finalizeSubmission,
    finalizeSubmissionNoEmail,
    updateExpiration,
    ExpireLink,
    IDmeVerify,
};
