import moment from "moment";
import { productService } from "../_services";

const state = {
  productList: {
    status: {},
    addStatus: {},
    importStatus: {},
    updateStatus: {},
    products: null,
    productCount: null,
    lastImportDate: null,
    error: null
  },
  filteredProductList: {
    status: {},
    importStatus: {},
    updateStatus: {},
    products: null,
    productCount: null,
    lastImportDate: null,
    error: null
  }
};

const actions = {
    
  getProducts({ dispatch, commit }, productDto) {
    commit("getProductsRequest");

    const productDtoObject = Object.assign({}, productDto);
    productService.getProducts(productDtoObject).then(
      response => {
        commit("getProductsSuccess", response);
      },
      error => {
        commit("getProductsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getFilteredProducts({ dispatch, commit }, searchText) {
    commit("getFilteredProductsRequest");
    productService.getFilteredProducts(searchText).then(
      response => {
        commit("getFilteredProductsSuccess", response);
      },
      error => {
        commit("getFilteredProductsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  addProduct({ dispatch, commit }, productDto) {
    commit("addProductRequest");

    const productDtoObject = Object.assign({}, productDto);

    productService.addProduct(productDtoObject).then(
      response => {
        commit("addProductSuccess", response);
        //dispatch("getProducts");
      },
      error => {
        commit("addProductFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  updateProduct({ dispatch, commit }, productDto) {
    commit("updateProductRequest");

    const productDtoObject = Object.assign({}, productDto);

    productService.updateProduct(productDtoObject).then(
      response => {
        commit("updateProductSuccess", [response, productDto]);
      },
      error => {
        commit("updateProductFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  importProducts({ dispatch, commit }, importDto) {
    commit("importProductRequest");

    const importDtoObject = Object.assign({}, importDto);

    productService.importProducts(importDtoObject).then(
      response => {
        dispatch("getProducts");
        commit("importProductSuccess", [response, importDto]);
      },
      error => {
        commit("importProductFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  deleteProduct({ dispatch, commit }, productDto) {
    commit("deleteProductRequest");

    const productDtoObject = Object.assign({}, productDto);

    productService.deleteProduct(productDtoObject).then(
      response => {
        commit("deleteProductSuccess", [response, productDto]);
      },
      error => {
        commit("deleteProductFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  }

};

const mutations = {
    getProductsRequest(state) {
        state.productList.status = { showTableHeader: true, productsLoaded: true };
    },
    getProductsSuccess(state, response) { 
        for (const product of response.data.products) {
            product.showDetails = true;
            product.variants = product.variants === undefined ? null : product.variants;
            product.checked = response.data.checkedItems !== undefined && response.data.checkedItems !== null && response.data.checkedItems.length > 0 && response.data.checkedItems.find(item => item.id == product.id) !== undefined 
                              ? response.data.checkedItems.find(item => item.id == product.id).checked : false;
            product.dragHovered = false;
            product.lastImported = new moment.utc(product.lastImported);
            if(product.parentID !== null && product.parentID !== undefined) {
                // eslint-disable-next-line
                var parentIndex = response.data.products.findIndex(x => x.id === product.parentID)
                if(parentIndex === -1) {
                  product.showDetails = false;
                  continue;
                }
                response.data.products[parentIndex].variants === null || response.data.products[parentIndex].variants === undefined ? response.data.products[parentIndex].variants = [ product ] : response.data.products[parentIndex].variants.push(product);
                response.data.products[parentIndex].showDetails = true;
                response.data.products = response.data.products.filter(x => x.id !== product.id);
            }

            if(product.tags !== null) {
                const tagIds = product.tags.split(",")
                for (let index = 0; index < tagIds.length; index++) {
                    tagIds[index] = parseFloat(tagIds[index])
                }
                product.tagIds = tagIds
            }
            else {
                product.tagIds = []
            }
        }

        state.productList.lastImportDate = response.listCount > 0 ? new Date(Math.max.apply(null, response.data.products.map(function(e) {
                                                return new Date(e.lastImported);
                                              }))) : new Date("0001-01-01");
        state.productList.products = response.data.products;
        state.productList.productCount = response.listCount;
        state.productList.status = { productsLoaded: true, showTableHeader: true};
    },
    getProductsFailure(state, error) {
        state.productList.error = error;
        state.productList.status = { gettingProductsError: true };
    },

    getFilteredProductsRequest(state) {
      state.filteredProductList.status = { gettingProducts: true };
      state.filteredProductList.status = { productsLoaded: true };
    },
    getFilteredProductsSuccess(state, response) {
        state.filteredProductList.products = response.data.products;
        state.filteredProductList.status = { gettingProducts: false };
        state.filteredProductList.status = { productsLoaded: false };
    },
    getFilteredProductsFailure(state, error) {
        state.filteredProductList.error = error;
        state.filteredProductList.status = { gettingProductsError: true };
    },
    addProductRequest(state) {
        state.productList.addStatus = { addingProduct: true};
    },
    addProductSuccess(state) {
      state.productList.addStatus = { addingProduct: false, productAdded: true };
    },
    addProductFailure(state, error) {
        state.productList.error = error;
        state.productList.status = { addingProductError: true };
    },
    deleteProductRequest(state) {
      state.productList.deleteStatus = { deletingProduct: true};
    },
    deleteProductSuccess(state) {
      state.productList.deleteStatus = { deletingProduct: false, productDeleted: true };
    },
    deleteProductFailure(state, error) {
      state.productList.error = error;
      state.productList.status = { deletingProductError: true };
    },
    updateProductRequest(state) {
        state.productList.updateStatus = { updatingProduct: true };
    },
    updateProductSuccess(state, dataArray) {
        const productIds = dataArray[1].productIds.split(",")
        for (let i = 0; i < state.productList.products.length; i++) {
          const element = state.productList.products[i];
          if (productIds.includes(element.id.toString())) {
            element.active = dataArray[1].active
          }
          if(element.variants) {
            for (let k = 0; k < element.variants.length; k++) {
              const variant = element.variants[k];
              if (productIds.includes(variant.id.toString())) {
                variant.active = dataArray[1].active
              }
            }
          }
        }
        state.productList.updateStatus = { productUpdated: true };
    },
    updateProductFailure(state, error) {
        state.productList.error = error;
        state.productList.updateStatus = { updatingProductError: true };
    },

    importProductRequest(state) {
      state.productList.importStatus = { productsImporting: true};
    },
    importProductSuccess(state) {
        state.productList.importStatus = { productsImported: true };
    },
    importProductFailure(state, error) {
        state.productList.error = error;
        state.productList.importStatus = { productsImportError: true };
    },
};

export const product = {
  namespaced: true,
  state,
  actions,
  mutations
};
