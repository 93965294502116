<template>
    <div>
        <h4 class="group-title">My Profile</h4>
        <p class="description">Customize your profile.</p>
        <div class="groupSet card">
            <h4 class="header-title">General</h4>
            <p>General settings for your profile.</p>
            <form @submit="UpdateProfile($event)">
                <div class="settingRow">
                    <label for="ProfileFName">First Name:</label>
                    <input id="ProfileFName" class="form-control" v-model="tempProfileSettings.firstName">
                </div>
                <div class="settingRow">
                    <label for="ProfileLName">Last Name:</label>
                    <input id="ProfileLName" class="form-control" v-model="tempProfileSettings.lastName">
                </div>
                <div class="settingRow">
                    <label for="ProfileEmail">Email Address:</label>
                    <input id="ProfileEmail" class="form-control" v-model="tempProfileSettings.emailAddress">
                </div>
                <div class="settingRow">
                    <label>Email Notifications:</label>
                    <div class="OptionSwitch">
                        <div>
                            <input type="checkbox" id="EmailNot" data-switch="success" v-model="tempProfileSettings.emailNotifications"/>
                            <label for="EmailNot" data-on-label="On" data-off-label="Off" class="mb-0 d-block"></label>
                        </div>
                    </div>
                </div>
                <button class="btn userButton btn-secondary" id="SaveButton">Save</button>
            </form>
        </div>
    </div>
</template>

<script>
import appConfig from "@/app.config";
import { mapState, mapActions } from "vuex";
import { nextTick } from 'vue';

export default {
    name: "OptionPage",
    page: {
        title: "profile settings",
        meta: [{ name: "description", content: appConfig.description }]
    },
    data() {
        return {
            flags: '',
            tempProfileSettings: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                emailNotifications: false,
            },
        }
    },
    computed: {
        ...mapState('settings', ["Profile", "Status"]),
    },
    methods:
    {
        ...mapActions("settings", ["getProfileSettings", "updateProfileSettings"]),
        async UpdateProfile(ev)
        {
            ev.preventDefault();

            await this.updateProfileSettings(this.ValidateSettings());
            await this.getProfileSettings();
            this.updateTempProfile();
        },
        ValidateSettings()
        {
            const temp = this.tempProfileSettings
            return temp
        },
        updateTempProfile()
        {
            this.tempProfileSettings = this.Profile;
        },
    },
    async created() {
        
        await this.getProfileSettings();
        this.updateTempProfile();
    },
}
</script>

<style lang="scss" scoped>

.OptionSwitch
{
    width: fit-content;
    display: inline-block;
}

label
{
    width: 10em;
    vertical-align: middle;
    font-weight: 600;
    margin: 0;
}

.form-control
{
    width: 24em;
    display: inline-block;
    margin-bottom: .5em;
}

.settingRow
{
    display: flex;
    border: 1px solid rgba($color: #000000, $alpha: 0.0);
    align-items: center;
}

#SaveButton
{
    position: absolute;
    right: 1em;
    bottom: 1em
}
</style>