<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<template>
  <!-- start page title -->
  <div class="row lockCard">
    <!--div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
        <h4 class="page-title">{{ title }}</h4>
      </div>
    </div-->
  </div>
  <!-- end page title -->
</template>

<style scoped>
.lockCard {
  min-width: 1200px;
  padding-top: 17px;
}
.lockCard .row {
  flex-wrap: nowrap !important;
}
.lockCard .text-sm-right {
    text-align: right !important;
}
body {
  min-width: 1600px !important;
  overflow-x: auto !important;
}
.topbar-nav .metismenu {
    display: flex;
    flex-direction: row !important;
}
</style>
