<template>
    <b-modal centered size="lg" id="modal-addProduct" hide-footer @close="reset" title="Add Product">
        <div id="reportRow" class="row">
            <div class="col-12">
                <div class="card" id="reportCard">
                    <div class="card-body">
                        <div class="row mb-0">
                            <div class="col-lg-12">
                                <form ref="form" @submit.stop.prevent="handleSubmit">
                                    <!-- Simple or Variant Products-->
                                    <b-form-group label="Product ID" label-for="productID-input"
                                        invalid-feedback="Product ID is required" :state="productIDState">
                                        <b-form-input id="productID-input" placeholder="SKU, UPC, MFG#, etc."
                                            v-model="addProductObject.SubscriberProductID" :state="productIDState"
                                            required></b-form-input>
                                    </b-form-group>
                                    <br>
                                    <b-form-group label="Product Name" label-for="productName-input"
                                        invalid-feedback="Product Name is required" :state="productNameState">
                                        <b-form-input id="productName-input" v-model="addProductObject.name"
                                            :state="productNameState" required></b-form-input>
                                    </b-form-group>
                                    <br>
                                    <b-form-group label="Product Description" label-for="productDescription-input">
                                        <b-form-textarea id="productDescription-input"
                                            v-model="addProductObject.description"></b-form-textarea>
                                    </b-form-group>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
                <div slot="footer" class="row mb-2">

                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <div class="col pr-0">
                        <b-button class="float-right echeckpointBlue " size="sm" @click="reset()">
                            Cancel
                        </b-button>
                    </div>
                    <div class="col-auto">
                        <b-button type="submit" class="float-right userButton" size="sm" @click="check()">
                            Add Product
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

    </b-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { authHeader } from '../../_helpers';
import { productService } from '../../_services/product.service';

//const isVisible = ref(false);

export default {
    data() {
        return {
            productNameState: null,
            productIDState: null,
            //parentProductNameState: null,
            //parentProductIDState: null,
        }
    },
    computed:
    {
        ...mapState({
            account: (state) => state.account,
            product: (state) => state.product,
            productDesignation: (state) => state.productDesignation,
        }),
    },
    methods: {
        ...mapActions("product", ["addProduct", "updateProduct", "getProducts"]),
        resetModal() {
            this.productNameState = null;
            this.productIDState = null;
        },
        checkFormValidity() {
            this.productNameState = this.addProductObject.name != null && this.addProductObject.name != "";
            this.productIDState = this.addProductObject.SubscriberProductID != null && this.addProductObject.SubscriberProductID != "";

            if (!this.productIDState || !this.productNameState) {
                return false
            }
            else {
                this.addProductObject.name = this.addProductObject.name.trim();
                this.addProductObject.SubscriberProductID = this.addProductObject.SubscriberProductID.trim();
                this.productNameState = this.addProductObject.name != "";
                this.productIDState = this.addProductObject.SubscriberProductID != "";
                if (!this.productIDState || !this.productNameState) {
                    return false;
                }
                if (this.addProductObject.description != null)
                    this.addProductObject.description = this.addProductObject.description.trim();
                return true
            }
        },
        check() {
            // Trigger submit handler
            this.handleSubmit();
        },
        reset() {
            this.addProductObjectNull();
            this.resetModal();
            this.$emit("hideModal")
        },
        handleSubmit() {
            this.resetModal()
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // add new product
            this.addProduct(this.addProductObject);
            this.addProductStatus(1000, 0);

        },
        async addProductStatus(i, stop) {
            if (i == null) {
                this.addProductStatus(1000, 0);
            }
            const timeout = 240000;
            if (stop > timeout) {
                alert("Timed Out");
            } else if (this.product.productList.addStatus.addingProduct) {
                const temp = stop + i;
                setTimeout(() => {
                    this.addProductStatus(i, temp);
                }, i);
            } else if (this.product.productList.addStatus.addingProduct == false) {
                await this.$nextTick(() => {
                    this.$forceUpdate();
                });
                this.$emit("searchProducts");
                // Hide the modal manually
                this.addProductObjectNull();
                this.resetModal();
                this.$emit("hideModal");
            }
        },
        addProductObjectNull() {
            this.addProductObject.active = false;
            this.addProductObject.description = "";
            this.addProductObject.name = null;
            this.addProductObject.SubscriberParentProductID = null;
            this.addProductObject.SubscriberProductID = null;
            this.addProductObject.parentName = null;
            this.addProductObject.parentDescription = null;
        },
    },
    props: {
        addProductObject: {
            required: true
        }
    }
}
//
</script>
<style>
.pad {
    padding: 10px;
}
</style>
