<template>
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div id="container" class="align-items-center d-flex h-100">
        <div class="card-body">
          <!-- Logo -->
          <logo-content></logo-content>

          <!-- login default and is not locked out -->
          <span v-show="!isAccountLocked">
            <!-- title-->
            <h4 class="mt-0">Sign In</h4>
            <p class="text-muted mb-4">
              Enter your email and password to login.
            </p>

            <b-form @submit.prevent="handleSubmit">
              <b-form-group
                id="input-group-1"
                label="Email"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="email"
                  required
                  :state="loginState"
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2">
                <!-- ----- FORGET YOUR PASSWORD ----- -->

                <router-link
                  tabindex="-1"
                  to="/recover"
                  class="text-muted float-right"
                >
                  <small>Forgot your password?</small>
                </router-link>

                <label>Password</label>
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  required
                  :state="loginState"
                  placeholder="Enter password"
                ></b-form-input>
                <b-form-invalid-feedback :state="loginState">{{
                  account.message
                }}</b-form-invalid-feedback>
              </b-form-group>

              <!-- ----- REMEMBER ME CHECKBOX ----- -->

              <b-form-checkbox
                id="checkbox-1"
                class="mb-3"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
                >Remember me
              </b-form-checkbox>

              <div class="form-group mb-0 text-center">
                <!-- <b-button id="button" class="btn-block" type="submit" variant="primary">Log In</b-button> -->
                <b-button
                  id="button"
                  class="btn-block userButton"
                  type="submit"
                  variant="primary"
                  >Log In</b-button
                >
              </div>
 
              <div class="text-center mt-4">
              <!-- Social Sign-in
                <p class="text-muted font-16">Sign in with</p>
                <ul class="social-list list-inline mt-3">
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item border-primary text-primary"
                      ><i class="mdi mdi-facebook"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item border-danger text-danger"
                      ><i class="mdi mdi-google"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item border-info text-info"
                      ><i class="mdi mdi-twitter"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item border-secondary text-secondary"
                      ><i class="mdi mdi-github-circle"></i
                    ></a>
                  </li>
                </ul>
               -->
              </div>

            </b-form>
            <!-- end form-->
          </span>
          <!-- end login default and is not locked out -->

          <!-- account is locked content -->
          <div v-show="isAccountLocked">
            <!-- icon with text -->
            <div class="text-center m-auto">
              <i class="uil uil-lock-alt fontLarge text-danger" />
              <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                Account Locked
              </h4>
              <p class="text-muted mb-4">
                Your account is locked.  Please contact  <a href="mailto:support@echeckpoint.com">Customer Support</a> to
                unlock your account.
              </p>
            </div>
            <!-- button -->
            <div class="form-group mb-0 text-center">
              <button
                class="btn btn-primary btn-block"
                @click="isAccountLocked = false"
              >
                Back to Login
              </button>
            </div>
          </div>
          <!-- end account is locked content -->
        </div>
        <!-- end card-body -->

        <!-- --------------------- Removed Sign Up Link at the request of Eric -------------------------- -->
        <!--
        <div v-show="!isAccountLocked">
          <footer class="footer footer-alt">
            <p class="text-muted">
              Don't have an account?
              <a href="/register" class="text-muted ml-1"><b>Sign Up</b></a>
            </p>
            <p class="text-muted">
              CONFIDENTIAL {{ new Date().getFullYear() }} RICO Technologies LLC
            </p>            
          </footer>
        </div> 
        
        <div v-show="isAccountLocked">
          <footer class="footer footer-alt">
            <p class="text-muted">
              CONFIDENTIAL {{ new Date().getFullYear() }} RICO Technologies LLC
            </p>
          </footer>
        </div>
        -->
      </div>
      <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->
    <auth-fluid-content></auth-fluid-content>
    <!-- Auth fluid right content -->

    <!-- end Auth fluid right content -->
  </div>
  <!-- end Auth fluid -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import AuthFluidContent from "../../components/login/AuthFluidContent";
import LogoContent from "../../components/login/LogoContent";

/*import '../design/assets/scss/app.scss';
import Layout from '../components/layout/default.vue';*/
// import '../design/assets/scss/app.scss';
// import '../design/assets/scss/icons.scss';
//import Layout from '../components/layout/default.vue';
//import '../assets/app-modern.scss';

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      isAccountLocked: false
    };
  },
  components: { AuthFluidContent, LogoContent },
  computed: {
    ...mapState({
      account: state => state.account
    }),
    loginFailed: function() {
      return this.account.status.loginFailed === true;
    },
    loginState: function() {
      return this.loginFailed === true ? false : null;
    }
  },
  created() {
    // reset login status
    this.logout();
    // Check if there's a remembered email
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe) 
      {
        const { email, createdOn } = JSON.parse(rememberMe);
        const now = new Date();
        const storedDate = new Date(createdOn);
        // Check if the remembered email is not older than 7 days
        if (this.isWithinSevenDays(storedDate, now)) 
        {
          this.email = email;
        } 
        else 
        {
          localStorage.removeItem("rememberMe");
        }
      }
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    handleSubmit(e) {
      this.submitted = true;
      const { email, password } = this;
      if (email && password) {
        this.login({ email, password });
      }
    },
    isWithinSevenDays(storedDate, currentDate) {
       // Difference in milliseconds between the current date and the stored date
       const differenceInMillis = currentDate.getTime() - storedDate.getTime();
      const millisecondsPerDay = 1000 * 3600 * 24; // Number of milliseconds in a day
      const daysDifference = differenceInMillis / millisecondsPerDay;
      // Check if the difference is less than or equal to 7 days
      const isWithinSevenDays = daysDifference <= 7;
      // Return the result
      return isWithinSevenDays;
    }
  }
};
</script>

<style>
/* #container {
  justify-content: center;
} */

/* #logoHeader {
    padding-left: 1.5rem;
} */

/* #button {
    -webkit-box-shadow: 0 2px 6px 0 (#4a90e2 / 50%);
    box-shadow: 0 2px 6px 0 (#4a90e2 / 50%);
    background-color: #4a90e2;
    border: #4a90e2;
} */

/* #button,#button:focus {
  background-color: #F89C43;
  border-color: #F89C43;
}

#button:active,#button:hover {
  background-color: #f1902f;
  border-color: #f1902f;
} */
</style>
