import { reportService } from "../_services";
import moment from "moment";

// sorts by property, alphabetically
function dynamicSort(property) {
  let sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}
const state = {
  orderList: {
    status: {},
    orders: null
  },
  orderDetailsList: {
    status: {},
    details: null
  },
  flowLookup: {
    status: {}, 
    flows: null
  }
};

const actions = {
  getOrderReport({ dispatch, commit }, filterObject) {
    commit("getOrderReportRequest");

    const orderFilters = Object.assign({}, filterObject);

    //------------------------------------------------------
    // Date comes into this function as a local date
    // therefore we must turn date into UTC and substract
    // the timeoffset to get the correct UTC time for filtering.
    //------------------------------------------------------
    /*if (orderFilters.startDate !== null) {debugger;
      const time = orderFilters.startDate.toDateString();
      const offset = orderFilters.startDate.getTimezoneOffset();
      const eightHours = moment.duration("00:" + offset + ":00");
      const date = moment
        .utc(time)
        .utc()
        .subtract(eightHours);
      orderFilters.startDate = date;
    }*/

    //------------------------------------------------------
    // Date comes into this function as a local date
    // therefore we must turn date into UTC and substract
    // the timeoffset, then we add one day so that
    // it includes all the orders for that day.
    //------------------------------------------------------
    /*if (orderFilters.endDate !== null) {
      const time = orderFilters.endDate.toDateString();
      const offset = orderFilters.endDate.getTimezoneOffset();
      const eightHours = moment.duration("00:" + offset + ":00");
      const date = moment
        .utc(time)
        .utc()
        .subtract(eightHours);
      const addedDate = moment(date).add(1, "days");
      orderFilters.endDate = addedDate;
    }*/


    if (orderFilters.startDate === null && orderFilters.orderID === null && orderFilters.skuID === null && orderFilters.statusID === null && orderFilters.addressTypeID === null
        && orderFilters.firstName === null && orderFilters.lastName === null && orderFilters.address === null && orderFilters.city === null && orderFilters.state === null
        && orderFilters.postalCode === null && orderFilters.flowID === null) {
      //const startDate = moment().subtract(7, 'days').format();
      const utcStartDate = moment.utc().subtract(7, 'days').format();
      orderFilters.startDate = utcStartDate;
    }

    if (orderFilters.endDate !== null) {
      const addedDate = moment(orderFilters.endDate).add(1, "days");
      orderFilters.endDate = addedDate;
    }

    reportService.getOrderReport(orderFilters).then(
      response => {
        commit("getOrderReportSuccess", response);
      },
      error => {
        commit("getOrderReportFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getOrderDetails({ dispatch, commit }, ids) {
    commit("getOrderDetailsRequest");

    reportService.getOrderReportDetails(ids).then(
      orderDetails => {
        commit("getOrderDetailsSuccess", orderDetails);
      },
      error => {
        commit("getOrderDetailsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getFlows({ dispatch, commit }) {
    commit("getFlowsRequest");

    reportService.getOrderReportFlows().then(
      flows => {
        commit("getFlowsSuccess", flows);
      },
      error => {
        commit("getFlowsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  }
};

const mutations = {
  getOrderReportRequest(state) {
    state.orderList.status = { reportLoading: true };
  },
  getOrderReportSuccess(state, response) {
    for (const order of response.data) {
      order.shipToAddresses = [];
      order.billToAddresses = [];
      for (const address of order.addresses) {
        if (address.addressType === "Shipping") {
          order.shipToAddresses.push(address);
        } else if (address.addressType === "Billing") {
          order.billToAddresses.push(address);
        }
      }
    }
    //     const addresses = order.address.split(';');
    //     for (const address of addresses) {
    //         const addressSplit = address.split(',');
    //         const addressObject = {
    //             addressType: addressSplit[0],
    //             firstName: addressSplit[1],
    //             lastName: addressSplit[2],
    //             address1: addressSplit[3],
    //             address2: addressSplit[4],
    //             city: addressSplit[5],
    //             addressState: addressSplit[6],
    //             postalCode: addressSplit[7]
    //         }
    //         // middleName: addressSplit[2],
    //         if (addressObject.addressType === 'Shipping') {
    //             order.shipToAddresses.push(addressObject)
    //         }
    //         else if (addressObject.addressType === 'Billing') {
    //             order.billToAddresses.push(addressObject)
    //         }
    //     }
    // }
    state.orderList.orders = response.data;
    state.orderList.orderCount = response.listCount;
    state.orderList.status = { reportloaded: true };
  },
  getOrderReportFailure(state, error) {
    state.orderList.orders = error;
  },
  getOrderDetailsRequest(state) {
    state.orderDetailsList.status = { detailsLoading: true };
  },
  getOrderDetailsSuccess(state, details) {

    let descList = [];
    const newList = [];
    let counter = 0;
    let descIndex = [];
    let isNextItem = false;
    details.forEach(function(item,index){
        if(item.sortOrder != null && item.sortOrder > 0)
        {   

          if(item.sortOrder == 1 && (isNextItem))
          {
            descIndex.forEach(function(item, index) {
              const newDescList = [];
              const newDesc = descList.filter(x => x[0] == item[0]);
              newDesc.forEach(function(i, idx) {
                newDescList.push([i[1], item[2]]);
              });

              const descriptions = [];
              newDescList.forEach(function(i, idx){
                descriptions.push(i[0]);
              });
              newList.find(x => x.id == item[2]).description = descriptions.join("<br>");
            });
            
            isNextItem = false;
            descList = [];
            descIndex = [];
          }
          let desc = item.description;

          if(item.sequenceOrder != null && item.sequenceOrder != "")
            desc = item.sequenceOrder == 0.1 ?  "<br>" + item.description + "<br>" //keyword AND/OR
                      : item.sequenceOrder == 0.2 ? "<i>" + item.description + "</i>" //italic ALL/ANY text
                      : item.sequenceOrder == 0.3 ? "<br><i>" + item.description + "</i>"
                      : item.sequenceOrder == 0.4 ? item.description //main text
                      : item.sequenceOrder == 0.5 ? item.description.includes("NOT") || item.description.includes("not") ? "<b><span class='fflCheckFail'><i class='uil uil-shield-slash text-danger font-20'></i> " + item.description  + "</span></b>"
                                                    : "<b><span class='fflCheck'><i class='uil uil-shield-check text-success font-20'></i> " + item.description  + "</span></b>"
                      : item.sequenceOrder == 0.6 ? "<span class='fflCheck'>" + item.description + "</span>" //FFL text
                      : item.sequenceOrder == 0.7 ? "<br><span class='businesstradetitle'><b>" + item.description + "</b></span>" //business trade type text
                      : item.sequenceOrder == 0.8 ? "<span class='addlrequirementmet text-success'><b><i class='uil uil-shield-check text-success font-20'></i> " + item.description + "</b></span>" //addl requirement met
                      : item.sequenceOrder == 0.9 ? "<span class='addlFflLicenserequirementnotmet'><b><i class='uil uil-shield-slash text-danger font-20'></i> " + item.description + "</b></span>" //addl ffl license requirement not met
                      : parseInt(item.sequenceOrder) === item.sequenceOrder ? "<b>" + item.sequenceOrder + ") " + item.description + "</b>" 
                      : "<span class='exemptionbullet'>&bull; " + item.description + "</span>";

          descList.push([item.orderID, desc, item.id]);
        }
        if((item.sortOrder != null && item.sortOrder == 1) || item.sortOrder == null)
        {
            
            newList.push(item);
            if(item.sortOrder != null && item.sortOrder == 1)
            {
                descIndex.push([item.orderID, counter, item.id]);
                isNextItem = true;
            }
            counter++;
        }
        
    });

    descIndex.forEach(function(item, index) {
      const newDescList = [];
      const newDesc = descList.filter(x => x[0] == item[0]);
      newDesc.forEach(function(i, idx) {
        newDescList.push([i[1], item[2]]);
      });
      
      const descriptions = [];
      newDescList.forEach(function(i, idx){
        descriptions.push(i[0]);
      });
      newList.find(x => x.id == item[2]).description = descriptions.join("<br>");
    });

    state.orderDetailsList.details = newList;
    state.orderDetailsList.status = { detailsloaded: true };
  },
  getOrderDetailsFailure(state, error) {
    state.orderDetailsList.details = error;
  },
  getFlowsRequest(state) {
    state.flowLookup.status = { flowsLoading: true };
  },
  getFlowsSuccess(state, flows) {
    flows.unshift({ id: null, flowName: "All", description: "" });
    state.flowLookup.flows = flows.sort(dynamicSort("flowName"));
    state.flowLookup.status = { flowsloaded: true };
  },
  getFlowsFailure(state, error) {
    state.flowLookup.flows = error;
  }
};

export const report = {
  namespaced: true,
  state,
  actions,
  mutations
};
