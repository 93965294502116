import { settingsService } from "../_services";

const state = {
    settings:
    {
        Domains: [],
        Flows: {},
        PassIds:{},
    },
    iframeSettings:
    {
      Style: {},
      Name: "null",
      Domain: "null"
    },
    Flows: {},
    ValidFlows:[],
    BCSettings: {
        Hash: "",
        BCAccessToken: "",
        SubscriberServiceFlowIDPreCheckout: null,
        SubscriberServiceFlowIDPostCheckout: null,
        OrderStatusUpdate: false,
        OrderComment: false,
        DisplayCondlFail: false,
        PreCheckoutEnabled: false,
        Fee: {
            Enabled: false,
            Minimum: 0,
            Maximum: 0,
            Percent: 0,
            CountDuplicates: 0,
        }
    },
    Profile: {},
    Status: {
      getIframeSettingsURLLoading: null,
      getIframeSettingsLoading: null,
      updateIframeSettingsLoading: null,
      deleteIframeSettingsLoading: null,
      getOptionSettingsRequestLoading: null,
      addOptionRequestLoading: null,
      deleteOptionRequestLoading: null,
      getSubFlowsRequestLoading: null,
      updateBigCommerceRequestLoading: null,
      getBigCommerceRequestLoading: null,
      getProfileSettingsRequestLoading: null,
      updateProfileSettingsRequestLoading: null,
    },
    Error: {}
};

const actions = {
  getIframeSettings({ dispatch, commit })
  {
    commit("getIframeSettingsRequest");
    
    settingsService.getIframeSettings().then(
      response => {
        commit("getIframeSettingsSuccess", response);
      },
      error => {
        commit("getIframeSettingsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  updateIframeSettings({ dispatch, commit }, newSetting)
  {
    commit("updateIframeSettingsRequest");
    
    settingsService.updateIframeSettings(newSetting).then(
      response => {
        commit("updateIframeSettingsSuccess");
        dispatch("getIframeSettings");
      },
      error => {
        commit("updateIframeSettingsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  deleteIframeSettings({ dispatch, commit }, newSetting)
  {
    commit("deleteIframeSettingsRequest");
    
    settingsService.deleteIframeSettings(newSetting).then(
      response => {
        commit("deleteIframeSettingsSuccess");
        dispatch("getIframeSettings");
      },
      error => {
        commit("deleteIframeSettingsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  
  async GetScripts({dispatch, commit}, settings)
  {
    debugger;
    return await settingsService.GetScripts(settings)
  },
  async AddScript({dispatch, commit}, settings)
  {
    debugger;
    return await settingsService.AddScript(settings)
  },
  async UpdateScript({dispatch, commit}, settingsAndUUID)
  {
    debugger;
    return await settingsService.UpdateScript(settingsAndUUID)
  },
  async DeleteScript({dispatch, commit}, settingsAndUUID)
  {
    debugger;
    return await settingsService.DeleteScript(settingsAndUUID)
  },
  
  getSubFlows({ dispatch, commit })
  {
    
    commit("getSubFlowsRequest");
    //commit("getOptionSettingsRequest");
    
    settingsService.getSubFlows().then(
      response => {
        debugger;
        commit("getSubFlowsSuccess", response);
      },
      error => {
        commit("getSubFlowsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getOptionSettings({ dispatch, commit })
  {
    commit("getOptionSettingsRequest");
    
    settingsService.getOptionSettings().then(
      response => {
        commit("getOptionSettingsSuccess", response);
      },
      error => {
        commit("getOptionSettingsFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  addOption({ dispatch, commit }, newSetting)
  {
    commit("addOptionRequest");
    
    settingsService.addOption(newSetting).then(
      response => {
        commit("addOptionSuccess");
        dispatch("getOptionSettings");
      },
      error => {
        commit("addOptionFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  deleteOption({ dispatch, commit }, newSetting)
  {
    commit("deleteOptionRequest");
    
    settingsService.deleteOption(newSetting).then(
      response => {
        commit("deleteOptionSuccess");
        dispatch("getOptionSettings");
      },
      error => {
        commit("deleteOptionFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  //BigCommerce
  updateBigCommerce({ dispatch, commit }, newSetting)
  {
    commit("updateBigCommerceRequest");
    
    settingsService.updateBigCommerce(newSetting).then(
      response => {
        commit("updateBigCommerceSuccess", response);
      },
      error => {
        commit("updateBigCommerceFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  getBigCommerce({ dispatch, commit }, hash)
  {
    commit("getBigCommerceRequest");
    
    settingsService.getBigCommerce(hash).then(
      response => {
        commit("getBigCommerceSuccess", response);
      },
      error => {
        commit("getBigCommerceFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

    //profile
    async getProfileSettings({ dispatch, commit })
    {
        commit("getProfileSettingsRequest");
        
        await settingsService.getProfileSettings().then(
        response => {
            commit("getProfileSettingsSuccess", response);
        },
        error => {
            commit("getProfileSettingsFailure", error);
            dispatch("alert/error", error, { root: true });
        }
        );
    },

    async updateProfileSettings({ dispatch, commit }, settings)
    {
        commit("updateProfileSettingsRequest");
        
        await settingsService.updateProfileSettings(settings).then(
        response => {
            commit("updateProfileSettingsSuccess", response);
        },
        error => {
            commit("updateProfileSettingsFailure", error);
            dispatch("alert/error", error, { root: true });
        }
        );
    },

  //get settings by url
  getIframeSettingsByURL({ dispatch, commit }, url)
  {
    commit("getIframeSettingsURLRequest");
    
    settingsService.getIframeSettingsByURL(url).then(
      response => {
        commit("getIframeSettingsURLSuccess", response);
      },
      error => {
        commit("getIframeSettingsURLFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
};

const mutations = 
{//CALLS
  //iframe
  getIframeSettingsRequest(state)
  {
    state.Status.getIframeSettingsLoading = true;
  },
  getIframeSettingsSuccess(state, response) 
  {
    state.settings.Domains = [];
    for(const record of response)
    {
      state.settings.Domains.push({Id: record.id, Name: record.name, Style: record.style, Url: record.domain, ServiceFlowID: record.serviceFlowID, CustomerServiceEmail: record.customerServiceEmail, CustomerServiceEmailFrom: record.customerServiceEmailFrom});
    }
    state.Status.getIframeSettingsLoading = false;
  },
  getIframeSettingsFailure(state, error) 
  {
    state.Status.getIframeSettingsLoading = false;
    state.Error = { error: error }
  },

  updateIframeSettingsRequest(state)
  {
    state.Status.updateIframeSettingsLoading = true;
  },
  updateIframeSettingsSuccess(state)
  {
    state.Status.updateIframeSettingsLoading = false;
  },
  updateIframeSettingsFailure(state, error)
  {
    state.Status.updateIframeSettingsLoading = false;
  },

  deleteIframeSettingsRequest(state)
  {
    state.Status.deleteIframeSettingsLoading = true;
  },
  deleteIframeSettingsSuccess(state)
  {
    state.Status.deleteIframeSettingsLoading = false;
  },
  deleteIframeSettingsFailure(state, error)
  {
    state.Status.deleteIframeSettingsLoading = false;
  },

  getSubFlowsRequest(state)
  {
    state.Status['getSubFlowsRequestLoading'] = true;
  },
  getSubFlowsSuccess(state, response)
  {
    state.Flows = {};
    for(const record of response)
    {
      debugger;
      state.Flows[record.id + ""] = {Id: record.id, Name: record.flowName, Description: record.description};
    }
    state.Status['getSubFlowsRequestLoading'] = false;
  },
  getSubFlowsFailure(state)
  {
    state.Status['getSubFlowsRequestLoading'] = false;
  },

  getOptionSettingsRequest(state)
  {
    state.Status['getOptionSettingsRequestLoading'] = true;
  },
  getOptionSettingsSuccess(state, response)
  {
    state.settings.Flows = {};
    for(const flowId in response.flows)
    {
      const flow = response.flows[flowId];
      if(!state.settings.Flows[flow.id])
      state.settings.Flows[flow.id] = {ID: flow.id, Name: flow.name, Description: flow.description, FlowID: flow.flowId, Services: flow.services};
    }

    state.Status['getOptionSettingsRequestLoading'] = false;
  },
  getOptionSettingsFailure(state)
  {
    state.Status['getOptionSettingsRequestLoading'] = false;
  },

  addOptionRequest(state)
  {
    state.Status.addOptionRequestLoading = true;
  },
  addOptionSuccess(state)
  {
    state.Status.addOptionRequestLoading = false;
  },
  addOptionFailure(state)
  {
    state.Status.addOptionRequestLoading = false;
  },

  deleteOptionRequest(state)
  {
    state.Status.deleteOptionRequestLoading = true;
  },
  deleteOptionSuccess(state)
  {
    state.Status.deleteOptionRequestLoading = false;
  },
  deleteOptionFailure(state)
  {
    state.Status.deleteOptionRequestLoading = false;
  },

  //BigCommerce
  updateBigCommerceRequest(state)
  {
    state.Status.updateBigCommerceRequestLoading = true;
  },
  updateBigCommerceSuccess(state)
  {
    state.Status.updateBigCommerceRequestLoading = false;
  },
  updateBigCommerceFailure(state)
  {
    state.Status.updateBigCommerceRequestLoading = false;
  },

  getBigCommerceRequest(state)
  {
    state.Status.getBigCommerceRequestLoading = true;
  },
  getBigCommerceSuccess(state, response)
  {
    state.BCSettings.Hash = response.hash;
    state.BCSettings.BCAccessToken = response.bcAccessToken;
    state.BCSettings.SubscriberServiceFlowIDPreCheckout = response.subscriberServiceFlowIDPreCheckout;
    state.BCSettings.SubscriberServiceFlowIDPostCheckout = response.subscriberServiceFlowIDPostCheckout;
    state.BCSettings.OrderStatusUpdate = response.orderStatusUpdate;
    state.BCSettings.OrderComment = response.orderComment;
    state.BCSettings.PreCheckoutEnabled = response.preCheckoutEnabled;
    state.BCSettings.DisplayCondlFail = response.displayCondlFail;
    state.BCSettings.Fee.Enabled = response.fee.enabled
    state.BCSettings.Fee.Minimum = response.fee.minimum
    state.BCSettings.Fee.Maximum = response.fee.maximum
    state.BCSettings.Fee.Percent = response.fee.percent
    state.BCSettings.Fee.CountDuplicates = response.fee.countDuplicates
    state.Status.getBigCommerceRequestLoading = false;
  },
  getBigCommerceFailure(state)
  {
    state.Status.getBigCommerceRequestLoading = false;
  },

  //profile
  getProfileSettingsRequest(state)
  {
    state.Status.getProfileSettingsRequestLoading = true;
  },
  getProfileSettingsSuccess(state, response)
  {
    state.Profile = response;
    state.Status.getProfileSettingsRequestLoading = false;
  },
  getProfileSettingsFailure(state, error)
  {
    state.Status.getProfileSettingsRequestLoading = false;
    console.log(error)
  },

  updateProfileSettingsRequest(state)
  {
    state.Status.updateProfileSettingsRequestLoading = true;
  },
  updateProfileSettingsSuccess(state, response)
  {
    state.Status.updateProfileSettingsRequestLoading = false;
  },
  updateProfileSettingsFailure(state, error)
  {
    state.Status.updateProfileSettingsRequestLoading = false;
    console.log(error)
  },

  //get settings for URL
  getIframeSettingsURLRequest(state)
  {
    state.Status.getIframeSettingsURLLoading = true;
  },
  getIframeSettingsURLSuccess(state, response) 
  {
    state.iframeSettings = {Style: response.style, Name: response.name, Domain: response.domain};
    state.Status.getIframeSettingsURLLoading = false;
  },
  getIframeSettingsURLFailure(state, error) 
  {
    debugger;
    state.Status.getIframeSettingsURLLoading = false;
    console.log(error);
    state.Error = { error: error }
  },
};

export const settings = 
{
  namespaced: true,
  state,
  actions,
  mutations
};