<script>
// import { layoutComputed } from '@state/helpers'

import Horizontal from "./horizontal";
// import Vertical from '@router/layouts/vertical'
// import Detached from '@router/layouts/detached'

export default {
  data() {
    return {
      layoutType: "horizontal"
    };
  },
  // , Vertical, Detached
  components: { Horizontal }
  // computed: {
  //   ...layoutComputed,
  // },
};
</script>

<template>
  <div>
    <!-- <vertical v-if="this.layoutType === 'vertical'" :layout="this.layoutType">
      <slot />
    </vertical> -->

    <Horizontal
      v-if="this.layoutType === 'horizontal'"
      :layout="this.layoutType"
    >
      <slot />
    </Horizontal>

    <!-- <Detached v-if="this.layoutType === 'detached'" :layout="this.layoutType">
      <slot />
    </Detached> -->
  </div>
</template>
