<template>
    <div>
        <!--Add new Modal-->
        <b-modal id="modalAdd" title-class="modalTitle" title="Add New Domain" @hidden="CancelDomain()" centered hide-footer no-fade :visible="flags === 'add'" v-if="Flows !== {}">
            <div class="card" id="reportCard">
                <div class="card-body">
                    <form class="edits" ref="addModal">
                        <label for="domainNameAdd" class="d-block">Name:</label>
                        <input id="domainNameAdd" class="form-control" type="text" required v-model="tempDomain.Name"><br/>
                        <label for="domainUrlAdd" class="d-block" >URL:</label>
                        <input id="domainUrlAdd" class="form-control" type="text" required v-model="tempDomain.Url"><br/>
                        <label for="domainEmailAdd" class="d-block" >Customer Service Email:</label>
                        <input id="domainEmailAdd" class="form-control" type="email" required v-model="tempDomain.CustomerServiceEmail"><br/>
                        <label for="domainFromAdd" class="d-block" >From:</label>
                        <input id="domainFromAdd" class="form-control" type="text" required v-model="tempDomain.CustomerServiceEmailFrom"><br/>
                        <label for="domainServAdd" class="d-block" >Checkpoint:</label>
                        <select id="domainServAdd" class="form-control" required v-model="tempDomain.ServiceFlowID">
                            <template v-for="flow in Flows"><option :key="flow.Id" :value="flow.Id" v-if="Unused(flow.Id, tempDomain.ServiceFlowID, 'A')">{{ flow.Name}}</option></template>
                        </select><br/>
                        <label for="domainStyleAdd" class="d-block" >Styles:</label>
                        <!-- <input class="form-control" id="domainColorEdit" type="color" name="color" v-model="tempDomain.Color"> -->
                        <textarea id="domainStyleAdd" class="form-control" v-model="tempDomain.Style"></textarea>
                    </form>
                </div>
            </div>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="CancelDomain()">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="CommitDomain('addModal')">Submit</b-button>
                </div>
            </div>
        </b-modal>

        <!--edit modal-->
        <b-modal id="modalEdit" title-class="modalTitle" title="Edit a Domain" @hidden="CancelDomain()" centered hide-footer no-fade :visible="flags === 'edit'" v-if="Flows !== {}">
            <div class="card" id="reportCard">
                <div class="card-body">
                    <form class="edits" ref="editModal">
                        <label for="domainNameEdit" class="d-block" >Name:</label>
                        <input id="domainNameEdit" class="form-control" type="text" required v-model="tempDomain.Name"><br/>
                        <label for="domainUrlEdit" class="d-block" >URL:</label>
                        <input id="domainUrlEdit" class="form-control" type="text" required v-model="tempDomain.Url"><br/>
                        <label for="domainEmailEdit" class="d-block" >Customer Service Email:</label>
                        <input id="domainEmailEdit" class="form-control" type="email" required v-model="tempDomain.CustomerServiceEmail"><br/>
                        <label for="domainFromEdit" class="d-block" >From:</label>
                        <input id="domainFromEdit" class="form-control" type="text" required v-model="tempDomain.CustomerServiceEmailFrom"><br/>
                        <label for="domainServEdit" class="d-block" >Checkpoint:</label>
                        <select id="domainServEdit" class="form-control" required v-model="tempDomain.ServiceFlowID">
                            <template v-for="flow in Flows"><option :key="flow.Id" :value="flow.Id" v-if="Unused(flow.Id, tempDomain.ServiceFlowID, 'E')">{{ flow.Name }}</option></template>
                        </select><br/>
                        <label for="domainStyleEdit" class="d-block" >Style:</label>
                        <!-- <input class="form-control" id="domainColorEdit" type="color" name="color" v-model="tempDomain.Color"> -->
                        <textarea id="domainStyleEdit" class="form-control" v-model="tempDomain.Style"></textarea>
                    </form>
                </div>
            </div>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="CancelDomain()">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="CommitDomain('editModal')">Submit</b-button>
                </div>
            </div>
        </b-modal>

        <!--delete modal-->
        <b-modal id="modaledit" title-class="modalTitle" title="Deleting a Domain" @hidden="CancelDomain()" centered hide-footer no-fade :visible="flags === 'delete'">
            <p>You are about to delete {{ deleteDomain.Name }}. You will not be able to undo this action. Are you sure?</p>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="row mb-2 float-right">
                <div class="col pr-0">
                    <b-button class="float-right echeckpointBlue modalBtn" size="sm" @click="CancelDomain()">Cancel</b-button>
                </div>
                <div class="col-auto">
                    <b-button type="button" class="float-right userButton modalBtn" size="sm" @click="DeleteDomain()">Confirm</b-button>
                </div>
            </div>
        </b-modal>

        <h4 class="group-title">Credentialing</h4>
        <p class="description">Choose the site you would like to get credentials from and customize the appearance of the iframe.</p>
        <!--iframe settings-->
        <div class="groupSet card">
            <h4 class="header-title">Iframe Settings</h4>
            <p></p>
            <table class="table table-centered mb-0">
                <thead >
                    <tr>
                        <th>Domain Name</th>
                        <!-- <th>Background Color</th> -->
                        <th>Domain URL</th>
                        <th>Email</th>
                        <th>From</th>
                        <th>Checkpoint</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="domain in $store.state.settings.settings.Domains" :key="domain.Id">
                        <td class="tableText">{{ domain.Name }}</td>
                        <!-- <td class="ColorCell"><span class="colorCircle" :style="{backgroundColor:domain.Color}" :title="domain.Color"></span></td> -->
                        <td class="tableText">{{ domain.Url }}</td>
                        <td class="tableText">{{ domain.CustomerServiceEmail }}</td>
                        <td class="tableText">{{ domain.CustomerServiceEmailFrom }}</td>
                        <td><span v-if="settings.Status.getSubFlowsRequestLoading === false">{{ Flows[domain.ServiceFlowID].Name}}</span></td>
                        <td>
                            <button class="btnContainer" @click="EditDomain(domain)" title="Edit" :disabled="flags !== ''"><i class="mdi mdi-pencil"></i></button>
                            <button class="btnContainer" @click="ConfirmDeleteDomain(domain)" title="Delete" :disabled="flags !== ''"><i class="mdi mdi-delete"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5"></td>
                        <td><button class="btnContainer" v-b-modal.modalAdd @click="AddDomain()"  title="Add New" :disabled="flags !== ''"><i class="mdi mdi-plus-circle"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="groupSet no-flex card"><!--Iframe HTML for copying-->
            <h4 class="header-title">Iframe Component</h4>
            <p>Copy and paste this where you want the verification module to appear.</p>
            <div class="row-flex">
                <pre id="iframeHtml">
&lt;script src="https://cdn.echeckpoint.com/9.0.86zoid.min.js" &GT;&LT;/script&GT;
&LT;script src="https://cdn.echeckpoint.com/echeckpointIframe.js" &GT;&LT;/script&GT;
&LT;div id="verification" style="overflow:hidden !important;"&GT;&LT;/div&GT;</pre>
                <button class="CopyBtn btnContainer" @click="Copy('iframeHtml')"  title="Copy to Clipboard"><i class="mdi mdi-content-copy"></i></button> 
            </div>
        </div>
    </div>
</template>

<script>
import appConfig from "@/app.config";
import { mapState, mapActions } from "vuex";

export default {
    name: "IframePage",
    page: {
        title: "iframe",
        meta: [{ name: "description", content: appConfig.description }]
    },
    created() 
    {
        this.getIframeSettings();
        this.getSubFlows();
    },
    data() 
    {   
        return {
            tempDomain: 
            {
                Id: null,
                Style: null,
                Url: null,
                Name: null,
                ServiceFlowID: null,
                CustomerServiceEmail: null,
                CustomerServiceEmailFrom: null
            },
            deleteDomain:
            {
                Id: null,
                Style: null,
                Url: null,
                Name: null,
                ServiceFlowID: null,
                CustomerServiceEmail: null,
                CustomerServiceEmailFrom: null
            },
            flags: ""
        }
    },
    computed:{
        ...mapState({settings: state => state.settings}),
        ...mapState("settings", ["Flows"])
    },
    methods:{
        ...mapActions("settings", ["getIframeSettings", "updateIframeSettings", "deleteIframeSettings", "getSubFlows"]),
        CloseModal(modalName)
        {
            debugger;
            this.$bvModal.hide(modalName);
        },
        AddDomain()
        {   
            this.CancelDomain();
            this.flags = "add"
        },
        CancelDomain()
        {
            this.tempDomain.Name = null;
            this.tempDomain.Style = "";
            this.tempDomain.Url = null;
            this.tempDomain.Id = null;
            this.tempDomain.CustomerServiceEmail = null;
            this.tempDomain.CustomerServiceEmailFrom = null;

            this.deleteDomain.Name = null;
            this.deleteDomain.Style = null;
            this.deleteDomain.Url = null;
            this.deleteDomain.Id = null;
            this.deleteDomain.CustomerServiceEmail = null;
            this.deleteDomain.CustomerServiceEmailFrom = null;
            this.flags = "";
        },
        EditDomain(domain)
        {debugger;
            this.tempDomain.Name = domain.Name;
            this.tempDomain.Style = domain.Style;
            this.tempDomain.Url = domain.Url;
            this.tempDomain.Id = domain.Id;
            this.tempDomain.ServiceFlowID = domain.ServiceFlowID;
            this.tempDomain.CustomerServiceEmail = domain.CustomerServiceEmail;
            this.tempDomain.CustomerServiceEmailFrom = domain.CustomerServiceEmailFrom;
            this.flags = "edit";
        },
        CommitDomain(name)
        {
            const passSetting = {Id: this.tempDomain.Id, Style: this.tempDomain.Style, Name: this.tempDomain.Name, Domain: this.tempDomain.Url, ServiceFlowID: this.tempDomain.ServiceFlowID, CustomerServiceEmail: this.tempDomain.CustomerServiceEmail, CustomerServiceEmailFrom: this.tempDomain.CustomerServiceEmailFrom};
            console.log(passSetting);

            debugger;

            if(passSetting.Id === null)
                passSetting.Id = -1;
            const checkEmail = this.validateEmail(passSetting.CustomerServiceEmail);
            if(this.$refs[name].checkValidity() && checkEmail)
            {
                this.updateIframeSettings(passSetting);
                this.CancelDomain();
            }
            else
            {
                for(const child of this.$refs[name].children)
                {
                    child.classList.remove("needed");
                    if(!child.value && (child.nodeName == "INPUT" || child.nodeName == "SELECT"))
                    {
                        child.classList.add("needed");
                    }
                    if(child.value == passSetting.CustomerServiceEmail && !checkEmail && (child.nodeName == "INPUT"))
                    {
                        child.classList.add("needed"); 
                    }
                }
            }
        },
        ConfirmDeleteDomain(domain)
        {
            this.deleteDomain = {Id: domain.Id, Style: domain.Style, Name: domain.Name, Domain: domain.Url};
            this.flags = "delete";
        },
        DeleteDomain()
        {
            this.deleteIframeSettings({Id: this.deleteDomain.Id, Style: this.deleteDomain.Style, Name: this.deleteDomain.Name, Domain: this.deleteDomain.Url});
            this.flags = "";
        },
        SelectedUrl()
        {
            const index = document.getElementById("domainDrop").value  - 1;
            if(this.$store.state.iframe.Domains)
            {
                if(index > 0)
                    {return this.$store.state.iframe.Domains[index].url;}
                return this.$store.state.iframe.Domains[0].url;
            }
            return null;
        },
        validateEmail(email) {
            const emailRegex =
                new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[^0-9_!#$%&'*+/=?`{|}~^.-]{2}[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]*/, "gm");
            const isValidEmail = emailRegex.test(email);
            return isValidEmail;
        },
        Unused(id, currentID, mode)
        {
            debugger;
            if(this.$store.state.settings.settings.Domains.find((x) => x.ServiceFlowID === id))
            {
                if(mode === 'E' && currentID === id)
                    return true;
                return false;
            }
            else
                return true;
        },
        Copy(name)
        {
            const string = document.getElementById(name).innerText
            navigator.clipboard.writeText(string)
        }
    }
}
</script>

<style lang="scss" scoped>
.edits
{
    button
    {
        margin: 0 4px 0 8px;
    }
}

thead
{
    :first-child
    {
        width: 200px;
        border-top: none;
    }
    :nth-child(2)
    {
        width:max-content;
        border-top: none;
    }
    :nth-child(3)
    {
        width: 250px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(4)
    {
        width: 150px;
        padding-left: 15px;
        border-top: none;
    }
    :nth-child(5)
    {
        width: 250px;
        padding-left: 0;
        border-top: none;
    }
    :nth-child(6)
    {
        width: 75px;
        padding-left: .5em;
        border-top: none;
        text-align: center;
    }
}

td 
{
    padding: 0;
}

.ColorCell
{
    text-align: center;
}

tbody:last-child
{   
    tr
    {
        border-bottom:none;
    }
}

.tableText
{
    font-size: 12pt;
    padding-left: 15px;
}

.needed
{
    outline: 4px solid #FF6666;
}

.row-flex
{
    display: flex;
    flex-direction: row;
}

pre
{
    white-space: pre;
    margin-bottom: .25em;
    width: fit-content;
    display: flex;
}

.CopyBtn
{
    display:flex;
    height: 1.8em;
    margin-left: 1em !important;
}
</style>