<template>
  <simplebar :settings="settings" class="tagList">
    <ul id="menu-bar" class="metismenu side-nav">
      <template v-for="item in productDesignation.productDesignationsList.categories.values()">
        <!-- <template v-if="item.header && mode !== 'horizontal'">
          <li 
            :key="`item-${item.name}-header`"
            class="side-nav-title side-nav-item"
          >
            {{ item.header }}
          </li>
        </template>-->

        <li :key="`item-${item.productCategoryID}`" class="side-nav-item">
          <template v-if="hasItems(item)">
            <a href="javascript:void(0);" class="side-nav-link has-arrow" aria-expanded="false"
              @click="toggleDropdown(item.productCategoryID)">
              <!--<a href="javascript:void(0);" class="side-nav-link has-arrow action-icon">
              <i v-if="hasItems(item)" 
                class="uil expandIcon"
                @click="toggleDropdown(item.productCategoryID)"></i>-->

              <!-- <i
                v-if="item.icon"
                :class="item.icon"
              ></i> -->
              <!-- <i
                class="uil uil-crosshair-alt"
              ></i> -->
              <span>{{ item.productCategory }}</span>
            </a>

            <ul :ref="`item-${item.productCategoryID}`" class="side-nav-second-level mm-collapse">
              <!-- <draggable :sort="false" v-model="item.children" @start="drag=true" @end="drag=false"> -->
              <li v-for="subitem in item.children" :key="`sub-item-${subitem.id}`"
                class="side-nav-item badge badge-secondary-lighten grabbable"
                :class="{ 'activeFilteredTag': filteredTag !== null && subitem.id === filteredTag }" :id="subitem.id"
                draggable="true" @dragover.prevent @dragstart="dragStartHandler($event)"
                @drop="dropHandler($event, subitem);" @click="onTagClick(subitem)">
                <template v-if="hasItems(subitem)">
                  <a href="javascript:void(0);" class="side-nav-link-ref has-arrow side-sub-nav-link">
                    {{ subitem.name }}
                  </a>
                  <ul class="
                      side-nav-third-level" aria-expanded="false">
                    <li v-for="subSubitem in subitem.children" :key="`sub-sub-item-${subSubitem.id}`">

                      <template v-if="hasItems(subSubitem)">
                        <a href="javascript:void(0);" class="side-nav-link-ref has-arrow side-sub-nav-link">
                          {{ subSubitem.name }}
                        </a>

                        <ul class="side-nav-forth-level" aria-expanded="false">
                          <li v-for="subSubitemChild in subSubitem.children"
                            :key="`sub-sub-sub-item-${subSubitemChild.id}`">
                            <!-- <router-link
                              tag="a"
                              class="side-nav-link-ref side-sub-nav-link"
                            >{{ subSubitemChild.name }}</router-link> -->
                            <a class="side-nav-link-ref side-sub-nav-link">{{ subSubitemChild.name }}</a>
                          </li>
                        </ul>
                      </template>

                      <template v-else>
                        <!-- <router-link
                          tag="a"
                          class="side-sub-nav-link side-nav-link-ref"
                        >{{ subSubitem.name }}</router-link> -->
                        <a class="side-nav-link-ref side-sub-nav-link">{{ subSubitem.name }}</a>
                      </template>
                    </li>
                  </ul>
                </template>

                <template v-else>
                  <!-- <router-link
                    tag="a"
                    :to="``"
                    class="side-sub-nav-link side-nav-link-ref"
                  >{{ subitem.designation }}</router-link> -->
                  <a class="side-nav-link-ref side-sub-nav-link">{{ subitem.designation }}
                  </a>
                  <i class="badge-grip mdi mdi-drag"></i>
                </template>
              </li>
              <!-- </draggable> -->
            </ul>

          </template>
        </li>
      </template>
    </ul>
  </simplebar>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MetisMenu from 'metismenujs/dist/metismenujs'
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
// import draggable from 'vuedraggable'

export default {
  name: "TagFilter",
  components: {
    simplebar,
    // draggable
  },
  props: [
    "passedTags",
    "currentFilteredTag",
    "tagMatchAnyDefault",
    "tagsShowNoneOnlyDefault"
  ],
  computed: {
    ...mapState({
      account: state => state.account,
      product: state => state.product,
      productDesignation: state => state.productDesignation
    }),
    filteredCategories() {
      if (this.filterText.length === 0) {
        const filteredCategories = this.tags.filter(tag => {
          return tag.type === "Category";
        });
        return filteredCategories.length > 0
          ? filteredCategories
          : [{ name: "No matching tags" }];
      } else {
        return this.filteredTags;
      }
    },
    filteredTags() {
      if (this.filterText.length > 0) {
        const filteredTags = this.tags.filter(tag => {
          return String(tag.name)
            .toLowerCase()
            .includes(String(this.filterText).toLowerCase());
        });
        return filteredTags.length > 0
          ? filteredTags
          : [{ name: "No matching tags" }];
      } else {
        //If no current search
        const filteredTags = this.tags.filter(tag => {
          return String(tag.name)
            .toLowerCase()
            .includes(String(this.filterText).toLowerCase());
        });
        return filteredTags.length > 0
          ? filteredTags
          : [{ name: "No matching tags" }];
      }
    },
    tagFilter() {
      debugger;
      if (this.productDesignation.productDesignationsList.designations) {
        return this.productDesignation.productDesignationsList.designations.filter(tag => tag.filtered);
      }
      return []
    }
  },
  mounted: function () {
    this.menuRef = new MetisMenu('#menu-bar')

    const activeClass = 'mm-active';
    const dropdownActiveClass = 'mm-show';
    this.activateMenuItems(activeClass, dropdownActiveClass)
  },
  created() {
    this.$emit("filtered", this.tagFilter);
    this.activateTheme('light');
  },
  watch: {
    tagFilter() {
      this.$emit("filtered", this.tagFilter);
    },
    tagMatchAny() {
      this.$emit("tagMatchAnyToggled", this.tagMatchAny);
    },
    tagMatchNoneOnly() {
      this.$emit("tagNoneOnlyToggled", this.tagMatchNoneOnly);
    },
    'productDesignation.productDesignationsList.status.designationsLoaded': function (newVal, oldVal) {
      if (newVal !== undefined) {
        this.$nextTick(function () {
          const activeClass = 'mm-active';
          const dropdownActiveClass = 'mm-show';
          this.activateMenuItems(activeClass, dropdownActiveClass)
        })
      }
    },
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      filterText: "",
      categories: [
        {
          id: 2,
          name: "Magazine",
          filtered: false,
          expanded: false,
          type: "Category",
          groups: {
            capacity: [4, 5, 6],
            shape: [1, 2, 3],
            compatibility: [7, 8],
            material: [9, 10]
          }
        },
        {
          id: 3,
          name: "Firearm",
          type: "Category",
          groups: {
            type: [11, 12],
            color: [13, 14]
          },
          expanded: true,
          filtered: false
        }
      ],
      tags: this.passedTags,
      tagSelected: this.currentFilteredTag,
      tagMatchAny: true,
      tagMatchNoneOnly: this.tagsShowNoneOnlyDefault,
      filteredTag: null
    };
  },
  methods: {
    ...mapActions("productDesignation", ["getProductDesignations", "addProductDesignation", "deleteProductDesignation"]),
    hasItems(item) {
      return item && item.children !== undefined
        ? item.children.length > 0
        : false
    },
    onTagClick(subitem) {
      debugger;
      if (this.filteredTag && this.filteredTag === subitem.id) {
        this.filteredTag = null
      }
      else {
        this.filteredTag = subitem.id
      }
      this.$emit("applyTagFilter", subitem.id);
    },
    activateTheme(theme) {
      switch (theme) {
        case 'default':
          document.body.removeAttribute('data-leftbar-theme')
          break
        case 'light':
          document.body.setAttribute('data-leftbar-theme', 'light')
          break
        case 'dark':
          document.body.setAttribute('data-leftbar-theme', 'dark')
          break
        default:
          document.body.removeAttribute('data-leftbar-theme')
          break
      }
    },
    toggleDropdown(categoryId) {
      const elem = this.$refs[`item-${categoryId}`][0]
      debugger;
      if (elem.classList.contains("mm-collapse")) {
        elem.classList.remove("mm-collapse")
        elem.parentElement.firstChild.setAttribute("aria-expanded", true)
      }
      else {
        elem.classList.add("mm-collapse")
        elem.parentElement.firstChild.setAttribute("aria-expanded", false)
      }

    },
    dragStartHandler(e) {
      // Change the source element's background color to signify drag has started
      // ev.currentTarget.style.border = "dashed";
      // Set the drag's format and data. Use the event target's id for the data
      e.dataTransfer.setData("text/plain", e.target.id);
      e.dataTransfer.setData("text/html", "fromTagList")
    },
    dropHandler(e, tag) {
      e.preventDefault();
      // Get the data, which is the id of the drop target
      if (e.dataTransfer.getData("text/html") === "fromTable") {
        const objectArray = e.dataTransfer.getData("text/plain").split(";");
        const ids = []
        for (const object of objectArray) {
          debugger;
          const splitObject = object.split(",")
          const splitTags = splitObject[1].split(":")
          // Check if the products that were provided already have the tagId, if not add them to the list for the API call
          if (!splitTags.includes(tag.id.toString())) {
            ids.push(parseInt(splitObject[0]))
          }
        }
        debugger;
        const prodDesDTO = { productIds: ids.join(","), designationId: tag.id }
        this.addProductDesignation(prodDesDTO)
        this.checkAddStatus(10,0);
      }
      // Clear the drag data cache (for all formats/types)
      e.dataTransfer.clearData();
    },
    checkAddStatus(i, stop) {
      debugger;
      if (i == null) {
        this.checkAddStatus(1000, 0)
      }
      const timeout = 60000;
      if (stop > timeout) {
        alert("Timed Out");
      } else if (!this.productDesignation.productDesignationsList.addingStatus.designationAdded) {
        const temp = stop + i;
        setTimeout(() => { this.checkAddStatus(i, temp); }, i);
      }
      else {
        this.$emit("searchEdit");
        this.$forceUpdate();
      }
    },
    dragoverFromTable(tag, e) {
      tag.dragHovered = true;
    },
    dragleaveFromTable(tag) {
      tag.dragHovered = false;
    },
    drop(tag, event) {
      tag.dragHovered = false;
      this.$emit("customDrop", tag.id);
    },
    tagClass(tag) {
      let classString = "";
      classString += tag.dragHovered ? " hoveredOver " : " ";
      classString += tag.filtered ? " tagFiltered " : " ";
      return classString;
    },
    handleCustomTagDrag(tag) {
      this.$emit("customTagDrag", [tag]);
    },
    activateMenuItems(activeClass, dropdownActiveClass) {
      debugger;
      const links = document.getElementsByClassName('side-nav-link-ref')

      let menuItemEl = null
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < links.length; i++) {
        // tslint:disable-next-line: no-string-literal
        if (window.location.pathname === links[i]['pathname']) {
          menuItemEl = links[i]
        }
      }

      if (menuItemEl) {
        menuItemEl.classList.add('active')
        const parentEl = menuItemEl.parentElement

        if (parentEl) {
          parentEl.classList.add(activeClass)

          const parent2El = parentEl.parentElement
          if (parent2El) {
            parent2El.classList.add(dropdownActiveClass)
          }

          const parent3El = parent2El.parentElement
          if (parent3El) {
            parent3El.classList.add(activeClass)

            if (parent3El.classList.contains('side-nav-item')) {
              const firstAnchor = parent3El.querySelector(
                '.side-nav-link-a-ref'
              )

              if (firstAnchor) {
                firstAnchor.classList.add('active')
              }
            }

            const parent4El = parent3El.parentElement
            if (parent4El) {
              parent4El.classList.add(dropdownActiveClass)

              const parent5El = parent4El.parentElement
              if (parent5El) {
                parent5El.classList.add(activeClass)

                const parent6El = parent5El.parentElement
                if (parent6El) {
                  parent6El.classList.add(dropdownActiveClass)

                  const parent7El = parent6El.parentElement
                  if (parent7El) {
                    parent7El.classList.add(activeClass)
                  }
                }
              }
            }
          }
        }
      }
    },
  }
};
</script>

<style scoped>
.badge-grip {
  position: absolute;
  top: 3px;
  right: 10px;
  font-size: 17px;
  color: #999;
}

.side-nav-second-level li a,
.side-nav-third-level li a,
.side-nav-forth-level li a {
  padding: 0px;
}

#menu-bar .side-nav-item .badge {
  width: 94%;
  text-align: left;
  margin: 3px 0px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 30px 5px 20px !important;
  display: block;
  position: relative;
  line-height: normal;
}

.filteredTagRow {
  padding: 12px 5px;
}

.hoveredOver {
  background-color: #bfd9f1;
  border-radius: 4px;
}

.tagFiltered {
  background-color: rgba(161, 153, 153, 0.29);
}

.side-nav .side-nav-link {
  padding: 10px 0px;
}

.tagList {
  height: 665px;
}

.activeFilteredTag {
  background-color: #bfd9f1;
  ;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
