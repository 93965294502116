/* eslint-disable */
const tableData = [
    {
        orderId: 'B9875632',
        date: 'Feb. 12, 2021',
        status: 'Fail',
        customer: 'Scarlet Witch',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    },
    {
        orderId: 'B9875632',
        date: 'Feb. 12, 2021',
        status: 'Pass',
        customer: 'Iron Man',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    },
    {
        orderId: 'B9875632',
        date: 'Feb. 13, 2021',
        status: 'Pass',
        customer: 'The Hulk',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    },
    {
        orderId: 'B9875632',
        date: 'Feb. 13, 2021',
        status: 'Pass',
        customer: 'Spiderman',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    },
    {
        orderId: 'B9875632',
        date: 'Feb. 13, 2021',
        status: 'Pass',
        customer: 'Loki',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    },
    {
        orderId: 'B9875632',
        date: 'Feb. 13, 2021',
        status: 'Fail',
        customer: 'Captian America',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    },
    {
        orderId: 'B9875632',
        date: 'Feb. 12, 2021',
        status: 'Fail',
        customer: 'Thor',
        shipToAddress: '1234 Main St, Los Angeles, CA, 91362',
        billToAddress: '1234 Main St, Los Angeles, CA, 91362',
        compliance_flow: 'Magento eCommerce Orders'
    }
]

export { tableData }