<template>
  <b-table-simple ref="productTable" id="productTable" class="product-table mb-0" :fields="fields"
    :current-page="currentPage" sticky-header="560px" scrollable fixed :key="componentKey">
    <b-thead v-show="product.productList.productCount > 0 &&
      product.productList.status.showTableHeader === true
      ">
      <!-- Table Start -->
      <b-tr>
        <b-th style="width: 30px">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <b-checkbox v-b-tooltip="'Select All Visible'" v-model="allSelected" @change="selectAll"
                class="echeckpointOrangeCheckbox" />
            </div>
          </div>
        </b-th>
        <b-th style="width: 70px">Product Name</b-th>
        <b-th style="width: 125px">Tags</b-th>
        <b-th style="width: 120px">Description</b-th>
        <!-- <b-th style="width:80px">Last Imported</b-th> -->
        <b-th style="width: 50px">Product ID</b-th>
        <b-th style="width: 30px">Active?</b-th>
        <b-th style="width: 35px">Action</b-th>
        <!-- <b-th style="width:50px"> Action</b-th> -->
      </b-tr>
    </b-thead>
    <b-tr v-if="product.productList.productCount === 0 &&
      product.productList.status.productsLoaded === true
      " class="m-6">
      <b-td colspan="6"> No matching products found </b-td>
    </b-tr>
    <!--  -->
    <b-tbody v-if="product.productList.status.gettingProducts === true">
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
        <b-td class="td-6"></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
      <b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td> </b-tr><b-tr>
        <b-td class="td-1"><span></span></b-td>
        <b-td class="td-2"><span></span></b-td>
        <b-td class="td-3"><span></span></b-td>
        <b-td class="td-4"></b-td>
        <b-td class="td-5"><span></span></b-td>
      </b-tr>
    </b-tbody>

    <b-tbody v-if="product.productList.productCount > 0 && product.productList.status.productsLoaded === true">
      <template v-for="item in product.productList.products">
        <!-- :class="{'draghovered' : item.dragHovered, 'parentProduct' : item.variants !== null && item.variants.length > 0, 'borderBottom' : item.variants !== null && item.variants.length > 0 && item.showDetails === false, 'darkBorder' : item.variants === null || item.variants.length === 0}" -->
        <!-- <draggable :key="item.id" :sort="false" @start="drag=true" @end="drag=false"> -->
        <b-tr :key="item.id" :class="{
          draghovered: item.dragHovered,
          borderBottom:
            item.variants !== null &&
            item.variants.length > 0 &&
            item.showDetails === false,
          darkBorder: item.variants === null || item.variants.length === 0,
        }" draggable="true" class="parentOrSingle allRowsHeight relative" @dragenter="onDragEnter($event, item)"
          @dragleave="onDragLeave($event, item)" @mouseenter="hoverRowOn(item)" @mouseleave="hoverRowOff(item)"
          @dragover.prevent @dragleave.prevent @dragstart="handleDrag" @drop="dropHandler($event, item)">
          <!-- PRODUCT CHECKED STATUS -->
          <b-td class="noScroll">
            <div class="row">
              <div class="row-grip"><i class="mdi mdi-drag"></i></div>
              <div class="col-6 pr-0">
                <a href="javascript:void(0);" class="action-icon">
                  <i v-if="item.variants !== null && item.variants.length > 0" class="uil expandIcon" :class="item.showDetails === true ? 'uil-angle-down' : 'uil-angle-right'
                    " @click.stop @click.prevent @click="toggleShowDetails(item)"></i>
                </a>
              </div>
              <div class="col-6">
                <b-checkbox :checked="item.checked" @change="toggleSelected(item)" class="echeckpointOrangeCheckbox" />
              </div>
            </div>
          </b-td>
          <!-- PRODUCT NAME -->
          <b-td>{{ item.name }}</b-td>
          <!-- PRODUCT TAGS -->
          <b-td>
            <div v-if="item.variants === null &&
              productDesignation.productDesignationsList.designations
              ">
              <div class="badge p-1 px-2 badge-secondary-lighten tagMargin" v-for="(id, key, index) in item.tagIds" :set="(tag = Object(
                productDesignation.productDesignationsList.designations.find(
                  (x) => x.id === id
                )
              ))
                " :key="index" :id="id">
                {{ tag.designation }}
                <i class="mdi mdi-close sm tagCloseButton" @click.stop @click="removeTag(item, id)">
                </i>
              </div>
            </div>
          </b-td>

          <!-- PRODUCT DESCRIPTION -->
          <b-td>
            <div class="scrollable">{{ item.description }}</div>
          </b-td>
          <!-- :class="itemVariantDifference(item, id) ? 'partialPill' : ''" -->

          <!-- PRODUCT LAST IMPORTED DATE -->
          <!-- <b-td>
            {{item.lastImported.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}}
          </b-td> -->
          <!-- PRODUCT ID -->
          <b-td>{{ item.subscriberProductID }}</b-td>

          <!-- PRODUCT ACTIVE -->
          <b-td class="isActiveCell">
            <div class="isActiveCellDiv">
              <input type="checkbox" :id="`activeSwitch` + item.id" @change="onActiveChange($event, item)"
                :checked="item.active" :value="item.active" data-switch="success" />
              <label :for="`activeSwitch` + item.id" data-on-label="Yes" data-off-label="No" class="mb-0 d-block"></label>
            </div>
            <!-- <span v-if="item.variants !== null && item.variants.length !== 0" class="badge badgeColor" :class="parentBadgeSelector(item, true)">{{item.active ? "Active" : "Inactive"}}</span>
            <span v-if="item.variants === null || item.variants.length === 0" class="badge badgeColor" :class="item.active ? 'bg-success' : 'bg-danger'">{{item.active ? "Active" : "Inactive"}}</span> -->
          </b-td>
          <!-- PRODUCT ACTION -->
          <b-td class="actions">
            <div>
              <button class="btnContainer" title="Edit" v-if="inlineHandler[item.id] !== true" @click="onEdit(item)">
                <i class="mdi mdi-pencil"></i>
              </button>
              <button class="btnContainer" title="Delete" @click="onDelete(item)">
                <i class="mdi mdi-delete"></i>
              </button>
            </div>

            <button class="btnContainer" title="New Variant" @click="newVariant(item)">
              <i class="mdi mdi-plus-circle"></i>
            </button>
          </b-td>
          <!-- <b-td>
            <div class="table-button-container">
              <a href="javascript:void(0);" 
                @click.stop
                @click.prevent
                @click="openEditModal(item)" 
                class="action-icon">
                <i class="mdi mdi-square-edit-outline"></i>
              </a>
              <a href="javascript:void(0);" class="action-icon">
                <i class="mdi mdi-delete"></i>
              </a> -->
          <!-- <a href="javascript:void(0);" class="action-icon">
                <i v-if="item.variants !== null && item.variants.length > 0" 
                  class="uil"
                  :class="item.showDetails === true
                          ? 'uil-angle-up'
                          : 'uil-angle-down'"
                  @click.stop
                  @click.prevent
                  @click="toggleShowDetails(item)"></i>
              </a>
            </div>
          </b-td> -->
        </b-tr>
        <!-- </draggable> -->

        <template v-if="item.showDetails">
          <b-tr v-for="(variant, index) in item.variants" :key="variant.id" draggable="true"
            :class="{ borderBottom: index === item.variants.length - 1 }" class="allRowsHeight relative"
            @dragstart="handleDrag($event, true, index)" @dragenter="onDragEnter($event, variant)"
            @dragleave="onDragLeave($event, variant)" @mouseenter="hoverRowOn(variant)" @mouseleave="hoverRowOff(variant)"
            @dragover.prevent @dragleave.prevent @drop="dropHandler($event, variant)">
            <!-- PRODUCT VARIANT CHECKED STATUS -->
            <b-td width="20" class="noScroll">
              <div class="row">
                <div class="row-grip"><i class="mdi mdi-drag"></i></div>
                <div class="col-6 pr-0"></div>
                <div class="col-6">
                  <b-checkbox :checked="variant.checked" @change="toggleSelected(variant)"
                    class="echeckpointOrangeCheckbox" />
                </div>
              </div>
            </b-td>
            <!-- PRODUCT VARIANT NAME -->
            <b-td>{{ variant.name }}</b-td>

            <!-- PRODUCT VARIANT TAGS -->
            <b-td>
              <div v-if="productDesignation.productDesignationsList.designations">
                <div class="badge p-1 px-2 tagPadding badge-secondary-lighten tagMargin"
                  v-for="(id, key, index) in variant.tagIds" :set="(variantTag = Object(
                    productDesignation.productDesignationsList.designations.find(
                      (y) => y.id === id
                    )
                  ))
                    " :key="index">
                  {{ variantTag.designation }}
                  <i class="mdi mdi-close sm tagCloseButton" @click.stop @click="removeTag(variant, id)" />
                </div>
              </div>
            </b-td>

            <!-- PRODUCT VARIANT DESCRIPTION -->
            <b-td>
              <div class="scrollable">{{ variant.description }}</div>
            </b-td>

            <!-- PRODUCT VARIANT LAST IMPORTED -->
            <!-- <b-td>
              {{variant.lastImported.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}}
            </b-td> -->

            <!-- PRODUCT VARIANT ID -->
            <b-td>{{ variant.subscriberProductID }}</b-td>

            <!-- PRODUCT VARIANT ACTIVE -->
            <b-td>
              <div class="isActiveCellDiv">
                <input type="checkbox" :id="`activeSwitchVariant` + variant.id"
                  @change="onActiveChange($event, variant, item)" :checked="variant.active" :value="variant.active"
                  data-switch="success" />
                <label :for="`activeSwitchVariant` + variant.id" data-on-label="Yes" data-off-label="No"
                  class="mb-0 d-block"></label>
              </div>
              <!-- <span class="badge badgeColor" :class="variant.active ? 'bg-success' : 'bg-danger'">{{variant.active ? "Active" : "Inactive"}}</span> -->
            </b-td>
            <!-- PRODUCT VARIANT ACTION -->
            <b-td class="actions">
              <div>
                <button class="btnContainer" title="Edit" v-if="inlineHandler[variant.id] !== true"
                  @click="onEdit(variant)">
                  <i class="mdi mdi-pencil"></i>
                </button>
                <button class="btnContainer" title="Delete" @click="onDelete(variant)">
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            </b-td>
            <!-- <b-td>
              <div class="table-button-container">
              <a href="javascript:void(0);" 
                @click.stop
                @click.prevent
                @click="openEditModal(variant)" 
                class="action-icon">
                <i class="mdi mdi-square-edit-outline"></i>
              </a>
            </div>
            </b-td> -->
          </b-tr>
        </template>
      </template>
    </b-tbody>
    <b-tfoot id="productfoot">
      <tr>
        <!-- <td colspan="3">
          <div class="row">
            <div class="col-md-12">
              <label class="d-inline-flex align-items-center">
                Show
                <b-select
                  class="mr-1 ml-1"
                  label-field="Show"
                  v-model="perPage"
                  :options="options"
                />
                entries
              </label>
            </div>
          </div>
        </td> -->
        <!-- <td colspan="6" v-if="productDesignation.productDesignationsList.designations">
          <hyperNav
            class="hyperNav"
            :currentPage="currentPage"
            :perPage="perPage"
            :filteredLength="this.filtered.length"
            @updateCurrentPage="updateCurrentPage"
          />
        </td> -->
      </tr>
    </b-tfoot>
    <!-- Confirm Delte Modal -->
    <b-modal id="modal-delete" title="Delete" ok-title="Confirm" hide-footer no-fade centered>
      <label class="deleteLabel" v-if="deleting !== null">
        Are you sure you want to delete the product {{ deleting.name }}?
      </label>
      <div class="row mb-2 float-right">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <div class="col pr-0">
          <b-button class="float-right echeckpointBlue" size="sm" @click="onModalCancel()">
            Cancel
          </b-button>
        </div>
        <div class="col-auto">
          <b-button type="button" class="float-right userButton" size="sm" @click="onModalConfirmDelete()">
            Delete
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Failed to Delete Modal-->
    <b-modal id="modal-deleteFail" title="This Product Cannot be Deleted Right Now" hide-footer no-fade centered
      @ok="onModalConfirm()">
      <label class="deleteLabel" v-if="deleting !== null">
        You are unable to delete {{ deleting.name }} due to the following reasons:
      </label>
      <label class="deleteLabel" v-bind:style="[willFail % 2 === 0 ? { 'display': 'block' } : { 'display': 'none' }]">
        • The product has variants that must be deleted or removed first.
      </label>
      <label class="deleteLabel" v-bind:style="[willFail % 3 === 0 ? { 'display': 'block' } : { 'display': 'none' }]">
        • The product has already be used in at least one order.
      </label>
      <label class="deleteLabel" v-bind:style="[willFail % 5 === 0 ? { 'display': 'block' } : { 'display': 'none' }]">
        • The product has tags that must be removed first.
      </label>
      <div>
        <b-button class="float-right echeckpointOrange" @click="onModalConfirm()">OK</b-button>
      </div>
    </b-modal>

    <!--Add Variant Modal -->
    <b-modal centered size="lg" id="modal-addVariant" @close="reset" @background="reset" hide-footer title="Add Variant">
      <div id="reportRow" class="row">
        <div class="col-12">
          <div class="card" id="reportCard">
            <div class="card-body">
              <div class="row mb-0">
                <div class="col-lg-12">
                  <form ref="form" @submit.stop.prevent="handleSubmit">
                    <!-- Simple or Variant Products-->
                    <b-form-group label="Variant Product ID" label-for="productID-input"
                      invalid-feedback="Variant Product ID is required" :state="productIDState">
                      <b-form-input id="productID-input" placeholder="SKU, UPC, MFG#, etc."
                        v-model="addVariantObject.SubscriberProductID" :state="productIDState" required></b-form-input>
                    </b-form-group>
                    <br>
                    <b-form-group label="Variant Name" label-for="productName-input"
                      invalid-feedback="Variant Name is required" :state="productNameState">
                      <b-form-input id="productName-input" v-model="addVariantObject.name" :state="productNameState"
                        required></b-form-input>
                    </b-form-group>
                    <br>
                    <b-form-group label="Variant Description" label-for="productDescription-input">
                      <b-form-textarea id="productDescription-input"
                        v-model="addVariantObject.description"></b-form-textarea>
                    </b-form-group>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div slot="footer" class="row mb-2">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="col pr-0">
              <b-button class="float-right echeckpointBlue" size="sm" @click="reset()">
                Cancel
              </b-button>
            </div>
            <div class="col-auto">
              <b-button type="submit" class="float-right userButton" size="sm" @click="check()">
                Add Variant
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Edit Product Modal -->
    <b-modal centered size="lg" id="modal-editProduct" hide-footer title="Edit Product">
      <div id="reportRow" class="row">
        <div class="col-12">
          <div class="card" id="reportCard">
            <div class="card-body">
              <div class="row mb-0">
                <div class="col-lg-12">
                  <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Product ID" label-for="productID-input" invalid-feedback="Product ID is required"
                      :state="editIDState">
                      <b-form-input id="productID-input" v-model="editObject.SubscriberProductID" :state="editIDState"
                        readonly required></b-form-input>
                    </b-form-group>
                    <br>
                    <b-form-group label="Product Name" label-for="productName-input"
                      invalid-feedback="Product Name is required" :state="editNameState">
                      <b-form-input id="productName-input" v-model="editObject.name" :state="editNameState"
                        required></b-form-input>
                    </b-form-group>
                    <br>
                    <b-form-group label="Product Description" label-for="productDescription-input">
                      <b-form-textarea id="productDescription-input" v-model="editObject.description"></b-form-textarea>
                    </b-form-group>


                  </form>
                </div>
              </div>
            </div>
          </div>
          <div slot="footer" class="row mb-2">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="col pr-0">
              <b-button class="float-right echeckpointBlue" size="sm" @click="reset()">
                Cancel
              </b-button>
            </div>
            <div class="col-auto">
              <b-button type="button" class="float-right userButton" size="sm" @click="onSaveEdit()">
                Update
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Edit Variant Modal -->
    <b-modal centered size="lg" id="modal-editProductVariant" hide-footer @ok="onSaveEdit" title="Edit Variant">
      <div id="reportRow" class="row">
        <div class="col-12">
          <div class="card" id="reportCard">
            <div class="card-body">
              <div class="row mb-0">
                <div class="col-lg-12">
                  <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group label="Variant Product ID" label-for="productID-input"
                      invalid-feedback="Variant Product ID is required" :state="editIDState">
                      <b-form-input id="productID-input" v-model="editObject.SubscriberProductID" :state="editIDState"
                        readonly required></b-form-input>
                    </b-form-group>
                    <!-- Select
                      <label>Parent Product</label>
                      <select class="form-control" ref="editSelect">
                        <option v-for="productOption in productOptions" :key="productOption.id" :value="productOption.id">
                          {{ productOption.name }}
                        </option>
                      </select>-->
                    <br />
                    <!-- Product Details-->
                    <b-form-group label="Variant Name" label-for="productName-input"
                      invalid-feedback="Variant Name is required" :state="editNameState">
                      <b-form-input id="productName-input" v-model="editObject.name" :state="editNameState"
                        required></b-form-input>
                    </b-form-group>
                    <br />
                    <b-form-group label="Variant Description" label-for="productDescription-input">
                      <b-form-textarea id="productDescription-input" v-model="editObject.description"></b-form-textarea>
                    </b-form-group>
                    <br />
                    <!--MultiSelect-->
                    <label>Parent Product</label>
                    <multiselect ref="editSelect" class="select" v-model="value" placeholder="Search for Products"
                      id="multiselect" :show-labels="false" :hide-selected="false" label="name" track-by="id"
                      :internal-search="false" :options="productOptions"
                      :loading="this.product.filteredProductList.status.productsLoaded" :multiple="false"
                      @search-change="asyncFindParentProducts">
                      <span slot="noResult">Oops! No elements found. Consider changing the search
                        query.</span>
                    </multiselect>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div slot="footer" class="row mb-2">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div class="col pr-0">
              <b-button class="float-right echeckpointBlue" size="sm" @click="reset()">
                Cancel
              </b-button>
            </div>
            <div class="col-auto">
              <b-button type="button" class="float-right userButton" size="sm" @click="onSaveEdit()">
                Update
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-table-simple>
</template>

<script>
// import HyperNav from "@/components/products/HyperNav";
import { mapState, mapActions } from "vuex";
import { authHeader } from "../../_helpers";
import { productService } from "../../_services/product.service";
import Multiselect from "vue-multiselect";
// import draggable from 'vuedraggable'

export default {
  name: "ProductTable",
  components: {
    Multiselect,
    // HyperNav
    // draggable
  },
  data() {
    return {
      componentKey: 0,
      productOptions: [],
      value: [],
      productNameState: null,
      productIDState: null,
      editNameState: null,
      editIDState: null,
      editParentNameState: null,
      editParentIdState: null,
      foot: {
        columns: 2,
      },
      currentPage: 1,
      options: [5, 20, 100],
      filtersShown: true,
      allSelected: false,
      updateProductObject: {
        productIds: [],
        active: null,
      },
      inlineHandler: {},
      reverter: {},
      deleting: null,
      willFail: 1,
      orderProducts: {},
      newProdLine: false,
      newProduct: {
        active: false,
        checked: false,
        description: null,
        dragHovered: false,
        hovered: false,
        id: null,
        lastImported: null,
        name: null,
        parentID: null,
        showDetails: false,
        subscriberID: null,
        subscriberProductID: null,
        tagIds: [],
        tags: null,
        variants: null,
      },
      fields: [
        { key: "checkbox" },
        { key: "productName", sortable: true },
        { key: "tags" },
        { key: "productDescription", sortable: true },
        { label: "Product ID", key: "productId", sortable: true },
        { key: "status" },
        { key: "actions" },
      ],
      // items: this.product.productList.products,
    };
  },
  props: {
    quickFilter: { type: String },
    sku: { type: Number },
    passedTags: { type: Array },
    currentFilteredTags: { type: Array },
    tagMatchAny: { type: Boolean },
    showTagless: { type: Boolean },
    products: { type: Array },
    perPage: { type: Number },
    addVariantObject: {
      required: true,
    },
    addProductObject: {
      required: true,
    },
    editObject: {
      required: true,
    },
    getProductsObject: { required: true },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      product: (state) => state.product,
      productDesignation: (state) => state.productDesignation,
    }),
    filtered() {
      if (this.product.productList.status.productsLoaded === true) {
        const filteredItems = this.product.productList.products;
        return filteredItems.length > 0 ? filteredItems : [];
      } else {
        return [];
      }
    },
    allVariants() {
      const variants = [];
      if (this.product.productList.status.productsLoaded) {
        for (const product of this.product.productList.products) {
          if (product.variants) {
            for (const variant of product.variants) {
              variants.push(variant);
            }
          }
        }
      }
      return variants;
    },
    selectedItems() {
      if (this.product.productList.status.productsLoaded) {
        return [
          ...this.product.productList.products.filter((item) => item.checked === true),
          ...this.allVariants.filter((item) => item.checked === true),
        ];
      }
      return 0;
    },
    draggedItems() {
      return [
        ...this.selectedItems,
        ...this.filtered.filter((item) => item.hovered && !item.checked),
      ];
    },
    multipleSelected() {
      return this.selectedItems.length > 1;
    },

  },

  /*watch: {
    selectedItems() {
      this.$emit("multipleSelected", this.selectedItems);
    }
  },*/
  methods: {
    ...mapActions("product", ["addProduct", "updateProduct", "getFilteredProducts", "deleteProduct"]),
    ...mapActions("productDesignation", [
      "getProductDesignations",
      "addProductDesignation",
      "deleteProductDesignation",
    ]),

    // gets Parent and Simple products for variant edit modal
    asyncFindParentProducts(query) {
      this.getFilteredProducts(query).then((response) => {
        this.checkFilterProductsStatus(1000, 0);
      });
    },
    // timeout to wait for results
    checkFilterProductsStatus(i, stop) {
      if (i == null) {
        this.checkFilterProductsStatus(1000, 0);
      }
      const timeout = 240000;
      if (stop > timeout) {
        alert("Timed Out");
      } else if (this.product.filteredProductList.status.productsLoaded) {
        const temp = stop + i;
        setTimeout(() => {
          this.checkFilterProductsStatus(i, temp);
        }, i);
      } else {
        this.productOptions = this.product.filteredProductList.products;
      }
    },

    onActiveChange(event, row, parentRow = null) {
      this.updateProductObject.productIds = [];
      this.updateProductObject.productIds.push(row.id);
      this.updateProductObject.active = !event.currentTarget._value;
      const isVariant = parentRow !== null;
      if (isVariant) {
        if (this.updateProductObject.active) {
          if (!parentRow.active) {
            this.updateProductObject.productIds.push(parentRow.id);
          }
        } else {
          for (let i = 0; i < parentRow.variants.length; i++) {
            const element = parentRow.variants[i];
            if (element.active && element.id !== row.id) {
              break;
            }
            if (i === parentRow.variants.length - 1) {
              this.updateProductObject.productIds.push(parentRow.id);
            }
          }
        }
      }
      // Is parent or simple product
      else {
        if (row.variants) {
          for (let i = 0; i < row.variants.length; i++) {
            this.updateProductObject.productIds.push(row.variants[i].id);
          }
        }
      }
      this.updateProductObject.productIds = this.updateProductObject.productIds.join(",");

      this.updateProduct(this.updateProductObject);
    },
    // sets edit Object and displays proper modal
    onEdit(row, ref) {
      this.$forceUpdate();
      if (row.parentID == null) {
        //Parent or Simple
        this.editObject.active = row.active;
        this.editObject.name = row.name;
        this.editObject.description = row.description;
        this.editObject.SubscriberProductID = row.subscriberProductID;
        this.editObject.SubscriberParentProductID = null;
        this.editObject.parentName = null;
        this.editObject.parentBadgeSelector = null;
        this.$bvModal.show("modal-editProduct");
      } else {
        //Variants
        const prevParent = this.product.productList.products.filter(
          (x) => x.id == row.parentID
        );
        this.value = {
          description: prevParent[0].description,

          id: prevParent[0].subscriberProductID,

          name: prevParent[0].name + " (" + prevParent[0].subscriberProductID + ")",

          parentID: ""
        }
        this.editObject.active = row.active;
        this.editObject.name = row.name;
        this.editObject.description = row.description;
        this.editObject.SubscriberProductID = row.subscriberProductID;
        this.editObject.SubscriberParentProductID = prevParent[0].subscriberProductID;
        this.editObject.parentName = prevParent[0].name;
        this.editObject.parentDescription = prevParent[0].description;
        this.$bvModal.show("modal-editProductVariant");
      }
    },
    // triggers when save is selected, updates product in database. Hides edit modals
    onSaveEdit() {
      if (!this.checkEditValidity()) {
        return;
      } else {
        this.editNameState = null;
      }
      let productObj = {};
      if (this.editObject.SubscriberParentProductID == null) {
        productObj = {
          active: this.editObject.active,

          description: this.editObject.description,

          name: this.editObject.name,

          SubscriberProductID: this.editObject.SubscriberProductID,

          pageSize: 1,

          isEdit: true
        };
        this.addProduct(productObj);
        this.$emit("searchEdit");
        this.$forceUpdate();
      } else {
        const parent = this.product.filteredProductList.products.filter(
          (x) => x.id == this.value.id
        );
        const parentName = this.value.name.slice(0, this.value.name.lastIndexOf(" ("));
        productObj = {
          active: this.editObject.active,

          description: this.editObject.description,

          name: this.editObject.name,

          SubscriberProductID: this.editObject.SubscriberProductID,

          SubscriberParentProductID: this.value.id,

          parentDescription: this.value.description,

          parentName: parentName,

          isEdit: true
        };

        this.addProduct(productObj);
        this.addProductStatus(1000, 0);
      }
      this.$bvModal.hide("modal-editProduct");
      this.$bvModal.hide("modal-editProductVariant");
      //this.$emit("searchEdit");
    },
    // refreshes table after product has successfully been added/stored proceedure ends
    addProductStatus(i, stop) {
      if (i == null) {
        this.addProductStatus(1000, 0);
      }
      const timeout = 240000;
      if (stop > timeout) {
        alert("Timed Out");
      } else if (this.product.productList.addStatus.addingProduct) {
        const temp = stop + i;
        setTimeout(() => {
          this.addProductStatus(i, temp);
        }, i);
      } else {
        //alert("Product Has Been Saved");
        //setTimeout(() => {},i);
        this.$emit("searchEdit")
        this.$forceUpdate();
      }
    },
    // when delete is pressed, check if product can be deleted. 
    onDelete(row) {
      productService.searchProducts(row).then((result) => {
        this.deleting = row;
        this.willFail = 1;

        //checks for variant/child products
        if (this.deleting.variants !== null) {
          this.willFail *= 2;
        }
        //check if there is an order
        if (result.data.length > 0) {
          this.willFail *= 3;
        }
        //check if there are any tags
        if (this.deleting.tagIds.length > 0) {
          this.willFail *= 5;
        }

        if (this.willFail > 1) {
          this.$bvModal.show("modal-deleteFail");
        } // everything looks clear
        else {
          this.$bvModal.show("modal-delete");
        }
      });
    },
    //modal buttons for delete
    onModalConfirm() {
      this.$bvModal.hide("modal-deleteFail");
    },
    async deleteProductStatus(i, stop) {
      if (i == null) {
        this.deleteProductStatus(1000, 0);
      }
      const timeout = 240000;
      if (stop > timeout) {
        alert("Timed Out");
      } else if (this.product.productList.deleteStatus.deletingProduct) {
        const temp = stop + i;
        setTimeout(() => {
          this.deleteProductStatus(i, temp);
        }, i);
      } else if (this.product.productList.deleteStatus.deletingProduct == false) {
        await this.$nextTick(() => {
          this.$forceUpdate();
        });
        this.$emit("searchDelete");
        this.hideModal();
      }
    },
    onModalConfirmDelete() {
      this.deleteProduct(this.deleting);
      this.deleteProductStatus(1000, 0);
    },
    onModalCancel() {
      this.deleting = null;
      this.hideModal();
    },
    //form vaildity for edit modals
    resetModal() {
      this.productNameState = null;
      this.productIDState = null;
      this.parentProductNameState = null;
      this.parentProductIDState = null;
    },
    checkEditValidity() {
      this.editNameState = !(this.editObject.name == null || this.editObject.name == "");
      if(this.editNameState)
        this.editObject.name = this.editObject.name.trim()
      if(this.editObject.description != null)
        this.editObject.description = this.editObject.description.trim()
      return this.editNameState;
    },
    checkFormValidity() {
      this.productNameState = this.addVariantObject.name != null && this.addVariantObject.name != "";
      this.productIDState = this.addVariantObject.SubscriberProductID != null && this.addVariantObject.SubscriberProductID != "";

      if (!this.productIDState || !this.productNameState) {
        return false
      }
      else {
        this.addVariantObject.name = this.addVariantObject.name.trim();
        this.addVariantObject.SubscriberProductID = this.addVariantObject.SubscriberProductID.trim();
        this.productNameState = this.addVariantObject.name != "";
        this.productIDState = this.addVariantObject.SubscriberProductID != "";
        if(!this.productIDState || !this.productNameState) 
        {
          return false;
        }
        if(this.addVariantObject.description != null)
        this.addVariantObject.description = this.addVariantObject.description.trim();
        return true
      }
    },
    check() {
      // Trigger submit handler
      this.handleSubmit();
    },
    reset() {
      this.addVariantObjectNull();
      this.resetModal();
      this.hideModal();
    },
    handleSubmit() {
      this.resetModal();
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // add new product
      this.addProduct(this.addVariantObject);
      this.addProductStatus(1000, 0);
      this.addVariantObjectNull();
      this.resetModal();
      this.hideModal();
      this.$emit("searchEdit");
    },
    // hides all modals when called 
    hideModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-addVariant");
        this.$bvModal.hide("modal-editProduct");
        this.$bvModal.hide("modal-editProductVariant");
        this.$bvModal.hide("modal-delete");
        this.$bvModal.hide("modal-deleteFail");
      });
    },
    //reset object 
    addVariantObjectNull() {
      this.addVariantObject.active = false;
      this.addVariantObject.description = "";
      this.addVariantObject.name = null;
      this.addVariantObject.SubscriberParentProductID = null;
      this.addVariantObject.SubscriberProductID = null;
      this.addVariantObject.parentName = null;
      this.addVariantObject.parentDescription = "";
    },
    // add variant button
    newVariant(row) {
      /**/
      this.reset();
      this.$bvModal.show("modal-addVariant");
      this.addVariantObject.parentName = row.name;
      this.addVariantObject.parentDescription = row.description;
      this.addVariantObject.SubscriberParentProductID = row.subscriberProductID;
    },
    // parentBadgeSelector(item, isClass) {
    //   // eslint-disable-next-line
    //   var activeVariants = []
    //   // eslint-disable-next-line
    //   var inactiveVariants = []
    //   for (const variant of item.variants) {
    //     if (variant.active) {
    //       activeVariants.push(variant)
    //     }
    //     else {
    //       inactiveVariants.push(variant)
    //     }
    //     if (activeVariants.length > 0 && inactiveVariants.length > 0) {
    //       return isClass ? "bg-warning" : "Mixed Status"
    //     }
    //   }

    //   if (activeVariants.length === item.variants.length) {
    //     return isClass ? "bg-success" : "Active"
    //   }
    //   else if (inactiveVariants.length === item.variants.length) {
    //     return isClass ? "bg-danger" : "Inactive"
    //   }
    // },
    hoverRowOn(item, index, event) {
      item.hovered = true;
    },

    hoverRowOff(item, index, event) {
      item.hovered = false;
    },
    toggleSelected(item) {
      item.checked = !item.checked;
      if (item.variants !== null && item.variants.length > 0) {
        item.variants.forEach((variant) => (variant.checked = item.checked));
      }
      this.$emit("multipleSelected", this.selectedItems);
    },
    toggleShowDetails(item) {
      if (item.variants !== null && item.variants.length > 0) {
        item.showDetails = !item.showDetails;
      }
    },

    handleDrag(e, isVariant) {
      //debugger;
      if (isVariant) {
        this.allVariants.find((item) => item.hovered).checked = true; //Ensure the current row is checked
      } else {
        this.filtered.find((item) => item.hovered).checked = true; //Ensure the current row is checked

        // if parent product is dragged onto a tag
        // ensure all its variants are selected and tagged as well
        const parentProduct = this.filtered.find((item) => item.hovered);
        if (parentProduct.variants && parentProduct.variants.length) {
          parentProduct.variants.forEach(function (item, index) {
            item.checked = true;
          });
        }
      }
      // Generate a psuedo-item to give the
      const count = this.draggedItems.length;
      const elem = document.createElement("div");
      elem.id = "drag-ghost";
      elem.textContent = "Dragging " + count + " item(s)";
      elem.style.position = "absolute";
      elem.style.top = "-1000px";
      elem.style.padding = "10px 15px";
      elem.style.color = "white";
      elem.style.backgroundColor = "#f89c43";
      document.body.appendChild(elem);
      e.dataTransfer.setDragImage(elem, 0, 0);
      const filteredProducts = this.draggedItems.filter(this.isSimpleOrVariant);
      const ids = filteredProducts.map((item) => item.id + "," + item.tagIds.join(":"));
      e.dataTransfer.setData("text/plain", ids.join(";"));
      e.dataTransfer.setData("text/html", "fromTable");

      if (
        isVariant &&
        this.allVariants.filter((item) => item.checked == true).length == 1 &&
        this.filtered.filter((item) => item.checked == true).length < 1
      ) {
        this.allVariants.find((item) => item.hovered).checked = false; //Ensure the current row is checked
      } else if (
        !isVariant &&
        this.filtered.filter((item) => item.checked == true).length == 1 &&
        this.allVariants.filter((item) => item.checked == true).length < 1 &&
        !this.filtered.find((item) => item.hovered).variants
      ) {
        this.filtered.find((item) => item.hovered).checked = false;
      }

    },
    isSimpleOrVariant(product) {
      if (!product.variants || !product.variants.length) {
        return product;
      }
    },
    dropHandler(e, row) {
      debugger;
      e.preventDefault();

      const prodIdList = this.product.productList.products.filter((item) => item.checked);
      const variantIdList = this.allVariants.filter((item) => item.checked);

      // Get the data, which is the id of the drop target
      if (e.dataTransfer.getData("text/html") === "fromTagList") {
        const data = e.dataTransfer.getData("text/plain");
        const tagId = parseInt(data);
        let prodDesDTO = null;

        if (this.multipleSelected || this.selectedItems.length == 1) {
          const ids = [];
          for (const product of this.selectedItems) {
            if (!product.variants || !product.variants.length) {
              // Product is simple or a variant
              //if (!product.tagIds.includes(tagId)) {
              ids.push(product.id);

              //}
            }
          }

          prodDesDTO = { productIds: ids.join(","), designationId: tagId };
          this.addProductDesignation(prodDesDTO);

          //this.getProductsAfterTag(this.getProductsObject, true)
          this.$emit("getProductsAfterTag", this.selectedItems);
          this.checkAddStatus(1,0);

          if (this.selectedItems.length == 1) {
            this.selectedItems[0].checked = false;
          }
        } else {
          if (row.variants) {
            const filteredProducts = row.variants; //.filter(item => !item.tagIds.includes(tagId))
            const productIds = filteredProducts.map((item) => item.id);
            if (productIds && productIds.length !== 0) {
              prodDesDTO = { productIds: productIds.join(","), designationId: tagId };
              this.addProductDesignation(prodDesDTO);
              this.checkAddStatus(1,0);
            }
          } else {
            //if (!row.tagIds.includes(tagId)) {
            prodDesDTO = { productIds: row.id.toString(), designationId: tagId };
            this.addProductDesignation(prodDesDTO);
            this.checkAddStatus(1,0);
            //}
            row.checked = false; //ensure box is unchecked if a single product w/o variants was checked before dragging tag unto it
          }
        }
      }

      // Clear the drag data cache (for all formats/types)
      e.dataTransfer.clearData();
    },
    checkAddStatus(i, stop) {

      if (i == null) {
        this.checkAddStatus(1000, 0)
      }
      const timeout = 60000;
      if (stop > timeout) {
        alert("Timed Out");
      } else if (!this.productDesignation.productDesignationsList.addingStatus.designationAdded) {
        const temp = stop + i;
        setTimeout(() => { this.checkAddStatus(i, temp); }, i);
      }
      else {
        this.$emit("searchEdit");
        this.$forceUpdate();
      }
    },
    onDropFromLabel(e, item) {
      item.dragHovered = false;
    },
    onDragEnter(e, item) {
      item.dragHovered = true;
    },
    onDragLeave(e, item) {
      item.dragHovered = false;
    },
    toggleFiltersShown() {
      this.filtersShown = !this.filtersShown;
    },
    itemVariantDifference(item, id) {
      if (item.variants !== null && item.variants.length > 0) {
        return item.variants.some(function (itemVariant) {
          if (itemVariant.tagIds !== null && itemVariant.tagIds.length > 0) {
            return !itemVariant.tagIds.includes(id);
          } else {
            return false;
          }
        });
      }
      return false;
    },
    selectAll() {
      if (this.product.productList.products.length > 0) {
        for (const item of this.product.productList.products) {
          if (item.variants !== null) {
            for (const variant of item.variants) {
              variant.checked = this.allSelected;
            }
          }
          item.checked = this.allSelected;
        }

        this.$emit("multipleSelected", this.selectedItems);
      }
    },
    updateCurrentPage(data) {
      this.currentPage = data;
    },
    openEditModal(item) {
      this.$emit("singleProductEdit", item);
      this.$bvModal.show("product-edit-modal");
    },
    removeTag(item, id) {
      debugger;
      const prodDesDTO = { productIds: item.id.toString(), designationId: id };
      this.deleteProductDesignation(prodDesDTO);
    },
  },
  mounted: function () {
    this.getFilteredProducts("");
  },
};
</script>

<style>
.row-grip {
  position: absolute;
  bottom: 0px;
  left: 2px;
  font-size: 17px;
  color: #999;
}

.relative {
  position: relative;
}

#productTable tr th {
  font-size: 12px;
}

#productTable .noScroll {
  overflow: hidden;
}

#productTable.table th,
#productTable.table td {
  padding: 10px;
}

#editSelect .multiselect,
#editSelect .multiselect__content-wrapper {
  height: max-content;
  min-width: 100%;
  border-top: 1px solid #e8e8e8;
}

#editSelect .multiselect__input {
  background: none;
}

#editSelect .multiselect--active,
#editSelect .multiselect__tags {
  border-bottom: none;
}

.tagMargin {
  margin-right: 3px;
  margin-bottom: 3px;
}

.borderBottom {
  border-bottom: 2px solid #dfe4eb !important;
}

.darkBorder {
  border-top: 2px solid #dfe4eb;
  border-bottom: 2px solid #dfe4eb;
}

.draghovered {}

.variant {
  background-color: rgba(249, 239, 255, 0.51);
}

.partialPill {
  background: repeating-linear-gradient(232deg,
      rgba(145, 156, 167, 0.18),
      rgba(145, 156, 167, 0.18) 10px,
      rgba(141, 203, 255, 0.18) 10px,
      rgba(141, 203, 255, 0.18) 20px);
}

div.scrollable {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.allRowsHeight {
  height: 105px;
  background-color: #ffffff;
}

.parentOrSingle {
  /* background-color: #f3f6fd; */
  background-color: #fafafa;
}

.badgeColor {
  color: #fff;
}

.hyperNav ul {
  margin-bottom: 0px;
}

#productfoot td {
  padding-bottom: 0px;
}

.expandIcon {
  font-size: 25px;
}

#productTable thead th {
  background-color: #f5f5f5;
  box-shadow: inset 1px 0 #ffffff, 0 -1px #ffffff, 4px 0 #ffffff,
    5px 1px 3px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 -250px 2px 201px #ffffff;
  border-top: 1px #ffffff;
}

#productTable th {
  border: 1px solid #ffffff;
  border-width: 1px 1px 0 0;
}

#productTable tr td {
  /*height: 95px;*/
  height: fit-content;
  overflow: auto;
}

.productsCard .table-responsive {
  max-width: 1280px;
}

.productsCard .row {
  flex-wrap: nowrap !important;
}

.productsCard .col-sm-auto {
  width: auto !important;
}

.productsCard div.isActiveCellDiv {
  overflow: hidden;
}
</style>

<style lang="scss">
@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

table {
  width: 100%;

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    td {
      height: 50px;
      vertical-align: middle;
      padding: 8px;

      span {
        display: block;
      }

      &.td-1 {
        width: 20px;

        span {
          width: 20px;
          height: 20px;
        }
      }

      &.td-2 {
        width: 50px;

        span {
          background-color: rgba(0, 0, 0, 0.15);
          width: 50px;
          height: 12px;
        }
      }

      &.td-3 {
        width: 400px;

        // padding-right: 100px;
        span {
          height: 12px;
          background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
          background-size: 500px 100px;
          animation-name: moving-gradient;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }

      &.td-4 {}

      &.td-5 {
        //width: 100px;

        span {
          background-color: rgba(0, 0, 0, 0.15);
          width: 100%;
          height: 30px;
        }
      }
    }
  }
}

.actions {
  display: inline-block;
  overflow-y: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  // Action button Colors
  .btnContainer {
    border: 1px solid #adb5bd;
    border-radius: 5px;
    width: 1.75em;
    text-align: center;
    font-size: 14pt;
    background-color: transparent;
    color: #adb5bd;
    margin-bottom: 3px;
    margin-right: 3px;
  }

  .redBtn {
    color: #6c757d;
    border: 1px solid #6c757d;
  }

  .btnContainer:hover {
    background-color: #adb5bd;
    color: #ffffff;
  }

  .redBtn:hover {
    background-color: #6c757d;
    color: #ffffff;
  }
}

.actions::-webkit-scrollbar{ display: none;}

.parentProductNameLabel {
  margin: 20px, 0, 0, 0;
}

.newProdLabel {
  margin-right: 10px;
  padding-right: 10px;
  width: auto;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.tagCloseButton {
  height: 4px;
  width: 4px;
}

input[data-switch="success-ec-mc"]:checked+label {
  //background-color: #16a23e;
  background-color: #1e8e3e;
}

.tagCloseButton:hover {
  background-color: rgba(88, 96, 102, 0.349);
  border-radius: 50%;
}

#productTable .multiselect .multiselect__content-wrapper {
  min-width: 500px;
  width: auto;
  border-top: 1px solid #e8e8e8;
}

#productTable .multiselect--active .multiselect__tags {
  border-bottom: none;
}

.deleteLabel {
  padding: 5px;
}
</style>
