import moment from "moment";

export function formatDate(date) {
  return moment(date).format("MM/DD/YYYY h:mm A");
}

export function formatDateLocal(date) {
  return moment.utc(date).local().format("MM/DD/YYYY h:mm A");
}

export function formatFriendlyDate(date) {
  return moment(date).format("MM/DD/YYYY");
}

export function formatFriendlyDateLocal(date) {
  return moment.utc(date).local().format("MM/DD/YYYY");
}
