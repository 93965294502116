import config from "config";
import { authHeader, handleResponse } from "../_helpers";

function SendAuthCode(Code){
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(Code ? Code : {})
    }
    return fetch(`${config.apiUrl}/idme/authcode`, requestOptions).then(
        handleResponse
    );
}

export const IDmeService = {
    SendAuthCode,
};