<template>
    <layout>
        <PageHeader title="BigCommerce"/> 
        <div class="card">
            <div class="card-body lockCard">
                <h4 class="group-title">Settings</h4>
                <form id="bcForm" @submit="UpdateBC($event)" v-if="Flows[BCSettings.SubscriberServiceFlowIDPostCheckout] || BCSettings.SubscriberServiceFlowIDPostCheckout === null">
                    <label for="StoreHash">Store: </label>
                    <span id="StoreHash">{{ BCSettings.Hash }}</span><br>
                    <label for="BCAccessToken">BigCommerce API Token: </label>
                    <span for="BCAccessToken">{{ BCSettings.BCAccessToken }}</span><br>
                    <br><hr>
                    <h4 class="header-title">Pre-Order Checkpoint</h4>
                    <p>The Pre-Order Checkpoint reviews items in the shopping cart once all the necessary information has been provided<!--when the Place Order button is clicked and before payment is processed-->.
                    It blocks non-compliant items from being purchased and helps prevent refunds.
                    For full compliance and accuracy, this checkpoint must be used in conjunction with the Post-Order Checkpoint.</p>
                    <h5>General Settings</h5>
                    <p>Configure the settings for your pre-order checkpoint.</p>
                    <label for="PreFlow">Checkpoint: </label>
                    <select class="form-control" type="text" id="PreFlow" name="PreFlow" v-model="TempBCSettings.SubscriberServiceFlowIDPreCheckout" required>
                        <option v-for="flow in Flows" :key="flow.Id" :value="flow.Id">{{ flow.Name }}</option>
                    </select><br>
                    <label>Enable Pre-Order Check:</label>
                    <div class="OptionSwitch">
                        <div>
                            <input id="PreCheckoutEnabled" type="checkbox" v-model="TempBCSettings.PreCheckoutEnabled" data-switch="success" />
                            <label for="PreCheckoutEnabled"  data-on-label="On" data-off-label="Off"></label>
                        </div>
                    </div><br>
                    <h5>Conditional Fail Settings</h5>
                    <p>Displays a message during checkout if additional information will be required from your customer after checkout for example, permits or licenses.
                    Displaying these messages will enable your customers to remove products from their cart that they are not qualified to purchase.</p>
                    <label>Show Conditional Fail Messages:</label>
                    <div class="OptionSwitch">
                        <div>
                            <input id="DisplayCondlFail" type="checkbox" v-model="TempBCSettings.DisplayCondlFail" data-switch="success" />
                            <label for="DisplayCondlFail"  data-on-label="On" data-off-label="Off"></label>
                        </div>
                    </div><br>
                    <br><hr>
                    <h4 class="header-title">Post-Order Checkpoint</h4>
                    <p>The Post-Order Checkpoint reviews the items in the shopping cart after the order has been placed.
                    This provides a tamper-proof compliance record, ensuring it is the most reliable source for Customer Service to confirm final
                    actions needed to process the order.</p>
                    <label for="PostFlow">Checkpoint: </label>
                    <select class="form-control" type="text" id="PostFlow" name="PostFlow" v-model="TempBCSettings.SubscriberServiceFlowIDPostCheckout" required>
                        <option v-for="flow in Flows" :key="flow.Id" :value="flow.Id">{{ flow.Name }}</option>
                    </select><br>
                    <h5>Order Comment Settings</h5>
                    <p>Adds a comment to each order noting the compliance check result. Your customers will not be notified.</p>
                    <label>Add Order Comment: </label>
                    <div class="OptionSwitch">
                        <div>
                            <input id="Comments" type="checkbox" v-model="TempBCSettings.OrderComment" data-switch="success" />
                            <label for="Comments"  data-on-label="On" data-off-label="Off"></label>
                        </div>
                    </div><br>
                    <h5>Order Status Settings</h5>
                    <p>Places orders with a Fail or Conditional Fail result on hold. Your customers will not be notified.</p>
                    <label>Place Orders On Hold: </label>
                    <div class="OptionSwitch inline">
                        <div>
                            <input id="Statuses" type="checkbox" v-model="TempBCSettings.OrderStatusUpdate" data-switch="success" />
                            <label for="Statuses"  data-on-label="On" data-off-label="Off"></label>
                        </div>
                    </div><br>
                    <br><hr>
                    <h4 class="header-title">Compliance Fee</h4>
                    <p>Offset the costs of your compliance program by adding a compliance fee in checkout.</p>
                    <label>Enable 50-State Compliance Fee: </label>
                    <div class="OptionSwitch inline">
                        <div>
                            <input id="FeeOn" type="checkbox" v-model="TempBCSettings.Fee.Enabled" data-switch="success" />
                            <label for="FeeOn"  data-on-label="On" data-off-label="Off"></label>
                        </div>
                    </div><br>
                    <label>Fee Percentage: </label>
                    <div class="percentage"><input type="number" v-model="TempBCSettings.Fee.Percent" class="form-control right-align" step=".01" min="0" max="100"></div><br>
                    <label>Minimum Fee Per Item: </label>
                    <div class="currency"><input type="number" v-model="TempBCSettings.Fee.Minimum" class="form-control right-align" step=".01" min="0"></div><br>
                    <label>Maximum Fee Per Item: </label>
                    <div class="currency"><input type="number" v-model="TempBCSettings.Fee.Maximum" class="form-control right-align" step=".01" min="0"></div><br>
                    <label>Calculate Fee For up to X Items per SKU: </label>
                    <input type="number" v-model="TempBCSettings.Fee.CountDuplicates" class="form-control right-align" min="0"><br>

                    <!--button class="btn userButton btn-secondary" id="Saveutton">Save</button-->
                    <SaveButton id="SaveButton" :handle="saveHandle" :state="saveState"/>
                </form>
                <div v-if="!Flows[BCSettings.SubscriberServiceFlowIDPostCheckout] && BCSettings.SubscriberServiceFlowIDPostCheckout !== null">
                    <span>This store is not linked to this subscriber account, please login to the correct account to view and edit settings.</span><br>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Layout from "@/components/layout/main";
import PageHeader from "@/components/layout/page-header";
import SaveButton from "@/components/universal/saveButton";
import { nextTick } from 'vue';
import { credentialReviewService } from '../../_services';



export default {
        page: {
        title: "BigCommerceApp",
    },
    components: {
        Layout,
        PageHeader,
        SaveButton
    },
    data() {
    return {
        saveState: null,
        ParentURL: null,
        TempBCSettings: {
            Hash: "",
            BCAccessToken: "",
            SubscriberServiceFlowIDPreCheckout: null,
            SubscriberServiceFlowIDPostCheckout: null,
            OrderStatusUpdate: false,
            OrderComment: false,
            DisplayCondlFail: false,
            PreCheckoutEnabled: false,
            Fee: {
                Enabled: false,
                Minimum: 0,
                Maximum: 0,
                Percent: 0,
                CountDuplicates: 0,
            }
        },
    };
    },
    computed: {
        ...mapState('settings', ["BCSettings", "Status", "Flows"]),
    },
    created() {
        let url = window.location.ancestorOrigins[window.location.ancestorOrigins.length - 1];
        if(url.startsWith("https://store-"))
        {
            url = url.replace("https://store-", "")
            this.ParentURL = url.split('.', 1)[0]
        }
        this.getSubFlows();
    },
    mounted() {
        this.getBigCommerce(this.ParentURL);
        this.Await(this.UpdateTemp, ()=> {return this.Status.getBigCommerceRequestLoading === false && this.Status.getSubFlowsRequestLoading === false}, 0)
    },
  
    methods: {
        ...mapActions("settings", ["updateBigCommerce", "getBigCommerce", "getSubFlows", "GetScripts", "AddScript", "UpdateScript", "DeleteScript"]),
        async UpdateBC(ev)
        {
            //document.getElementById("bcForm").checkValidity();
            ev.preventDefault();
            
            this.saveState = "Saving";
            //this.GetEcScriptBody()
            try
            {
                let state = 0;
                const PreChange = this.TempBCSettings.PreCheckoutEnabled !== this.BCSettings.PreCheckoutEnabled;
                const DisplayChange = this.CheckScriptChanges();
                if(PreChange)
                    state = 2;
                if(DisplayChange)
                    state += 1;
                
                switch(state)
                {
                    case 1:
                        if(this.TempBCSettings.PreCheckoutEnabled)
                        {
                            console.log("1");
                            const scriptID = await this.GetScriptUUID();
                            if(scriptID)
                            {
                                console.log(scriptID);
                                this.UpdateBCScript(scriptID); 
                            }
                            else
                            {
                                this.AddBCScript();
                            }
                            
                        }
                        break;
                    case 2:
                    case 3:
                        if(this.TempBCSettings.PreCheckoutEnabled)
                        {
                            //add script
                            
                            const scriptID = await this.GetScriptUUID();//MAY NOT NEED AS NONE SHOULD EXIST
                            if(scriptID)
                            {
                                
                                this.UpdateBCScript(scriptID);
                            }
                            else
                            {
                                this.AddBCScript();
                            }
                        }
                        else
                        {
                            //delete script
                            const scriptID = await this.GetScriptUUID();
                            if(scriptID)
                            {
                                this.DeleteBCScript(scriptID); 
                            }
                        }
                        break;
                }

                this.updateBigCommerce(this.ValidateSettings())
                this.Await(() => {this.getBigCommerce(this.ParentURL); this.Await(this.UpdateTemp, ()=> {return this.Status.getBigCommerceRequestLoading === false}, 0)}, ()=>{return this.Status.updateBigCommerceRequestLoading === false}, 0)
                this.saveState = "Saved";
                setTimeout(()=>{this.saveState = ""}, 5000);
            }
            catch
            {
                this.saveState = "Save Failed";
                setTimeout(()=>{this.saveState = ""}, 5000);
            }
        },
        Await(func, cond, i)
        {
            setTimeout(() =>
            {
                if(cond())
                {
                    func();
                    return;
                }
                else if(i > 100)
                    return;
                else
                    this.Await(func, cond, ++i)
            }, 250)
        },
        UpdateTemp()
        {
            this.TempBCSettings.Hash = this.BCSettings.Hash
            this.TempBCSettings.BCAccessToken = this.BCSettings.BCAccessToken
            this.TempBCSettings.SubscriberServiceFlowIDPreCheckout = this.BCSettings.SubscriberServiceFlowIDPreCheckout
            this.TempBCSettings.SubscriberServiceFlowIDPostCheckout = this.BCSettings.SubscriberServiceFlowIDPostCheckout
            this.TempBCSettings.OrderStatusUpdate = this.BCSettings.OrderStatusUpdate
            this.TempBCSettings.OrderComment = this.BCSettings.OrderComment
            this.TempBCSettings.PreCheckoutEnabled = this.BCSettings.PreCheckoutEnabled
            this.TempBCSettings.DisplayCondlFail = this.BCSettings.DisplayCondlFail
            this.TempBCSettings.Fee = JSON.parse(JSON.stringify(this.BCSettings.Fee))
        },
        GetECScriptBody(DisplayCondlFail)
        {
            const innerMsg = DisplayCondlFail ? "Showing Condl Fail" : "Hiding Condl Fail";
            const script = `<script>console.log("${innerMsg}")<\/script>`;//eslint-disable-line
            const scriptBody = {
                name: "eCheckpoint Pre-Checkout Compliance Check",
                description: "Handles the compliance checks on the checkout page and displays the results there." + DisplayCondlFail ? "" : " Conditional fail messages will not be displayed",
                html: script,
                auto_uninstall: true,//eslint-disable-line
                load_method: "default",//eslint-disable-line
                location: "footer",
                visibility: "checkout",
                kind: "script_tag",
                consent_category: "essential"//eslint-disable-line
            }
            return scriptBody;
        },
        GetOptions(method, body, token)
        {
            debugger;
            return {
                method: method,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Auth-Token': token,
                    'Access-Control-Allow-Origin': "Null"
                },
                body: method === "GET" ? null : JSON.stringify(body)
            };
        },
        ValidateSettings()
        {
            return {
                Hash:                               this.BCSettings.Hash,
                BCAccessToken:                      this.BCSettings.BCAccessToken,
                SubscriberServiceFlowIDPreCheckout: this.TempBCSettings.SubscriberServiceFlowIDPreCheckout,
                SubscriberServiceFlowIDPostCheckout:this.TempBCSettings.SubscriberServiceFlowIDPostCheckout,
                OrderStatusUpdate:                  this.TempBCSettings.OrderStatusUpdate,
                OrderComment:                       this.TempBCSettings.OrderComment,
                DisplayCondlFail:                   this.TempBCSettings.DisplayCondlFail,
                PreCheckoutEnabled:                 this.TempBCSettings.PreCheckoutEnabled,
                Fee:                                this.FixedFee()
            }
        },
        async GetScriptUUID()
        {
            //const response = await this.GetScripts({url: `https://api.bigcommerce.com/stores/${this.BCSettings.Hash}/v3/content/scripts`, options: this.GetOptions("GET", null, this.BCSettings.BCAccessToken)})
            const response = await this.GetScripts(this.ValidateSettings());
            debugger;
            if(response.meta.pagination.total === 0)
                return false
            return response.data.find(e => e.name === "eCheckpoint Pre-Order Compliance Check").uuid
        },
        async AddBCScript()
        {
            const response = await this.AddScript({Settings: this.ValidateSettings()});
            return response;
        },
        async UpdateBCScript(UUID)
        {
            const response = await this.UpdateScript({Settings: this.ValidateSettings(), UUID: UUID});
            return response;
        },
        async DeleteBCScript(UUID)
        {
            const response = await this.DeleteScript({Settings: this.ValidateSettings(), UUID: UUID});
            return response;
        },
        FixedFee()
        {
            const fixed = {}
            fixed.Enabled = this.TempBCSettings.Fee.Enabled
            fixed.Minimum = parseFloat(this.TempBCSettings.Fee.Minimum)
            fixed.Maximum = parseFloat(this.TempBCSettings.Fee.Maximum)
            fixed.Percent = parseFloat(this.TempBCSettings.Fee.Percent)
            fixed.CountDuplicates = parseInt(this.TempBCSettings.Fee.CountDuplicates)
            return fixed
        },
        CheckScriptChanges()
        {
            this.ValidateSettings();
            let settings = Object.keys(this.BCSettings)
            const toRemove = ["OrderComment", "OrderStatusUpdate", "SubscriberServiceFlowIDPreCheckout", "SubscriberServiceFlowIDPostCheckout", "BCAccessToken", "Hash"]
            settings = settings.filter((e)=> {return !toRemove.includes(e)});
            for(const setting of settings)
            {
                if(JSON.stringify(this.BCSettings[setting]) !== JSON.stringify(this.TempBCSettings[setting]))
                {
                    return true;
                }
            }
            return false;
        },
        saveHandle()
        {
            //var evt = document.createEvent("Event");
            //evt.initEvent("submit", true, true);
            document.getElementById("bcForm").requestSubmit();
        }
    }
}
</script>

<style lang="scss" scoped>

.card-body
{
    background-color: var(--white);
    position: relative;
}

select, .form-control
{
    width: 24em;
}

.OptionSwitch
{
    width: fit-content;
    display: inline-block;
}

label
{
    width: 16em;
    vertical-align: middle;
    font-weight: 600;
}

.form-control
{
    display: inline-block;
    margin-bottom: .5em;
}

.right-align
{
    text-align: right;
    width: 7em;
}

#SaveButton
{
    position: absolute;
    right: 1em;
    bottom: 1em;
}

#percentSym
{
    width:fit-content;
}

.currency
{
    display: inline-block;
    position: relative;
}

.currency::before
{
    position: absolute;
    top: 20%;
    content:"$";
    left: 5px;
}

.percentage
{
    display: inline-block;
    position: relative;
}

.percentage::after
{
    position: absolute;
    top: 20%;
    content:"%";
    right: 15px;
}

.percentage:hover::after, .percentage:focus-within::after
{
    display: none;
}
</style>