import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import Home from "../views/vue_sample_pages/Home.vue";
// import About from "../views/vue_sample_pages/About.vue";
import Report from "../views/report/Report.vue";
import LoginPage from "../views/login/LoginPage.vue";
import RecoverPassword from "../views/recover_password/RecoverPassword.vue";
import RecoverPasswordConfirm from "../views/recover_password/RecoverPasswordConfirm.vue";
import RecoverPasswordSubmit from "../views/recover_password/RecoverPasswordSubmit.vue";
import TwoFactorPage from "../views/TwoFactorPage.vue";
import Products from "../views/products/Products.vue";
import Settings from "../views/settings/Settings.vue";
import IframePage from "../views/iframe/iframe.vue";
import ManualCheck from "../views/manual_check/manualCheck.vue";
import CredentialReview from "../views/credential_review/CredentialReview.vue";
import IdmeRedirect from "../views/iframe/idmeRedirect.vue";
import eCBigCommerceApp from "../views/bigCommerceApp/bigCommerceApp.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/report",
    name: "Home"
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage
  },
  {
    path: "/twofa",
    name: "twofa",
    component: TwoFactorPage
  },
  {
    path: "/report",
    name: "Report",
    component: Report
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/recover",
    name: "RecoverPasword",
    component: RecoverPassword
  },
  {
    path: "/recover-confirm",
    name: "RecoverPaswordConfirm",
    component: RecoverPasswordConfirm
  },
  {
    path: "/recover-submit",
    name: "RecoverPaswordSubmit",
    component: RecoverPasswordSubmit
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/iframe",
    name: "Iframe",
    component: IframePage
  },
  {
    path: "/manual-check",
    name: "Manual Compliance Check",
    component: ManualCheck
  },
  {
    path: "/credential-review",
    name: "CredentialReview",
    component: CredentialReview
  },
  {
    path: "/idmeRedirect",
    name: "idmeRedirect",
    component: IdmeRedirect
  },
  {
    path: "/bigCommerce",
    name: "bigCommerce",
    component: eCBigCommerceApp
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/login",
    "/register",
    "/recover",
    "/recover-confirm",
    "/recover-submit",
    "/iframe",
    "/idmeRedirect"
  ];
  const loginRequired = !publicPages.includes(to.path);
  const userJson = localStorage.getItem("user");
  const currentUser = userJson !== null ? JSON.parse(userJson) : null;
  let loggedIn = false;
  let twoFactorAuthenticated = false;
  if (currentUser !== null) {
    loggedIn = currentUser.token !== null || currentUser.token !== "";
    twoFactorAuthenticated = loggedIn && "twoAuthToken" in currentUser;
    // currentUser.twoAuthToken !== null || currentUser.twoAuthToken !== ""
  }

  // const twoFactorAuthenticated = localStorage.getItem('2fa');

  if (loginRequired && !loggedIn) {
    return next("/login");
  } else if (
    loginRequired &&
    loggedIn &&
    !twoFactorAuthenticated &&
    to.path !== "/twofa"
  ) {
    return next("/twofa");
  } else {
    next();
  }

  // if (authRequired && loggedIn && !twoFactorAuthenticated) {
  //   return next('/2fa');
  // }
});

const reportPage = [
  {
    path: "/report",
    name: "Reporting",
    icon: "uil-home-alt",
    meta: { authRequired: true },
    // create a container component
    component: Report
    // children: [
    // ],
  }
];

const productPage = [
  {
    path: "/products",
    name: "Products",
    icon: "uil-tag-alt",
    meta: { authRequired: true },
    component: Products
  }
];

/*const settingsPage = [
  {
    path: "/settings",
    name: "Settings",
    icon: "uil-cog",
    meta: { authRequired: true },
    component: Settings
  }
];*/

const manualCheckPage = [
  {
    path: "/manual-check",
    name: "Manual Check",
    icon: "uil-book-alt",
    meta: { authRequired: true },
    component: ManualCheck
  }
];

const loginPage = [
  {
    path: "/login",
    name: "LoginPage",
    icon: "uil-sign-out-alt",
    meta: { authRequired: false },
    // create a container component
    component: LoginPage
    // children: [
    // ],
  }
];

// const registerPage = [
//   {
//     path: '/register',
//     name: 'RegisterPage',
//     icon: 'uil-file-upload-alt',
//     meta: { authRequired: false },
//     // create a container component
//     component: RegisterPage,
//     // children: [
//     // ],
//   }
// ];

// const adminPage = [
//   {
//     path: '/admin',
//     name: 'AdminPage',
//     icon: 'uil-user-plus',
//     meta: { authRequired: true },
//     // create a container component
//     // component: AdminPage,
//     // children: [
//     // ],
//   }
// ];

const credentialReview = [
  {
    path: "/credential-review",
    name: "Credential Review",
    icon: "uil-sign-out-alt",
    meta: { authRequired: true },
    component: CredentialReview
  }
];

const navigation = [
  ...reportPage,
  ...productPage,
  ...manualCheckPage,
  //...settingsPage,
  ...credentialReview
  // ...loginPage,
  // ...registerPage,
  // ...adminPage
];



export { navigation };
