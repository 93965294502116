import { IDmeService } from "../_services";

const state = {
    all: {}
};

const actions = {
    sendAuthCode({ dispatch, commit }, code){

        IDmeService.SendAuthCode(code).then(
        response => {
            return commit("AuthCodeSuccess");
        },
        error => {
            commit("AuthCodeFailure", error);
            dispatch("alert/error", error, { root: true });
        }
        );
    }
};

const mutations = {
    AuthCodeSuccess(state)
    {
        window.close();
    },
    AuthCodeFailure(state, error)
    {
        window.close();
    }
};

export const idme = {
    namespaced: true,
    state,
    actions,
    mutations
};