<template>
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div id="container" class="align-items-center d-flex h-100">
        <div class="card-body">
          <!-- Logo -->
          <logo-content></logo-content>

          <h4 class="mt-0">Two-Factor Authentication</h4>
          <p class="text-muted mb-4">Enter the 6-digit security code sent to your email.</p>
          <twofa></twofa>
        </div>
        <!-- end card-body -->
        <footer class="footer footer-alt">
          <p class="text-muted">
            <a href="/login" class="text-muted ml-1"><b>Logout</b></a>
          </p>
        </footer>
      </div>
      <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->
    <auth-fluid-content></auth-fluid-content>
    <!-- Auth fluid right content -->

    <!-- end Auth fluid right content -->
  </div>
  <!-- end Auth fluid -->
</template>

<script>
import twofa from "../components/twofa.vue";
import AuthFluidContent from "../components/login/AuthFluidContent";
import LogoContent from "../components/login/LogoContent";

export default {
  name: "TwoFactorPage",
  components: {
    twofa,
    AuthFluidContent,
    LogoContent
  }
};
</script>

<style scoped></style>
