<template>
  <div class="row">
    <div class="col-12">
      <div class="card lockCard">
        <div class="card-body lockCard">
          <h4 class="header-title">Filters</h4>
          <!-- <p
              class="text-muted font-14"
            >Filter your results with the following fields.</p> -->
          <div class="row">
            <div class="col-md-3">
              <div class="form-group row">
                <!-- <div class="col-auto"> -->
                <label
                  for="startDate"
                  class="col-3 col-form-label col-form-label-md"
                  >Start Date</label
                >
                <!-- </div> -->
                <div class="col-9 ml-auto alignCenter">
                  <date-picker
                    id="startDate"
                    v-model="filterObject.startDate"
                    placeholder=""
                    format="MM/DD/YYYY"
                    :first-day-of-week="1"
                    lang="en"
                  ></date-picker>
                </div>
              </div>
              <div class="form-group row">
                <!-- <div class="col-auto"> -->
                <label
                  for="endDate"
                  class="col-3 col-form-label col-form-label-md"
                  >End Date</label
                >
                <!-- </div> -->
                <div class="col-9 ml-auto alignCenter">
                  <date-picker
                    id="endDate"
                    v-model="filterObject.endDate"
                    placeholder=""
                    format="MM/DD/YYYY"
                    :first-day-of-week="1"
                    lang="en"
                  ></date-picker>
                </div>
              </div>
              <div class="form-group row">
                <!-- <div class="col-auto"> -->
                <label
                  for="orderNumber"
                  class="col-3 col-form-label col-form-label-md"
                  >Order #</label
                >
                <!-- </div> -->
                <div class="col-9 ml-auto alignCenter">
                  <input
                    id="orderNumber"
                    v-model="filterObject.orderID"
                    @keyup.enter="submission"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <!-- <div class="col-auto"> -->
                <label
                  for="skuNumber"
                  class="col-3 col-form-label col-form-label-md"
                  >SKU #</label
                >
                <!-- </div> -->
                <div class="col-9 ml-auto alignCenter">
                  <input
                    id="skuNumber"
                    v-model="filterObject.skuID"
                    @keyup.enter="submission"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row mb-1">
                <div class="col-auto">
                  <label class="col-form-label col-form-label-md">Status</label>
                </div>
                <div class="col-9 ml-auto">
                  <!--
                      <multiselect
                        :searchable="true"
                        track-by="abbr"
                        label="abbr"
                        :show-labels="false"
                        :options="statusOptions"
                        :value="filterObject.statusID"
                        :max-height="250"
                        @input="onStateChange($event)"
                        class="filterSelect"
                      >
                        <template slot="singleLabel" slot-scope="props">{{
                          props.option.abbr === "None" ? "" : props.option.abbr
                        }}</template>
                      </multiselect>                  
                  -->
                  <b-form-group class="col-form-label col-form-label-md mb-0">
                    <b-form-radio-group
                      id="status-radio-group"
                      v-model="filterObject.statusID"
                      :options="statusOptions"
                      name="status-radio"
                    >
                    </b-form-radio-group>
                  </b-form-group>
                  
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <div class="col-auto">
                  <label class="col-form-label col-form-label-md"
                    >Address Type</label
                  >
                </div>
                <div class="col-9">
                  <b-form-group class="col-form-label col-form-label-md mb-0">
                    <b-form-radio-group
                      id="address-radio-group"
                      v-model="filterObject.addressTypeID"
                      :options="addressOptions"
                      name="address-radio"
                    >
                      <!-- <b-form-radio
                        name="address-all"
                        v-model="filterObject.addressTypeID"
                        :value="null"
                        class="custom-control-inline"
                      >All</b-form-radio>
                      <b-form-radio
                        name="address-shipping"
                        v-model="filterObject.addressTypeID"
                        :value="2"
                        class="custom-control-inline"
                      >Shipping</b-form-radio>
                      <b-form-radio
                        name="address-billing"
                        v-model="filterObject.addressTypeID"
                        :value="1"
                        class="custom-control-inline"
                      >Billing</b-form-radio> -->
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">
                  <div class="row">
                    <!-- <div class="col-auto"> -->
                    <label class="col-3 col-form-label col-form-label-md"
                      >First Name</label
                    >
                    <!-- </div> -->
                    <div id="firstName" class="col-9 ml-auto alignCenter">
                      <input
                        v-model="filterObject.firstName"
                        @keyup.enter="submission"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <!-- <div class="col-auto"> -->
                    <label class="col-3 col-form-label col-form-label-md"
                      >Last Name</label
                    >
                    <!-- </div> -->
                    <div id="lastName" class="col-9 ml-auto alignCenter">
                      <input
                        v-model="filterObject.lastName"
                        @keyup.enter="submission"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <!-- <div class="col-auto"> -->
                <label
                  for="inputAddress"
                  id="addressLabel"
                  class="col-2 col-form-label col-form-label-md"
                  >Address</label
                >
                <!-- </div> -->
                <div
                  id="addressContainer"
                  class="col-10 pl-0 ml-auto alignCenter"
                >
                  <b-form-input
                    id="inputAddress"
                    v-model="filterObject.address"
                    @keyup.enter="submission"
                    type="text"
                    placeholder="1234 Main St"
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-7">
                  <div class="row">
                    <div class="col-auto">
                      <label
                        for="inputCity"
                        class="col-form-label col-form-label-md"
                        >City</label
                      >
                    </div>
                    <div id="cityContainer" class="col-10 ml-auto alignCenter">
                      <input
                        id="inputCity"
                        v-model="filterObject.city"
                        @keyup.enter="submission"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="row">
                    <!-- <div class="col-auto"> -->
                    <label
                      for="inputState"
                      class="col-4 col-form-label col-form-label-md"
                      >State</label
                    >
                    <!-- </div> -->
                    <div class="col-8 ml-auto alignCenter" id="stateDropdown">
                      <!-- <b-form-select id="inputState" v-model="filterObject.state">
                          <option>Option 1</option>
                          <option>Option 2</option>
                          <option>Option 3</option>
                        </b-form-select> -->
                      <multiselect
                        :searchable="true"
                        track-by="abbr"
                        label="abbr"
                        :show-labels="false"
                        :options="states"
                        :value="stateValue()"
                        :max-height="250"
                        @input="onStateChange($event)"
                        class="filterSelect"
                      >
                        <template slot="singleLabel" slot-scope="props">{{
                          props.option.abbr === "None" ? "" : props.option.abbr
                        }}</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-1">
                <!-- <div class="col-auto"> -->
                <label
                  for="inputPostalCode"
                  id="postalCodeLabel"
                  class="col-2 col-form-label col-form-label-md"
                  >Postal Code</label
                >
                <!-- </div> -->
                <div id="postalContainer" class="col-10 paddingFix alignCenter">
                  <b-form-input
                    id="inputPostalCode"
                    v-model="filterObject.postalCode"
                    @keyup.enter="submission"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group row mb-5">
                <div class="col-auto">
                  <label
                    for="inputFlow"
                    class="col-form-label col-form-label-md"
                    >Flow</label
                  >
                </div>
                <div class="col alignCenter" id="flowDropdown">
                  <!-- <b-form-select id="inputFlow" v-model="filterObject.flowID">
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </b-form-select> -->
                  <multiselect
                    :searchable="true"
                    track-by="id"
                    label="flowName"
                    :show-labels="false"
                    :options="
                      Array.isArray(this.report.flowLookup.flows)
                        ? this.report.flowLookup.flows
                        : []
                    "
                    :value="flowValue()"
                    :max-height="250"
                    v-if="this.report.flowLookup"
                    @input="onFlowChange($event)"
                    class="filterSelect"
                  >
                    <template slot="singleLabel" slot-scope="props">{{
                      props.option.flowName
                    }}</template>
                  </multiselect>
                </div>
              </div>
              <div id="buttonContainer" class="row align-items-end">
                <div class="col pr-0">
                  <b-button
                    v-show="needsReset"
                    class="btn-secondary float-right resetbutton"
                    id="resetButton"
                    type="submit"
                    @click="resetFilterObject"
                    >Reset</b-button
                  >
                </div>
                <div class="col-auto">
                  <b-button
                    class="float-right userButton"
                    id="filterButton"
                    ref="filter"
                    type="submit"
                    @click="onClick"
                    variant="primary"
                    >Filter</b-button
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { states } from "@/views/report/state_capitals";

// export default {
//   name: "filterComponent",
//   props: {
//     filterObject: {
//       required: true
//     },
//   }
// }

export default {
  data() {
    return {
      statusOptions: [
        { text: "All", value: null },
        { text: "Pass", value: 1 },
        { text: "Fail", value: 2 },
        { text: "Cnd'l", value: 3 }
      ],
      addressOptions: [
        { text: "All", value: null },
        { text: "Billing", value: 1 },
        { text: "Shipping", value: 2 }
      ],
      states: states
      // Array.isArray(this.report.flowLookup.flows) ? this.report.flowLookup.flows : []
      // { id: this.report.flowLookup.flows.id, name: this.report.flowLookup.flows.flowName, description: this.report.flowLookup.flows.description }
    };
  },
  computed: {
    ...mapState({
      report: state => state.report
    }),
    needsReset: function() {
      const nullFilterObject = {
        startDate: null,
        endDate: null,
        orderID: null,
        skuID: null,
        statusID: null,
        addressTypeID: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        postalCode: null,
        flowID: null,
        pageNumber: 1,
        pageSize: 50
      };

      for (const property in nullFilterObject) {
        if (property !== "pageNumber" && property !== "pageSize") {
          if (
            this.filterObject[property] !== nullFilterObject[property] &&
            this.filterObject[property] !== ""
          ) {
            return true;
          }
        }
      }
      return false;
    }
  },
  created() {
    this.getFlows();
  },
  methods: {
    ...mapActions("report", ["getFlows"]),
    onClick() {
      if (this.filterObject.pageNumber !== 1) {
        this.filterObject.pageNumber = 1;
      }

      this.$emit("handleSubmit");
    },
    submission() {
      debugger;
      this.$refs.filter.click();
    },
    resetFilterObject() {
      for (const property in this.filterObject) {
        if (property !== "pageNumber" && property !== "pageSize") {
          this.filterObject[property] = null;
        }
      }
      this.submission();
    },
    onFlowChange(event) {
      if (event === null) {
        this.filterObject.flowID = null;
        //this.submission()
        return;
      } else {
        this.filterObject.flowID = event.id;
        //this.submission()
      }
    },
    flowValue() {
      if (this.filterObject.flowID !== null) {
        return this.report.flowLookup.flows.filter(
          flow => flow.id === this.filterObject.flowID
        );
      } else {
        return { id: null, flowName: "All", description: "" };
      }
    },
    onStateChange(event) {
      debugger;
      if (event === null || event.abbr === "None") {
        this.filterObject.state = null;
        //this.submission()
        return;
      } else {
        this.filterObject.state = event.abbr;
        //this.submission()
      }
    },
    stateValue() {
      if (this.filterObject.state !== null) {
        return this.states.filter(
          state => state.abbr === this.filterObject.state
        );
      } else {
        return { abbr: "None" };
      }
    }
  },
  components: {
    DatePicker,
    Multiselect
  },
  props: {
    filterObject: {
      required: true
    }
  }
};
</script>

<style lang="scss">
.lockCard {
  min-width: 1200px;
}

.alignCenter {
  align-self: center;
}

#flowDropdown .multiselect__tags,
#stateDropdown .multiselect__tags {
  padding-right: 25px !important;
}

.form-group {
  margin-bottom: 0.5rem;
}

#resetButton.btn-secondary,
#resetButton.btn-secondary:focus,
#resetButton.btn-secondary:visited {
  color: rgb(58, 58, 58);
  background-color: #bfd9f1;
  border-color: #bfd9f1;
}

#resetButton.btn-secondary:hover,
#resetButton.btn-secondary:active {
  color: rgb(58, 58, 58);
  background-color: #a0c7eb;
  border-color: #a0c7eb;
}

// #filterButton.buttonColor,#filterButton.buttonColor:focus {
//   background-color: #F89C43;
//   border-color: #F89C43;
// }

// #filterButton.buttonColor:active,#filterButton.buttonColor:hover {
//   background-color: #f1902f;
//   border-color: #f1902f;
// }

// #filterButton {
//     bottom: 0;
//     right: 0;
//     position: absolute;
//     margin-right: 12px;
// }

#buttonContainer {
  bottom: 0;
  right: 0;
  position: absolute;
  margin-right: 12px;
}

#firstName.col-9,
#lastName.col-9 {
  flex: 0 0 71.5%;
  max-width: 71.5%;
}

#addressContainer.col-10 {
  flex: 0 0 84.253333%;
  max-width: 84.253333%;
  min-width: 475px;
}

#addressLabel.col-2,
#postalCodeLabel.col-2 {
  flex: 0 0 13.666667%;
  max-width: 13.666667%;
  min-width: 79px;
}

#inputPostalCode {
  max-width: 105px;
}

#cityContainer.col-10 {
  flex: 0 0 75.663333%;
  max-width: 75.663333%;
}

// #postalContainer.col-2 {
//   flex: 0 0 16.6666666667%;
//   max-width: 16.6666666667%;
// }

.paddingFix {
  padding-left: 1.04rem;
}
</style>
